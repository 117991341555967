import { copyData } from "./copy";
import store from "../../store";
export default function (stores, businessType) {
    // console.log(stores)
    let copyDate = copyData(stores);
    let dateListC = [];
    let K = businessType == 1 ? "appointmentTime" : "takeSelfTime"
    for (let i = 0; i < copyDate.length; i++) {
        dateListC.push(...copyDate[i][K]);
    }
    let dateObj = {};
    dateListC.forEach((val) => {
        if (dateObj[val.date]) {
            let json1 = JSON.stringify(dateObj[val.date].times);
            let json2 = JSON.stringify(val.times);
            if (json1 != json2) {
                dateObj[val.date].times.push(...val.times);
            }
        } else {
            val.timeHours = val.times
            dateObj[val.date] = val;
        }
    });
    let dateTime = [];
    for (let key in dateObj) {
        let D = dateObj[key].date;
        dateObj[key].timeList = [];
        dateObj[key].timeObj = {};
        dateObj[key].hours = []
        dateObj[key].hoursMinutes = {}
        let M = [0, 15, 30, 45]
        dateObj[key].timeHours.forEach(V => {
            let T = V.split("~");
            let startTime = T[0].split(":");
            let endTime = T[1].split(":")
            for (let i = Number(startTime[0]); i <= endTime[0]; i++) {
                if (!dateObj[key].hoursMinutes[i]) {
                    dateObj[key].hoursMinutes[i] = []
                }
                let H = Number(i)
                if (dateObj[key].hours.indexOf(H) < 0) {
                    // if (H < 11) continue;
                    dateObj[key].hours.push(H)
                }
                let minutes = []
                if (startTime[0] != endTime[0]) {
                    if (i == startTime[0]) {
                        for (let i = parseInt(startTime[1]); i < 60; i += 15) {
                            minutes.push(i)
                        }
                    } else if (i == endTime[0]) {
                        for (let i = 0; i <= parseInt(endTime[1]); i += 15) {
                            minutes.push(i)
                        }

                    } else {
                        minutes = M
                    }
                } else {
                    if (i == startTime[0]) {
                        for (let i = parseInt(startTime[1]); i <= parseInt(endTime[1]); i += 15) {
                            minutes.push(i)
                        }
                    }
                }
                if (!minutes.length) {
                    minutes = [0]
                }
                dateObj[key].hoursMinutes[i].push(...minutes)
            }
            dateObj[key].hours.sort((a, b) => {
                return a - b
            })
            for (var keyM in dateObj[key].hoursMinutes) {
                dateObj[key].hoursMinutes[keyM].sort((a, b) => {
                    return a - b
                })
                dateObj[key].hoursMinutes[keyM] = Array.from(new Set(dateObj[key].hoursMinutes[keyM]))
            }
        })
        dateObj[key].times.forEach((T) => {
            let Tdata = T.split("~");
            if (
                dateObj[key].timeList.indexOf(
                    new Date(D + " " + Tdata[0]).getTime()
                ) < 0
            ) {
                dateObj[key].timeList.push(new Date(D + " " + Tdata[0]).getTime());
            }
            if (
                dateObj[key].timeList.indexOf(
                    new Date(D + " " + Tdata[1]).getTime()
                ) < 0
            ) {
                dateObj[key].timeList.push(new Date(D + " " + Tdata[1]).getTime());
            }
            dateObj[key].timeObj[new Date(D + " " + Tdata[0]).getTime()] =
                Tdata[0];
            dateObj[key].timeObj[new Date(D + " " + Tdata[1]).getTime()] =
                Tdata[1];
        });
        dateObj[key].timeList.sort((a, b) => {
            return a - b;
        });
        let L = dateObj[key].timeList.length - 1;
        let startTime = dateObj[key].timeObj[dateObj[key].timeList[0]];
        let endTime = dateObj[key].timeObj[dateObj[key].timeList[L]];
        dateObj[key].times = [startTime + "~" + endTime];
        dateTime.push(dateObj[key]);
    }
    return setTime(dateTime, businessType)
}

function setTime(times) {
    let timeMap = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sept",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
    };
    let month = "月";
    let day = "日";
    let hourText = "時";
    let minuteText = "分";
    if (store.state.language != 1) {
        month = "month";
        day = "day";
        hourText = "";
        minuteText = "";
    }
    let date = [];
    let hoursArr = [];
    let minutesData = {};
    times.sort((a, b) => {
        return new Date(a.date.split("-").join("/")).getTime() - new Date(b.date.split("-").join("/")).getTime()
    })
    for (let i = 0; i < times.length; i++) {
        let dateArr = times[i].date.split("-");
        let dateStr;
        if (store.state.language == 1) {
            dateStr = dateArr[1] + month + dateArr[2] + day;
        } else {
            dateStr = dateArr[2] + " " + timeMap[dateArr[1]];
        }
        date.push(dateStr);
    }
    let canDate = []
    times.forEach((V, I) => {
        let hours = copyData(V.hours)
        hours.forEach((H, index) => {
            if (H < 10) {
                H = "0" + H
            }
            hours[index] = H += hourText
            let minutesKey = date[I] + hours[index]
            V.hoursMinutes[parseInt(H)].forEach((M, mi) => {
                V.hoursMinutes[parseInt(H)][mi] = M + minuteText
            })
            minutesData[minutesKey] = V.hoursMinutes[parseInt(H)]
        })
        if (V.hours.length) {
            canDate.push(date[I])
            hoursArr.push(hours)
        }
    })
    let columns = []
    columns[0] = {};
    columns[1] = {};
    columns[2] = {};
    columns[0].values = canDate;
    columns[1].values = hoursArr[0];
    columns[2].values = minutesData[Object.keys(minutesData)[0]];
    let res = {
        columns,
        minutesData,
        hoursArr,
    }
    return res
}