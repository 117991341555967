<template>
  <div class="confirmBox">
    <div class="content">
      <div class="topIcon">
        <i></i>
        <i></i>
        <i></i>
      </div>
      <p class="title">{{ title }}</p>
      <p class="time" v-if="content">{{ content }}</p>
      <div class="btn">
        <span @click="closeBox">{{ cancelBtn }}</span>
        <span @click="finish">{{ finishBtn }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfirmBox",
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    finishBtn: {
      type: String,
      default: "確定",
    },
    cancelBtn: {
      type: String,
      default: "取消",
    },
  },
  methods: {
    closeBox() {
      this.$emit("closeBox");
    },
    finish() {
      this.$emit("finish");
    },
  },
};
</script>
<style scoped>
.confirmBox {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999999999;
}
.content {
  width: 3rem;
  background: #ffffff;
  border-radius: 0.12rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.logo {
  width: 1.56rem;
  height: 0.92rem;
}
.time {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 20px;
  color: #474747;
  margin: 0.08rem 0.16rem 0.5rem;
}
.title {
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 25px;
  color: #03060d;
  margin-top: .2rem;
  margin-bottom: .16rem;
}
.btn {
  height: 0.54rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.54rem;
  color: #b1b1b3;
  border-top: 1px solid #cacbcc;
}
.btn span {
  width: 100%;
  text-align: center;
}
.btn span:first-child {
  border-right: 1px solid #cacbcc;
}
.btn span:last-child {
  color: #e4022b;
}

.orderAlert {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #474747;
  padding: 0 0.24rem;
  box-sizing: border-box;
}
.orderAlert p {
  text-align: left;
  margin-top: 0.04rem;
}
.alertTitle {
  margin-bottom: 0.08rem;
  color: #03060d;
  font-size: 0.16rem;
}
.orderAlert p span {
  width: 0.05rem;
  height: 0.1rem;
  display: inline-block;
  border-right: 0.01rem solid #e4022b;
  border-bottom: 0.01rem solid #e4022b;
  transform: rotate(45deg);
}

.topIcon{
  display: flex;
  justify-content: center;
  align-items: center;
}
.topIcon i{
  width: .08rem;
  height: .1rem;
  background-color: #e4022b;
  margin-left: .08rem;
}
</style>