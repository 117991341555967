<template>
  <div class="cart">
    <div class="maleMoney" v-show="showPreBar">
      <div class="coupon" v-if="businessType == 2">
        <p>
          {{
            totalPricePre.pres.toString() == "[object Object]"
              ? ""
              : totalPricePre.pres
          }}
        </p>
      </div>
      <div v-else>
        <div class="coupon" v-if="$store.state.language == 1">
          <!-- <span v-if="totalPricePre.pres && totalPricePre.pres.now">
            已享<span
              >{{ totalPricePre.pres.now ? totalPricePre.pres.now[0] : 0
              }}{{ $store.state.money[$store.state.area] }}</span
            >減<span
              >{{ totalPricePre.pres.now ? totalPricePre.pres.now[1] : ""
              }}{{ $store.state.money[$store.state.area] }}</span
            >
          </span> -->
          <span v-if="totalPricePre.pres && totalPricePre.pres.next">
            再買<span class="carbar-money"
              >{{ $store.state.money[$store.state.area]
              }}{{
                totalPricePre.pres.next
                  ? totalPricePre.pres.next[0] - totalPricePre.pres.total
                  : 0
              }}</span
            >即減<span class="carbar-money"
              >{{ $store.state.money[$store.state.area]
              }}{{
                totalPricePre.pres.next ? totalPricePre.pres.next[1] : 0
              }}</span
            >
          </span>
        </div>
        <div class="coupon" v-else>
          <!-- <span v-if="totalPricePre.pres && totalPricePre.pres.now">
            <span
              >{{ totalPricePre.pres.now ? totalPricePre.pres.now[0] : 0
              }}{{ $store.state.money[$store.state.area] }}</span
            >
            off upon
            <span
              >{{ totalPricePre.pres.now ? totalPricePre.pres.now[1] : ""
              }}{{ $store.state.money[$store.state.area] }}</span
            >
            spending
          </span> -->
          <span v-if="totalPricePre.pres && totalPricePre.pres.next">
            <span class="carbar-money">
              {{ $store.state.money[$store.state.area]
              }}{{
                totalPricePre.pres.next
                  ? totalPricePre.pres.next[0] - totalPricePre.pres.total
                  : 0
              }}
            </span>
            away from getting
            <span class="carbar-money"
              >{{ $store.state.money[$store.state.area]
              }}{{
                totalPricePre.pres.next ? totalPricePre.pres.next[1] : 0
              }}</span
            >
            discount
          </span>
        </div>
      </div>
    </div>
    <div class="cartLeft" @click="showCart">
      <img src="@/assets/icon/linshi.png" alt="" />
      <b class="cartNum" v-show="showCartLength">{{ showCartLength }}</b>
      <p v-show="!showCartLength">
        {{
          $store.state.language == 1
            ? "購物車尚未有任何餐品"
            : "Your cart is empty!"
        }}
      </p>
      <p v-show="showCartLength" class="money">
        <span
          >{{ $store.state.money[$store.state.area] }}
          {{ showPrice }}</span
        >
        <span v-show="businessType == 1">
          <span v-show="afterDeliveryFee > 0">
            {{ lanType == 1 ? YF : ""
            }}{{ $store.state.money[$store.state.area] }}{{ afterDeliveryFee }}
            {{ lanType == 2 ? YF : "" }}
          </span>
          <span v-show="afterDeliveryFee <= 0">
            {{ YF_none }}
          </span>
        </span>
      </p>
    </div>
    <p class="cartRight" @click="checkVoucher">
      {{ $store.state.language == 1 ? "去付款" : "Checkout" }}
    </p>
  </div>
</template>
<script>
import { inject } from "@vue/runtime-core";
import language from "../../assets/js/language";
import { postDC } from "../../assets/utils/request";
export default {
  name: "CartBar",
  data() {
    return {
      show: false,
      liJiDianCan: "",
      afterDeliveryFee: 0, //优惠的配送费
      lanType: this.$store.state.language,
      preShow: true,
      storePre: {},
      checkSelectVoucherTimer: null, //检查优惠券节流定时器
      getDispatchPreTimer: null, //获取配送费优惠节流定时器
      YF: "",
      YF_none: "",
      HQPSFYHSB: "",
      HQXXSB: "",
    };
  },
  props: {
    storeId: {
      type: Number,
      default: 0,
    },
    storePres: {
      type: Object,
      default: () => {
        return {};
      },
    },
    businessType: {
      //业务类型
      default: 0,
    },
    second: {
      //预约时间
      default: 0,
    },
    book: {
      //预约时间
      type: Number,
      default: 0,
    },
    menuId: {
      type: Number,
      default: 0,
    },
    shopName: {
      type: String,
      default: "",
    },
    // 配送费
    fee: {
      type: Number,
      default: 0,
    },
    reachFee: {
      type: Number,
      default: 0,
    },
    // 选择的优惠券信息
    couponInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    canToPay: {
      type: Boolean,
      default: false,
    },
    takeaway: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    checkSelectVoucherTime(info) {
      if (this.checkSelectVoucherTimer)
        clearTimeout(this.checkSelectVoucherTimer);
      this.checkSelectVoucherTimer = setTimeout(() => {
        this.$parent.$parent.checkSelectVoucher(info, false);
      }, 500);
    },
    checkDispatchPreTime(money) {
      if (this.getDispatchPreTimer) clearTimeout(this.getDispatchPreTimer);
      this.getDispatchPreTimer = setTimeout(() => {
        this.getDispatchPre(money);
      }, 300);
    },
    showCart() {
      this.showPre(false);
      this.$emit("showCart");
    },
    showPre(type) {
      this.preShow = type;
    },
    async checkVoucher() {
      // if (!this.cartLength) return;
      let C = this.$store.state.couponInfo;
      if (C) {
        await this.$parent.$parent.checkSelectVoucher(C);
        this.$parent.$parent.isChangePage = true;
      } else {
        this.changePage();
      }
    },
    async changePage() {
      if (this.businessType == 1) {
        var canChange = await this.getDispatchPre();
        if (!canChange) {
          return this.$toast(this.HQXXSB);
        }
      }
      // if (this.couponInfo) {
      //   if (this.cartLength - 1 < 0) {
      //     return;
      //   }
      // } else {
      //   if (!this.cartLength) {
      //     return;
      //   }
      // }
      if (!this.cartLength) return;
      if (this.book == 2) {
        let can;
        if (this.businessType == 1) {
          can = await this.$parent.$parent.getNewTakeselfTime();
        } else {
          can = await this.$parent.$parent.getNewAppointime();
        }
        if (!can) {
          this.$parent.$parent.shouldRemoveVoucher(2);
          return;
        }
      }
      if (this.businessType == 1 && this.totalPricePre.price < this.reachFee) {
        this.$parent.$parent.shouldRemoveVoucher(3);
        return;
      }
      if (!this.storeId || !this.menuId || !this.canToPay) {
        return;
      }
      var query = {
        path: "/confirmorder",
        storeId: this.storeId,
        businessType: this.businessType,
        menuId: this.menuId,
        second: this.second,
        book: this.book,
        shopName: this.shopName,
        language: this.lanType,
        couponInfo: JSON.stringify(this.couponInfo),
        app: this.$store.state.app,
        afterDeliveryFee: this.afterDeliveryFee,
        afterTotal: this.totalPricePre.pres.now
          ? this.totalPricePre.pres.now[1]
          : "",
      };
      if (this.businessType != 1) {
        query.afterDeliveryFee = 0;
      }
      if (this.$route.params.type != 1) {
        var pageParam = this.$route.params.type;
        if (pageParam.app) {
          query.app = pageParam.app || false;
        }
      }
      if (this.businessType == 4) {
        query.second = this.second;
      }
      if (this.businessType == 3) {
        query.takeaway = this.takeaway;
      }
      var queryParam = JSON.stringify(query);
      queryParam = encodeURIComponent(queryParam);
      this.$router.push("/confirmorder/" + queryParam);
    },
    encodeStr(str) {
      const A = [
        {
          code: "%",
          encode: "%25",
        },
        {
          code: "?",
          encode: "%3F",
        },
        {
          code: "#",
          encode: "%23",
        },
        {
          code: "&",
          encode: "%26",
        },
        {
          code: "=",
          encode: "%3D",
        },
      ];
      var key = str.replace(/[%?#&=]/g, ($, index, str) => {
        for (var k of A) {
          if (k.code == $) {
            return k.encode;
          }
        }
        console.log(str);
      });
      return key;
    },
    // 获取配送费优惠
    async getDispatchPre(price) {
      if (this.businessType != 1) return;
      let params = {
        actionName: "candao.preferential.getDispatchPre",
        content: {
          storeId: this.storeId,
          products: this.foodSku,
          price: price || price == 0 ? price : this.totalPricePre.price,
          orderType: this.businessType,
          menuId: this.menuId,
          deliveryFee: this.fee || 0,
        },
      };
      let result = await postDC("Action", params);
      if (result.status == 1) {
        if (result.data) {
          this.afterDeliveryFee = result.data.afterDeliveryFee;
        }
        return true;
      } else {
        this.$toast(this.HQPSFYHSB);
        return false;
      }
    },
    setLanguage() {
      var T;
      if (this.$store.state.language == 1) {
        T = "chinese";
      } else {
        T = "english";
      }
      this.liJiDianCan = language.liJiDianCan[T];
      this.YF = language.YF[T];
      this.YF_none = language.YF_none[T];
      this.HQPSFYHSB = language.HQPSFYHSB[T];
      this.HQXXSB = language.HQXXSB[T];
    },
  },
  created() {
    setTimeout(() => {
      this.setLanguage();
    }, 50);
  },
  computed: {
    showPreBar() {
      if (this.cartLength && this.preShow && this.totalPricePre.pres) {
        if (this.businessType == 2) {
          if (JSON.stringify(this.totalPricePre.pres) != "{}") {
            return true;
          }
        } else if (this.totalPricePre.pres.next) {
          return true;
        }
        return false;
      }
      return false;
    },
    // 餐品sku
    foodSku() {
      var products = [];
      var cartList = this.$store.state.cart;
      for (var i = 0; i < cartList.length; i++) {
        var obj = {
          pid: cartList[i].uid,
          typeId: cartList[i].type,
          num: cartList[i].num || cartList[i].selectedFood.num || 1,
          weight: 0,
        };
        if (cartList[i].type == 1 || cartList[i].typeId == 1) {
          if (cartList[i].listRequirements) {
            obj.listRequirements = cartList[i].listRequirements;
          }
          products.push(obj);
        } else if (cartList[i].type || cartList[i].typeId) {
          products.push(cartList[i].selectedFood);
        }
      }
      return products;
    },
    price() {
      var money = 0;
      var cart = this.$store.state.cart;

      for (var i = 0; i < cart.length; i++) {
        // 单品
        if (cart[i].type == 1) {
          if (cart[i].listRequirements) {
            for (var a = 0; a < cart[i].listRequirements.length; a++) {
              for (
                var c = 0;
                c < cart[i].listRequirements[a].propertys.length;
                c++
              ) {
                for (
                  var d = 0;
                  d < cart[i].listRequirements[a].propertys[c].items.length;
                  d++
                ) {
                  money +=
                    cart[i].listRequirements[a].propertys[c].items[d].price;
                }
              }
            }
          } else {
            //没有规格
            money += cart[i].price;
          }
        } else if (cart[i].type == 2) {
          money += cart[i].selectedFood.price;
        }
      }
      return money;
    },

    showCartLength() {
      let L = this.cartLength;
      let C = this.$store.state.couponInfo;
      if (C) {
        if (!C.isCanUserVoucher && (C.voucherType == 3 || C.voucherType == 5)) {
          L += 1;
        }
      }
      return L;
    },

    showPrice() {
      let P = this.totalPricePre.price || 0;
      let C = this.$store.state.couponInfo;
      if (C) {
        if (!C.isCanUserVoucher && (C.voucherType == 3 || C.voucherType == 5)) {
          P += this.couponProductPrice;
        }
      }
      return P;
    },

    // 购物车数量
    cartLength() {
      var cart = this.$store.state.cart;
      var length = 0;
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].isCouponPro) continue;
        length += Number(cart[i].num);
      }
      let C = this.$store.state.couponInfo;
      if (
        C &&
        C.isCanUserVoucher &&
        (C.voucherType == 3 || C.voucherType == 5)
      ) {
        length += 1;
      }
      return length;
    },
    // 一共都少钱
    totalPrice() {
      var total = 0;
      var cart = this.$store.state.cart;
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].isCouponPro) continue;
        var proPrice = cart[i].price;
        if (cart[i].selectedFood && cart[i].selectedFood.groups) {
          for (var a = 0; a < cart[i].selectedFood.groups.length; a++) {
            for (
              var b = 0;
              b < cart[i].selectedFood.groups[a].mains.length;
              b++
            ) {
              for (
                var c = 0;
                c < cart[i].selectedFood.groups[a].mains[b].groups.length;
                c++
              ) {
                for (
                  var d = 0;
                  d <
                  cart[i].selectedFood.groups[a].mains[b].groups[c].products
                    .length;
                  d++
                ) {
                  proPrice +=
                    cart[i].selectedFood.groups[a].mains[b].groups[c].products[
                      d
                    ].price *
                    cart[i].selectedFood.groups[a].mains[b].groups[c].products[
                      d
                    ].num;
                }
              }
            }
          }
        }
        proPrice *= cart[i].num;
        total += proPrice;
      }
      inject("getStorePresTime")(total);
      let C = this.$store.state.couponInfo;
      if (C) {
        this.checkSelectVoucherTime(C);
      }
      return total;
    },
    couponProductPrice() {
      var total = 0;
      var cart = this.$store.state.cart;
      var hasPro = false;
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].isCouponPro) {
          hasPro = true;
          total = cart[i].price;
          if (cart.type == 1 || cart[i].typeId == 1) {
            if (cart[i].listRequirements) {
              for (var a1 = 0; a1 < cart[i].listRequirements.length; a1++) {
                for (
                  var c1 = 0;
                  c1 < cart[i].listRequirements[a1].propertys.length;
                  c1++
                ) {
                  for (
                    var d1 = 0;
                    d1 <
                    cart[i].listRequirements[a1].propertys[c1].items.length;
                    d1++
                  ) {
                    total +=
                      cart[i].listRequirements[a1].propertys[c1].items[d1]
                        .price;
                  }
                }
              }
            }
          } else {
            if (cart[i].selectedFood && cart[i].selectedFood.groups) {
              for (var a = 0; a < cart[i].selectedFood.groups.length; a++) {
                for (
                  var b = 0;
                  b < cart[i].selectedFood.groups[a].mains.length;
                  b++
                ) {
                  for (
                    var c = 0;
                    c < cart[i].selectedFood.groups[a].mains[b].groups.length;
                    c++
                  ) {
                    for (
                      var d = 0;
                      d <
                      cart[i].selectedFood.groups[a].mains[b].groups[c].products
                        .length;
                      d++
                    ) {
                      total +=
                        cart[i].selectedFood.groups[a].mains[b].groups[c]
                          .products[d].price *
                        cart[i].selectedFood.groups[a].mains[b].groups[c]
                          .products[d].num;
                    }
                  }
                }
              }
            }
          }
          break;
        }
      }
      let C = this.$store.state.couponInfo;
      if (C) {
        if (C.voucherType == 3 || C.voucherType == 5) {
          if (!hasPro) {
            total = C.copyPro.price;
          }
        }
      }
      return total;
    },
    // 满减之后多少钱
    totalPricePre() {
      let voucher = this.$store.state.couponInfo;
      var pres;
      if (this.storePres && this.storePres.pres && this.storePres.pres.length) {
        for (var i = 0; i < this.storePres.pres.length; i++) {
          if (this.businessType == 2) {
            if (
              this.storePres.pres[i].childType == 6 &&
              this.storePres.pres[i].type == 4
            ) {
              pres = this.storePres.pres[i];
            }
          } else {
            if (
              this.storePres.pres[i].childType == 0 &&
              this.storePres.pres[i].type == 3
            ) {
              pres = this.storePres.pres[i].items;
            }
          }
        }
      }
      if (!pres) {
        var MP = this.totalPrice;
        this.$store.commit("setTotalPrice", MP);
        if (voucher && voucher.isCanUserVoucher) {
          if (voucher.voucherType == 1 || voucher.voucherType == 4) {
            var pPrice = voucher.voucherAmount / 100;
            MP -= Number(pPrice);
            this.$emit("setPreMoney", pPrice);
          } else if (voucher.voucherType == 2) {
            var pDiscount = (voucher.voucherDiscount / 10000) * MP;
            pDiscount = Math.round((pDiscount + Number.EPSILON) * 10) / 10;
            MP -= Number(pDiscount);
            this.$emit("setPreMoney", pDiscount);
          } else if (voucher.voucherType == 3 || voucher.voucherType == 5) {
            // MP += Number(this.couponInfo.mappingPro.mappingPrice);
            MP += this.couponProductPrice;
          }
        }
        var mPay = {
          price: Math.round((Number(MP) + Number.EPSILON) * 10) / 10,
          pres: {},
        };
        this.checkDispatchPreTime(mPay.price);
        return mPay;
      }
      var money = this.totalPrice;
      var priceParams = {};
      if (this.businessType == 2) {
        var value = 1 - pres.value / 100;
        var M = Math.round((money * value + Number.EPSILON) * 10) / 10;
        money = money - M;
        priceParams = this.lanType == 1 ? pres.title : pres.titleEng;
      } else {
        var priceArr = [];

        var index;
        // var pres = this.storePres.pres[0].items;
        pres.sort((a, b) => {
          return a.needConsumeMoney - b.needConsumeMoney;
        });
        for (var a = 0; a < pres.length; a++) {
          if (this.totalPrice >= pres[a].needConsumeMoney) {
            priceArr[0] = pres[a].needConsumeMoney;
            priceArr[1] = Math.abs(pres[a].price);
            index = a;
          }
        }
        if (priceArr.length) {
          priceParams.now = priceArr;
          if (pres[index + 1]) {
            var arr = [
              pres[index + 1].needConsumeMoney,
              Math.abs(pres[index + 1].price),
            ];
            priceParams.next = arr;
          }
        } else {
          var arrP = [pres[0].needConsumeMoney, Math.abs(pres[0].price)];
          priceParams.next = arrP;
        }
        priceParams.total = this.totalPrice;
        if (priceArr.length) {
          money = money - Number(priceArr[1] || 0);
        }
      }
      var saveMoney = money;
      if (this.$store.state.area != 0) {
        saveMoney += 1;
      }
      this.$store.commit("setTotalPrice", saveMoney);
      if (voucher && voucher.isCanUserVoucher) {
        if (voucher.voucherType == 1 || voucher.voucherType == 4) {
          var prePrice = voucher.voucherAmount / 100;
          money -= Number(prePrice);
          this.$emit("setPreMoney", prePrice);
        } else if (voucher.voucherType == 2) {
          var preDiscount = (voucher.voucherDiscount / 10000) * money;
          preDiscount = Math.round((preDiscount + Number.EPSILON) * 10) / 10;
          money -= Number(preDiscount);
          this.$emit("setPreMoney", preDiscount);
        } else if (voucher.voucherType == 3 || voucher.voucherType == 5) {
          money += this.couponProductPrice;
        }
      }
      if (this.$store.state.area != 0) {
        money += 1;
      }
      money = Math.round((money + Number.EPSILON) * 10) / 10;
      this.$emit("storePre", priceParams);
      var moneyPar = {
        price: money < 0 ? 0 : money,
        pres: priceParams,
      };

      this.checkDispatchPreTime(money);
      return moneyPar;
    },
  },
};
</script>
<style scoped>
.cart {
  width: 3.43rem;
  height: 0.56rem;
  border-radius: 0.28rem;
  background: rgba(141, 142, 146, 1);
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.cartLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.1rem;
  position: relative;
}
.cartLeft img {
  width: 0.56rem;
  height: 0.56rem;
  margin-top: -0.2rem;
  margin-right: 0.1rem;
}
.cartNum {
  width: 0.25rem;
  height: 0.25rem;
  font-size: 0.16rem !important;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.25rem;
  color: #ffffff;
  border-radius: 50%;
  background-color: #f0a10d;
  position: absolute;
  top: -0.11rem;
  left: 0.4rem;
}
.cartLeft p {
  width: 1.7rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.56rem;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cartLeft .money {
  font-size: 0.18rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.25rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.cartLeft .money span:last-child {
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.21rem;
  color: #ffffff;
  opacity: 0.8;
}
.cartRight {
  width: 1.2rem;
  padding: 0 0.08rem;
  height: 0.56rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.56rem;
  color: #ffffff;
  opacity: 1;
  background: #e4022b;
  border-radius: 0.28rem;
}
.maleMoney {
  width: 100%;
  height: auto;
  background-color: #f5e9eb;
  position: absolute;
  bottom: 0rem;
  z-index: -1;
  border-radius: 0.12rem 0.12rem 0.28rem 0.28rem;
  padding-bottom: 0.6rem;
}
.coupon {
  width: 100%;
  background: #f5e9eb;
  opacity: 1;
  border-radius: 12px 12px 0px 0px;
  text-align: center;
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #03060d;
  padding: 0.08rem;
  box-sizing: border-box;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
}
.carbar-money {
  color: #e4022b;
}
</style>