<template>
  <div class="shopItem">
    <div class="shopTop">
      <p v-if="tabActive == 1">
        <span
          :class="{
            takeIn: item.businessId == 3,
            takeUs: item.businessId == 2,
            deliveryActive: item.businessId == 1,
            deliveryYY: item.businessId == 4,
          }"
          v-for="item in shopInfo.businessStatus"
          :key="item"
        >
          <i
            :class="{
              takeIn: item.businessId == 3,
              takeUs: item.businessId == 2,
              deliveryActive: item.businessId == 1,
              deliveryYY: item.businessId == 4,
            }"
          ></i>
          {{ item.businessName }}</span
        >
      </p>
      <p v-if="tabActive == 2">
        <span
          :class="{
            takeIn: item == 'Dine in' || item == '堂食',
            takeUs: item == 'Takeaway' || item == '外賣自取',
            deliveryActive: item == 'Delivery' || item == '外送速遞',
          }"
          v-for="item in shopInfo.categoryName"
          :key="item"
        >
          <i
            :class="{
              takeIn: item == 'Dine in' || item == '堂食',
              takeUs: item == 'Takeaway' || item == '外賣自取',
              deliveryActive: item == 'Delivery' || item == '外送速遞',
            }"
          ></i>
          {{ item }}
        </span>
      </p>
      <p>{{ shopInfo.distance }}m</p>
    </div>
    <div class="name">
      <p>{{ shopInfo.shopName || shopInfo.storeName }}</p>
    </div>
    <p class="address">
      <span>
        {{ shopInfo.shopAddress || shopInfo.storeAddress }}
      </span>
      <span
        class="moreDetail"
        v-if="shopInfo.busy"
        :class="{ activeGray: shopInfo.busy }"
        >{{ $store.state.language == 1 ? "暫停" : "Paused" }}
      </span>
      <span
        class="moreDetail"
        v-else-if="!showDetail"
        :class="{ activeGray: shopInfo.busy }"
        @click.prevent.stop="lookDetail"
        >{{ $store.state.language == 1 ? "查看" : "View" }}
      </span>
      <span class="moreDetail" v-else :class="{ activeGray: shopInfo.busy }"
        >{{ $store.state.language == 1 ? "揀選" : "Select" }}
      </span>
    </p>
    <!-- <div v-if="showDetail" class="storeInfo">
      <p class="detailIcon" @click.prevent.stop="lookDetail">
        <i></i>
        <span>{{
          $store.state.language == 1 ? "店鋪詳情" : "Store Info"
        }}</span>
      </p>
      <span class="moreDetail">{{
        $store.state.language == 1 ? "揀選" : "Select"
      }}</span>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "ShopItem",
  props: {
    shopInfo: Object,
    default() {
      return {};
    },
    showDetail: {
      type: Boolean,
      default: true,
    },
    tabActive: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    lookDetail() {
      if (this.shopInfo.busy) return;
      this.$router.push({
        path: "/shopDetail",
        query: {
          id: this.shopInfo.canDaoShopId || this.shopInfo.storeId,
        },
      });
    },
  },
};
</script>
<style scoped>
.shopItem {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;

  border-bottom: 1px solid #e3e4e6;
}
.shopTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shopTop p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #808080;
}

.shopTop p:first-child span {
  height: 0.18rem;
  line-height: 0.18rem;
  color: #03060d;
  margin-right: 0.04rem;
  padding: 0 0.05rem;
  /* border: 0.01rem solid #a5a8af; */
  border-radius: 0.02rem;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0;
  font-weight: 600;
}
.name {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #030f14;
  margin: 0.08rem 0;
}
.name p:nth-child(2) {
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #03060d;
}
.address {
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #808080;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.address span:first-child {
  width: 80%;
}
.shopTop span.takeIn {
  background-color: #f5e9eb;
}
.shopTop span.takeUs {
  background-color: #ff7c9b;
}
.shopTop span.deliveryActive,
.shopTop span.deliveryYY {
  background-color: #f2f3f5;
}

.shopTop i.takeIn {
  width: 0.15rem;
  height: 0.15rem;
  background: url("../../assets/icon/ts.png");
}
.shopTop i.takeUs {
  width: 0.12rem;
  height: 0.12rem;
  background: url("../../assets/icon/ziqu.png");
}
.shopTop i.deliveryActive,
.shopTop i.deliveryYY {
  width: 0.13rem;
  height: 0.13rem;
  background: url("../../assets/icon/sd.png");
}

.shopTop p:first-child span i {
  background-size: cover;
  margin-right: 0.04rem;
}
.storeInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.11rem;
  margin-top: 0.09rem;
}
.detailIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.detailIcon i {
  width: 0.11rem;
  height: 0.11rem;
  background: url("../../assets/icon/menu.png") no-repeat center;
  background-size: cover;
  margin-right: 0.06rem;
}
.moreDetail {
  width: 0.58rem;
  height: 0.29rem;
  background: linear-gradient(90deg, #fe1112 0%, #e4022b 100%);
  border-radius: 0.24rem;
  font-size: 0.14rem;
  line-height: 0.29rem;
  color: #fff;
  text-align: center;
}
.activeGray {
  border: 0.01rem solid #b1b1b3;
  color: #b1b1b3;
  background: none;
}
</style>