<template>
  <div class="cart">
    <div class="coupon" v-if="businessType == 2">
      {{ storePreParam.toString() == "[object Object]" ? "" : storePreParam }}
    </div>
    <div v-else>
      <div class="coupon" v-if="$store.state.language == 1">
        <span v-if="storePreParam.now">
          已享<span
            >{{ $store.state.money[$store.state.area]
            }}{{ storePreParam.now ? storePreParam.now[0] : 0 }}</span
          >減<span
            >{{ $store.state.money[$store.state.area]
            }}{{ storePreParam.now ? storePreParam.now[1] : "" }}</span
          >
        </span>
        <!-- <span v-if="storePreParam.next">
          再買<span
            >{{
              storePreParam.next
                ? storePreParam.next[0] - storePreParam.total
                : 0
            }}{{ $store.state.money[$store.state.area] }}</span
          >即減<span
            >{{ storePreParam.next ? storePreParam.next[1] : 0
            }}{{ $store.state.money[$store.state.area] }}</span
          > 
        </span>-->
      </div>
      <div class="coupon" v-else>
        <!-- <span v-if="storePreParam.now">
          <span
            >{{ storePreParam.now ? storePreParam.now[0] : 0
            }}{{ $store.state.money[$store.state.area] }}</span
          >
          off upon<span
            >{{ storePreParam.now ? storePreParam.now[1] : ""
            }}{{ $store.state.money[$store.state.area] }}</span
          >
          spending
        </span> -->
        <span v-if="storePreParam.next">
          <span
            >{{ $store.state.money[$store.state.area]
            }}{{
              storePreParam.next
                ? storePreParam.next[0] - storePreParam.total
                : 0
            }}</span
          >
          away from getting
          <!-- <span
            >{{ storePreParam.next ? storePreParam.next[0] : 0
            }}{{ $store.state.money[$store.state.area] }}</span
          >get -->
          <span
            >{{ $store.state.money[$store.state.area]
            }}{{ storePreParam.next ? storePreParam.next[1] : 0 }}</span
          >
          discount
        </span>
      </div>
    </div>
    <div class="title">
      <p>{{ $store.state.language == 1 ? "已選餐品" : "Selected item(s)" }}</p>
      <p @click="closeBox(0)">
        {{ $store.state.language == 1 ? "清空購物車" : "Clear Cart" }}
      </p>
    </div>
    <div class="cartItemBox">
      <!-- 一只显示在购物车中的优惠券 -->
      <div class="cartItem" v-if="canShowVoucher">
        <img :src="$store.state.couponInfo.imageUrl" alt="" />
        <div>
          <div class="nameBox">
            <p class="name">
              {{ $store.state.couponInfo.voucherName }}
            </p>
            <p @click="clearCoupon" class="remove">
              X {{ $store.state.language == 1 ? "移除" : "Remove" }}
            </p>
          </div>

          <p class="content">
            -{{ $store.state.money[$store.state.area]
            }}{{ $store.state.couponInfo.showPrice }}
          </p>
        </div>
      </div>
      <!-- 优惠券 -->
      <div class="cartItem" v-if="couItemShow">
        <img :src="couponInfo.imageUrl" alt="" />
        <div>
          <div class="nameBox">
            <p class="name">
              {{ couponInfo.voucherName }}
            </p>
            <p @click="clearCoupon" class="remove">
              X {{ $store.state.language == 1 ? "移除" : "Remove" }}
            </p>
          </div>

          <p class="content">
            -{{ $store.state.money[$store.state.area] }}{{ preMoney }}
          </p>
        </div>
      </div>
      <div class="cartItem" v-if="couShow">
        <img
          ref="couponIcon"
          :src="voucher.imageUrl"
          @error="showErrorIcon"
          alt=""
        />
        <div>
          <p class="name">
            <span v-if="false">券</span>
            {{
              $store.state.language == 1
                ? voucher.mappingPro.mappingName || voucher.mappingPro.name
                : voucher.mappingPro.mappingName || voucher.mappingPro.nameEn
            }}
          </p>
          <p class="content active">
            <span v-if="voucher.copyPro.type != 1" :class="{ active: false }"
              >{{ voucher.mappingPro.mappingName }}x1</span
            >
          </p>
          <div class="price">
            <p class="money">
              {{ $store.state.money[$store.state.area] }}
              <span>{{ voucher.mappingPro.mappingPrice || 0 }}</span>
            </p>
            <div class="foodNum Qactive">
              <span @click="clearCoupon">-</span>
              <input type="text" value="1" readonly />
              <span :class="{ active: true }">+</span>
            </div>
          </div>
        </div>
      </div>
      <div class="itemLine" v-for="(item, i) in productList" :key="item.uid">
        <cart-item
          :ref="'cartItem_' + item.uid + i"
          :storeId="storeId"
          :product="item"
          :proIndex="i"
          @changeNum="changeNum"
          @updateFood="updateFood"
          @clearCoupon="clearCoupon"
          @dataLayerPush="dataLayerPush"
        ></cart-item>
      </div>
    </div>
    <div class="sendMoney" v-if="$store.state.area == 1">
      <p>澳門膠袋徵費</p>
      <p>
        <span>MOP </span>
        <span>1</span>
      </p>
    </div>
  </div>
</template>
<script>
import CartItem from "../../components/food/CartItem.vue";
import { copyData } from "../../assets/utils/copy";
export default {
  name: "ShopCart",
  data() {
    return {
      userId: 0,
      active: true,
      show: false,
      clear: false,
      errorIcon: require("../../assets/icon/orderIcon.png"),
    };
  },
  props: {
    preMoney: {
      //優惠的錢
      default: 0,
    },
    storeId: {
      type: Number,
      default: 0,
    },
    storePreParam: {
      type: Object,
      default: () => {
        return {};
      },
    },
    businessType: {
      //业务类型
      default: 0,
    },
    // 选择的优惠券信息
    couponInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    showErrorIcon() {
      this.$refs.couponIcon.src = this.errorIcon;
    },
    closeBox(type) {
      if (
        type == 0 &&
        (!this.$store.state.cart || !this.$store.state.cart.length) &&
        !this.$store.state.couponInfo
      )
        return;
      // this.show = !this.show;
      this.$emit("showClearCart");
    },
    finish() {
      this.clearCart();
      this.closeBox();
      this.dataLayerRemoveAllCart();
    },
    setDataLayerParam() {
      var items = [];
      var cart = copyData(this.$store.state.cart);
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].type != 1 && cart[i].typeId != 1) {
          var groups = cart[i].selectedFood.groups;
          groups.forEach((p) => {
            p.mains.forEach((m) => {
              m.groups.forEach((g) => {
                g.products.forEach((ps) => {
                  var itemObj = {};
                  itemObj.item_name =
                    this.$store.state.language == 1 ? ps.name : ps.nameEn;
                  itemObj.item_id = ps.pid;
                  itemObj.price = ps.price;
                  itemObj.addon_org_price = 0;
                  itemObj.item_list_name = cart[i].bigTypeName;
                  itemObj.item_list_id = cart[i].bigTypeId;
                  itemObj.quantity = ps.num;
                  itemObj.rec_usage = "";
                  itemObj.item_category = "";
                  itemObj.item_variant = ps.memo;
                  items.push(itemObj);
                });
              });
            });
          });
        } else {
          var itemObj = {};
          itemObj.item_name =
            this.$store.state.language == 1 ? cart[i].name : cart[i].nameEn;
          itemObj.item_id = cart[i].uid;
          itemObj.price = cart[i].price;
          itemObj.addon_org_price = 0;
          itemObj.item_list_name = cart[i].bigTypeName;
          itemObj.item_list_id = cart[i].bigTypeId;
          itemObj.quantity = cart[i].num;
          itemObj.rec_usage = "";
          itemObj.item_category = "";
          itemObj.item_variant = cart[i].memo;
          items.push(itemObj);
        }
      }
      return items;
    },
    dataLayerRemoveAllCart() {
      let items = this.setDataLayerParam();
      window.dataLayer.push({
        event: "remove_all_cart",
        ecommerce: {
          items,
        },
      });
    },
    //清空购物车
    clearCart() {
      this.$emit("clearNum");
      this.clearCoupon();
      this.$emit("closeCart");
      this.$store.commit("clearCart");
    },
    // 改变页面数量
    changeNum(params) {
      this.$emit("changeNum", params);
    },
    clearCoupon() {
      this.$emit("clearCoupon");
    },
    lookDesc() {
      this.active = !this.active;
    },
    updateFood(id) {
      var param = {
        id,
        change: true,
      };
      param = JSON.stringify(param);
      param = encodeURIComponent(param);
      this.$router.push("/foodDetail/" + param);
    },
    deleteCouPro() {
      var cart = this.$store.state.cart || [];
      for (var i = 0; i < cart.length; i++) {
        if (this.$refs["cartItem_" + cart[i].uid + i] && cart[i].isCouponPro) {
          this.$refs["cartItem_" + cart[i].uid + i].changeNum(1);
          break;
        }
      }
    },
    dataLayerPush() {
      let items = this.setDataLayerParam();
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items,
        },
      });
    },
  },
  components: {
    CartItem,
  },
  computed: {
    voucher() {
      return this.$store.state.couponInfo;
    },
    canShowVoucher() {
      if (
        this.$store.state.couponInfo &&
        this.$store.state.couponInfo.voucherType != 3 &&
        this.$store.state.couponInfo.voucherType != 5
      ) {
        return true;
      }
      return false;
    },
    couItemShow() {
      if (
        this.couponInfo &&
        this.couponInfo.voucherType != 3 &&
        this.couponInfo.voucherType != 5
      ) {
        return true;
      }
      return false;
    },
    couShow() {
      if (
        this.voucher &&
        (this.voucher.voucherType == 3 || this.voucher.voucherType == 5) &&
        this.voucher.copyPro &&
        this.voucher.copyPro.type == 1 &&
        !this.voucher.copyPro.property
      ) {
        return true;
      } else {
        return false;
      }
    },
    lanType() {
      return this.$store.state.language;
    },
    productList() {
      var cart = this.$store.state.cart;
      // this.$nextTick(() => {
      for (var i = 0; i < cart.length; i++) {
        var descStr = "";
        if (cart[i].type != 1 && cart[i].typeId != 1) {
          for (var c = 0; c < cart[i].selectedFood.groups.length; c++) {
            for (
              var d = 0;
              d < cart[i].selectedFood.groups[c].mains.length;
              d++
            ) {
              for (
                var j = 0;
                j < cart[i].selectedFood.groups[c].mains[d].groups.length;
                j++
              ) {
                for (
                  var k = 0;
                  k <
                  cart[i].selectedFood.groups[c].mains[d].groups[j].products
                    .length;
                  k++
                ) {
                  var pro =
                    cart[i].selectedFood.groups[c].mains[d].groups[j].products[
                      k
                    ];
                  if (
                    pro.type == 1 &&
                    pro.listPropertys &&
                    pro.listPropertys.length
                  ) {
                    for (var pl = 0; pl < pro.num; pl++) {
                      if (this.lanType == 1) {
                        descStr += pro.name + " ";
                      } else {
                        descStr += pro.nameEn + " ";
                      }
                      // for (var p = 0; p < pro.listPropertys.length; p++) {
                      var pPropertys = pro.listPropertys[pl].propertys;
                      for (var p_1 = 0; p_1 < pPropertys.length; p_1++) {
                        var pItems = pPropertys[p_1].items;
                        for (var p_l = 0; p_l < pItems.length; p_l++) {
                          if (this.lanType == 1) {
                            descStr += pItems[p_l].name;
                          } else {
                            descStr += pItems[p_l].nameEn;
                          }
                        }
                      }
                      descStr += " x1 ";
                      // }
                    }
                  } else {
                    if (this.lanType == 1) {
                      descStr +=
                        cart[i].selectedFood.groups[c].mains[d].groups[j]
                          .products[k].name +
                        " x " +
                        cart[i].selectedFood.groups[c].mains[d].groups[j]
                          .products[k].num +
                        " ";
                    } else {
                      descStr +=
                        cart[i].selectedFood.groups[c].mains[d].groups[j]
                          .products[k].nameEn +
                        " x " +
                        cart[i].selectedFood.groups[c].mains[d].groups[j]
                          .products[k].num +
                        " ";
                    }
                  }
                }
              }
            }
          }
        } else {
          if (this.lanType == 1) {
            descStr = cart[i].name + " x " + cart[i].num;
          } else {
            descStr = cart[i].nameEn + " x " + cart[i].num;
          }
          if (cart[i].listRequirements && cart[i].listRequirements.length) {
            var listRequirements = cart[i].listRequirements;
            for (var g = 0; g < listRequirements.length; g++) {
              var propertys = listRequirements[g].propertys;
              for (var l = 0; l < propertys.length; l++) {
                var items = propertys[l].items;
                for (var h = 0; h < items.length; h++) {
                  if (this.lanType == 1) {
                    descStr += items[h].name + " ";
                  } else {
                    descStr += items[h].nameEn + " ";
                  }
                }
              }
            }
          }
        }
        cart[i].descStr = descStr;
      }
      // });
      return cart;
    },
  },
  activated() {
    var cart = this.$store.state.cart || [];
    for (var i = 0; i < cart.length; i++) {
      if (this.$refs["cartItem_" + cart[i].uid + i]) {
        this.$refs["cartItem_" + cart[i].uid + i].setValue();
      }
    }
  },
  watch: {
    couponInfo(val) {
      if (!val) {
        this.deleteCouPro();
      }
    },
  },
  mounted() {
    // this.deleteCouPro();
    // this.dataLayerPush();
  },
  updated() {
    var cart = this.$store.state.cart || [];
    for (var i = 0; i < cart.length; i++) {
      if (this.$refs["cartItem_" + cart[i].uid + i]) {
        this.$refs["cartItem_" + cart[i].uid + i].setValue();
      }
    }
  },
};
</script>
<style scoped>
.coupon {
  width: 100%;
  background: #f5e9eb;
  opacity: 1;
  border-radius: 12px 12px 0px 0px;
  text-align: center;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #03060d;
  padding: 0.08rem;
  box-sizing: border-box;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.coupon span span {
  color: #e4022b;
}
.title {
  width: 100%;
  padding: 0 0.16rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #131314;
  margin-top: 0.16rem;
}
.title p:last-child {
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.22rem;
  color: #808080;
}
.cartItemBox {
  width: 100%;
  max-height: 2.2rem;
  overflow: scroll;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.sendMoney {
  width: 100%;
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #03060d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.16rem;
  box-sizing: border-box;
  margin: 0.16rem 0 0.24rem;
}

.cartItem {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.16rem 0;
  box-sizing: border-box;
}
.cartItem img {
  width: 0.9rem;
  height: 0.75rem;
  object-fit: cover;
  margin-right: 0.08rem;
}
.nameBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.remove {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  font-size: 0.11rem;
  color: #808080;
}
.name {
  width: 2rem;
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.2rem;
  color: #030f14;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* display: flex;
  justify-content: flex-start; */
}
.name span {
  padding: 0 0.02rem;
  color: #e4022b;
  border: 0.01rem solid #e4022b;
  border-radius: 0.02rem;
}
.content {
  width: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.14rem;
  text-align: left;
  background: #fff;
  padding: 0.02rem 0;
  box-sizing: border-box;
  margin: 0.1rem 0;
  color: #e4022b;
}

.content.active {
  color: #030f14;
}

.content span {
  width: 2.15rem;
  transition: all 0.2s linear;
}
.content span.active {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.price {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.18rem;
  color: #03060d;
  font-size: 0.1rem;
}
.money span {
  font-size: 0.16rem;
}
.foodNum {
  display: flex;
  justify-content: center;
  align-items: center;
}
.foodNum.Qactive {
  margin-right: 0.05rem;
}
.foodNum.Qactive input {
  width: 0.24rem;
  margin: 0 0.15rem;
}
.foodNum input {
  width: 0.2rem;
  margin: 0 0.02rem;
  outline: none;
  border: none;
  text-align: center;
}
.foodNum span {
  width: 0.2rem;
  height: 0.2rem;
  border: 0.02rem solid #e4022b;
  border-radius: 0.04rem;
  line-height: 0.18rem;
  text-align: center;
  color: #e4022b;
  font-size: 0.18rem;
}
.foodNum span {
  background-color: #e4022b;
  color: #fff;
}
.foodNum span:last-child.active {
  background-color: #bbb3b3;
  border-color: #bbb3b3;
}
.content span.active {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.itemLine {
  border-bottom: 0.01rem solid #c4c4c4;
}
</style>