<template>
  <div class="box" id="box" @scroll="bodyScroll">
    <div class="topbar" id="topbar">
      <img class="topIcon" src="@/assets/icon/mainT.png" alt="" />
      <div>
        <p class="topTitle active" @click="confirmBack()"></p>
        <!-- <p class="topTitle" id="topTitle" v-if="businessType == 1">
          {{ morning
          }}{{ $store.state.userInfo ? $store.state.userInfo.firstName : "" }}！
        </p> -->
        <div class="topInfo">
          <div class="topLeft">
            <span class="backIcon"></span>
            <div class="topPlace">
              <p @click="showPlace(true)">
                <span>{{ place }}</span>
                <i></i>
              </p>
              <div
                class="timeFunc"
                v-if="businessType == 1 || businessType == 2"
              >
                <!-- <span v-if="businessType == 1">{{ meiWeiSuDa }}｜</span>
                <span v-if="businessType == 2">{{ new_CD_ziQi }}｜</span>
                <span v-if="businessType == 3">{{ tangShi }}｜</span> -->
                <div v-if="book != 2">
                  <span v-if="quoteTime">
                    {{ businessType == 2 ? dateTextPick : dateTextOne
                    }}{{ quoteTime
                    }}{{ businessType == 1 ? dateTextTwo : dateTextThree }}
                  </span>

                  <span v-else>
                    {{ YJ_time_err }}
                  </span>
                </div>
                <span v-else> {{ second }}{{ dateTextFour }} </span>
              </div>
            </div>
          </div>
          <div class="topRight" v-if="businessType != 3">
            <span
              @click="selectBussinessType(1)"
              :class="{ active: businessType == 1 }"
              >{{ suDa }}</span
            >
            <span
              @click="selectBussinessType(2)"
              :class="{ active: businessType == 2 }"
              >{{ new_CD_ziQi }}</span
            >
          </div>
          <div class="topRight" v-else>
            <span
              @click="selectTakeaway(1)"
              :class="{ active: takeaway == 1 }"
              >{{ tangShi }}</span
            >
            <span
              @click="selectTakeaway(2)"
              :class="{ active: takeaway == 2 }"
              >{{ new_TS_ziQi }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="banner-box">
      <div v-if="menuBanner.menuAdvertList">
        <swiper :imglist="menuBanner.menuAdvertList"></swiper>
      </div>
      <div v-if="shopNow.announArr">
        <notice-swiper :brandInfos="shopNow.announArr"></notice-swiper>
      </div>
    </div>
    <div class="func funcbox"></div>
    <div class="func" id="func">
      <!-- <i style="width；0px" ></i> -->
      <p class="funcTitle" v-if="businessType == 3"></p>
      <p class="sendTime" v-else>
        <span @click="changeSendTime(1)" :class="{ active: book == 1 }">{{
          jiShi
        }}</span>
        <span @click="changeSendTime(2)" :class="{ active: book == 2 }">{{
          yuYue
        }}</span>
      </p>
      <p class="coupon" :class="{ yuuActive: isYuu }" @click="selectCoupon">
        <i></i>
        <span>{{ coupon }}</span>
      </p>
    </div>
    <food-list
      v-if="!showDefortImg"
      ref="foodList"
      id="foodList"
      :storePres="storePres"
      :productMenu="productMenu"
      :businessType="businessType"
      :second="second"
      :book="book"
      :shopName="shopNow.shopName"
      @topHeight="getHeight"
      @clearCoupon="cancelCoupon"
      @setS="setS"
      @dataLayerPush="dataLayerPush"
      @checkCoupon="checkCoupon"
      :fee="shopNow.fee"
      :reachFee="shopNow.reachFee"
      :couponInfo="couponInfo"
      :canToPay="canToPay"
      :takeaway="takeaway"
    ></food-list>

    <div class="deforeFoodImg" v-else>
      <img
        v-if="$store.state.language == 1"
        src="@/assets/icon/D-CN.png"
        alt=""
      />
      <img v-else src="@/assets/icon/D-EN.png" alt="" />
    </div>

    <div class="datePicker" :class="{ active: showPicker }">
      <div class="selectTimeTitle">{{ XZSJ }}</div>
      <van-picker
        ref="timePicker"
        :title="selectTime"
        :confirm="dateConfirm"
        :cancel="dateCancel"
        :columns="columns"
        @confirm="finishTime"
        @cancel="showDatePicker(1)"
        @change="changeTime"
      />
    </div>
    <div v-show="showPicker" @click="showDatePicker(9)" class="pickerBg"></div>
    <!-- 提示框 -->
    <div class="confirmBox">
      <food-confirm
        v-show="showConfirm"
        :title="confirmTitle"
        @confirm="backPage"
        @cancle="closePage"
        :leftBtn="leftBtn"
        :rightBtn="rightBtn"
      ></food-confirm>
    </div>
    <!-- 优惠券错误 -->
    <div class="confirmBox">
      <food-confirm
        v-show="showCouponError"
        :title="couponErrorTitle"
        :subTitle="couponErrorSubTitle"
        :subTitleE="couponErrorSubTitleE"
        :subTitleF="couponErrorSubTitleF"
        @confirm="JXorderFood"
        @cancle="closeFrameCouError"
        :leftBtn="voucherErrorLeftBtn"
        :rightBtn="QWFK"
      ></food-confirm>
    </div>
    <!-- 返回按鈕提示框 -->
    <div class="confirmBox">
      <food-confirm
        v-show="backConfirm"
        :title="backTitle"
        @confirm="confirmCancle"
        @cancle="confirmBack"
        :leftBtn="backLeftBtn"
        :rightBtn="backRightBtn"
      ></food-confirm>
    </div>
    <div class="confirmBox">
      <food-confirm
        v-show="showErrorFood"
        :title="errorFoodTit"
        @confirm="backFunc"
        @cancle="closeFunc"
        :leftBtn="errorFoodMsgCancel"
        :rightBtn="errorFoodMsgConfirm"
      ></food-confirm>
    </div>
    <!-- 选择地址 -->
    <div class="placeList">
      <myplace
        ref="myPlace"
        v-show="placeShow"
        :nowLocationP="nowLocationP"
        @select="selectPlace"
        @update="showPlace"
        @closeMyPlace="closeMyPlace"
        :appInfo="appInfo"
      ></myplace>
    </div>
    <!-- 选择门店弹窗 -->
    <div
      class="shopListBox"
      :class="{ active: showShopList }"
      @click="closeShopList"
      id="shopListBox"
    >
      <div class="shopList">
        <p class="shopListTitle">請選擇餐廳</p>
        <div class="itemBox">
          <shop-item
            @click="selectShop(item)"
            v-for="item in shopList"
            :key="item.storeId"
            :shopInfo="item"
            :showDetail="false"
          ></shop-item>
        </div>
      </div>
    </div>
    <!-- 优惠券列表 -->
    <div class="useCoupon" v-show="showCoupon">
      <use-coupon
        :couponList="couponList"
        :productMenu="productMenu"
        :storePres="storePres"
        :businessType="businessType"
        @closeCoupon="selectCoupon"
        @finishSelect="finishSelect"
        :isCheck="false"
      ></use-coupon>
    </div>
    <!-- 不在營業時間內彈窗 -->
    <div class="warnBox" v-show="showErrorTime">
      <confirm-box
        :show="false"
        @finish="closeErrorTime"
        :title="businessType == 1 ? DC_time_err_JS : DC_time_err_one"
        :subTitle="DC_time_err_two"
        :subTitleShow="true"
        :size="16"
        :finishBtn="finishBtn"
      ></confirm-box>
    </div>
    <!-- 不在營業時間內彈窗 -->
    <div class="warnBox" v-show="isNoStore">
      <confirm-box
        :show="false"
        @finish="closeErrorStore"
        :title="isNoStoreMsg"
        :size="16"
        :finishBtn="finishBtn"
      ></confirm-box>
    </div>
    <div class="warnBox" v-show="showErrorNear">
      <confirm-box
        :show="false"
        :size="16"
        @finish="closeErrorNear"
        :title="MDFMALERTTITLE"
        :subTitle="MDFMALERT"
        :subTitleShow="true"
        content=""
      ></confirm-box>
    </div>
    <div class="warnBox" v-show="showIsBusy">
      <confirm-box
        :show="false"
        :size="16"
        @finish="closeIsBusy"
        :title="MDFMALERTTITLE"
        :subTitle="MDFMALERT"
        :subTitleShow="true"
        :finishBtn="finishIsBusyBtn"
      ></confirm-box>
    </div>
    <!-- 没有可预约时间 -->
    <div class="warnBox" v-show="isNotHastime">
      <confirm-box
        :show="false"
        :size="16"
        @finish="closeIsNotTime"
        :title="BZCYY"
        :finishBtn="finishIsBusyBtn"
      ></confirm-box>
    </div>
    <!-- 餐品不在供应时间 -->
    <div class="warnBox" v-show="isNotProvideTime">
      <confirm-box
        :show="false"
        :size="16"
        @finish="closeNotProvideTime"
        :title="GWC_remove"
        :finishBtn="finishIsBusyBtn"
      ></confirm-box>
    </div>
  </div>
</template>
<script>
import Swiper from "../../components/swiper/Swiper.vue";
import NoticeSwiper from "../../components/swiper/NoticeSwiper.vue";
import FoodList from "../../components/food/FoodList.vue";
import FoodConfirm from "../../components/food/OrderConfirm.vue";
import Myplace from "../../components/myplace/Myplace.vue";
import ShopItem from "../../components/shop/ShopItem.vue";
import UseCoupon from "../../components/food/UseCoupon.vue";
import ConfirmBox from "../../components/common/CnfirmBox.vue";
import { wgs_gcj_encrypts } from "../../assets/utils/coordinate";

import language from "../../assets/js/language.js";
import { postDC, postPaas } from "../../assets/utils/request";
import { copyData } from "../../assets/utils/copy";
import { mapState } from "vuex";
import checkCouonType from "../../assets/utils/checkCouonType";
import setAppointment from "../../assets/utils/appointment";
import setHashTag from "../../assets/utils/setHashTag";
import { provide } from "@vue/runtime-core";

export default {
  name: "OrderFood",
  data() {
    return {
      pageParam: {}, //页面传递到的参数
      showIsBusy: false, //当前门店是否繁忙
      isbusyBack: false, //预约时间后如果门店繁忙点击是否要回到首页
      canAppoinmentStore: [], //可以预约的门店
      isNotHasAdvanceTimes: false, //是否有可预约时间
      isNotHastime: false, //没有可预约时间的弹窗
      BZCYY: "", //没有可预约时间的弹窗文案
      isNotProvideTime: false, //餐品是否在供应时间范围内
      showCouponError: false, //优惠券不可用
      isChangePage: true, //选择优惠券之后是否要跳到确认订单页面
      checkSelectCouponTimer: null, //检查优惠券节流定时器
      couponErrorTitle: "", //优惠券不可用title
      couponErrorSubTitle: "",
      couponErrorSubTitleE: "",
      couponErrorSubTitleF: "",
      voucherErrorLeftBtn: "", //优惠券错误弹窗左按钮
      isClearVoucher: false, //点击优惠券错误弹窗左按钮是否清除优惠券
      showDefortImg: false, //没有餐单
      clear: false,
      showErrorTime: false, //不在營業時間
      isNoStore: false, //没有符合条件门店时显示
      getPresTimer: null, //获取优惠节流定时器
      bookCanCancel: true,
      lanType: this.$store.state.language,
      position: "relative", //滚动时是否固定位置
      businessType: null, //业务类型，1：外卖；2：自取；3：堂食 4外卖预约
      productMenu: {}, //餐单
      storeId: null, //门店id
      storePres: {}, //门店优惠
      second: 0, //预约时间
      book: 1,
      imglist: [1],
      showErrorNear: false,
      seendTime: 0, //预约时间
      topbarHeight: 0, //标题栏高度
      start: 0, //手指在屏幕上的初始位置
      height: 0,
      goTop: 0,
      showPicker: false, //显示时间选择器
      confirmTitle: "", //提示框的标题
      leftBtn: "",
      rightBtn: "",
      confirmInfo: "你所選擇的門店不支援一下餐品", //提示框内容
      showConfirm: false, //是否显示弹窗
      backConfirm: false, //返回按鈕彈窗
      backTitle: "",
      backLeftBtn: "",
      backRightBtn: "",
      placeShow: false, //选择地址弹窗
      shopNow: {}, //现在所选地附近的门店
      shopList: [], //附近门店
      noBusyStorelist: [], //附近不繁忙的门店
      canStoreList: [], //附近在营业时间内的门店
      showErrorFood: false,
      errorFoodTit: "",
      menuBanner: [], //门店banner
      showShopList: false, //是否显示门店列表
      foodListTop: null, //餐品列表距离顶部的距离
      bodyScrollTop: null, //整体的滑动距离
      couponInfo: null, //选择或者传过来的优惠券信息
      takeaway: 1, //堂食/自取
      couponList: [], //优惠券列表
      showCoupon: false, //是否显示优惠券
      rightBtnCan: true, //剛進頁面電機右邊按鈕
      quoteTime: null, //预计时间
      minutesData: {},
      pagePlace: null,
      canToPay: false, //是否可以点击去付款
      canUseCoupon: "優惠券不可用", //
      unFood: "",
      appInfo: null,
      scrollTopHeight: 0,
      hours: [],
      minutesStart: [],
      minutesEnd: [],
      columns: [
        // 第一列
        {
          values: [],
          defaultIndex: 0,
        },
        // 第二列
        {
          values: [],
          defaultIndex: 0,
        },
        {
          values: [],
          defaultIndex: 0,
        },
      ],
      heightSc: 0,
      // 静态文字
      morning: "",
      suDa: "",
      new_CD_ziQi: "",
      new_TS_ziQi: "",
      jiShi: "",
      yuYue: "",
      coupon: "",
      selectTime: "",
      tangShi: "",
      meiWeiSuDa: "",
      new_CD_dianCan: "",
      DC_time_err_one: "",
      DC_time_err_JS: "",
      DC_time_err_two: "",
      DC_time_err_morning: "",
      DC_time_err_dinner: "",
      finishBtn: "",
      YJ_time_err: "",
      GWC_del: "",
      GWC_remove: "",
      dateConfirm: "",
      dateCancel: "",
      dateTextOne: "",
      dateTextTwo: "",
      dateTextThree: "",
      dateTextPick: "",
      dateTextFour: "",
      nowLocationP: "",
      SYMK: "",
      BZSYSJFW: "",
      GWCWKYHCW: "",
      HQXXSB: "",
      WFSYYHQ: "",
      BZPSFWN: "",
      BZCD: "",
      BZCDHYSQ: "",
      BZCDHYSQSUB: "",
      BZDQSJDSUB: "",
      BZDQSJDSUBF: "",
      MZDDZKSYYZQSUB: "",
      MZDDZKSYYZQSUBE: "",
      errorFoodMsgConfirm: "",
      errorFoodMsgCancel: "",
      SXDZBZFWFWN: "",
      isNoStoreMsg: "",
      MDFMALERTTITLE: "",
      MDFMALERT: "",
      finishIsBusyBtn: "",
      XZSJ: "",
      BZCZQYY: "",
      BZCWMYY: "",
      WDSYMK: "",
      WDSYMKSUBJE: "",
      WDSYMKSUBCP: "",
      WDSYMKSUB: "",
      JXXG: "",
      QWFK: "",
      QSJG: "",
      CDYFH: "",
      CDYBSYMD: "",
      SCSJYG:""
    };
  },
  methods: {
    // 关闭附近没有符合门店的弹窗
    closeErrorStore() {
      this.isNoStore = false;
      // 如果是从app跳过来 则返回app首页
      if (this.$store.state.app) {
        this.$store.commit("clearRouter");
        window.location = "kfchk://exith5";
      } else {
        this.$router.back(-1);
      }
    },
    JXorderFood() {
      this.showCouponError = false;
      if (this.isClearVoucher) {
        this.deleteCouponInfo();
      }
    },
    closeFrameCouError() {
      this.showCouponError = false;
      if (this.isChangePage) {
        this.$refs.foodList.$refs.cartBar.changePage();
      }
    },
    confirmCancle() {
      window.dataLayer.push({
        event: "confirm_ send_order",
      });
      // 如果是从app跳过来 则返回app首页
      if (this.$store.state.app) {
        this.$store.commit("clearRouter");
        // this.$store.commit("saveApp", false);
        window.location = "kfchk://exith5";
      } else {
        this.$router.push("/");
      }
    },
    confirmBack() {
      window.dataLayer.push({
        event: "view_send_order",
      });
      this.backConfirm = !this.backConfirm;
    },
    backFunc() {
      // 如果是从app跳过来 则返回app首页
      if (this.$store.state.app) {
        this.$store.commit("clearRouter");
        window.location = "kfchk://exith5";
      } else {
        this.$router.back(-1);
      }
    },
    closeFunc() {
      this.showErrorFood = !this.showErrorFood;
    },
    // 關閉營業時間時間彈唱
    closeErrorTime() {
      this.showErrorTime = false;
      if (this.businessType != 1 && this.businessType != 2) {
        // 如果是从app跳过来 则返回app首页
        if (this.$store.state.app) {
          this.$store.commit("clearRouter");
          window.location = "kfchk://exith5";
        } else {
          this.$router.push("/");
        }
      } else {
        this.changeSendTime(2);
      }
    },
    // 选择可使用的优惠券
    selectCoupon() {
      // yuu跳过来不可以使用优惠券
      if (this.isYuu) return;
      // this.$store.commit("saveShowCoupon", true);
      this.showCoupon = !this.showCoupon;
    },
    // 选择的优惠券信息 如果选择的是赠送餐品券 获取餐品的id
    async finishSelect(info) {
      if (info.voucherType == 3 || info.voucherType == 5) {
        if (info.voucherProductCode && info.voucherProductCode.length) {
          let R = await this.getVoucherProAndJoin(info);
          if (!R) return;
          info = R;
        }
        this.deleteCouPro();
        this.$store.commit("saveCouponInfo", info);
        this.lookDetail(info.copyPro);
      } else {
        this.deleteCouPro();
        this.$store.commit("saveCouponInfo", info);
      }
    },
    // 刪除購物車的優惠餐品
    deleteCouPro() {
      var cart = this.$store.state.cart || [];
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].isCouponPro) {
          var productObj = {};
          var product = cart[i];
          product.num = 0;
          productObj.product = product;
          productObj.cart = true;
          this.$store.commit("createCart", productObj);
          break;
        }
      }
    },
    // 取消选的优惠券
    cancelCoupon() {
      this.clear = true;
      this.couponInfo = null;
      // 清除vuex中的优惠券
      this.$store.commit("saveCouponInfo", null);
      this.$store.commit("saveShowCoupon", true);
    },
    // 弹窗
    closePage() {
      if (this.rightBtn == "繼續落單" || this.rightBtn == "Confirm") {
        this.showConfirm = false;
        this.rightBtnCan = false;
        window.dataLayer.push({
          event: "confirm_ continue_order",
        });
        return;
      }
      if (this.$store.state.app) {
        this.$store.commit("clearRouter");
        window.location = "kfchk://exith5";
      } else {
        this.$router.push("/");
      }
      window.dataLayer.push({
        event: "confirm_ send_order",
      });
    },
    // 切换业务类型
    selectBussinessType(type) {
      if (this.businessType == type) return;
      // this.$store.commit("clearCart");
      this.showPicker = false;
      if (type == 1) {
        // this.businessType = type;
        // this.getProductMenu();
        // 在自取页面选择外卖时 获取此时的参数
        let P = this.$route.params.type;
        let Q = {
          path: "/transfer",
          query: {},
        };
        // 设置 KFCJoinType 的值 当 KFCJoinType ==1 跳转餐单页面 否则返回上一页 在 Transfer。vue中使用
        localStorage.setItem("KFCJoinType", 1);
        if (P == 1) {
          Q.query.businessType = 1;
        } else {
          Q.query = P;
        }
        this.$router.push(Q);
      } else if (type == 2) {
        this.$router.push({
          path: "/selectShop",
          query: { type },
        });
      }
      // this.showShopList = !this.showShopList;
    },
    // 切换堂食/自取
    selectTakeaway(type) {
      this.takeaway = type;
    },
    // 查看门店是否在营业时间
    checkStoreBusinessTime() {
      let nowTime = new Date().getTime();
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      let day = new Date().getDate();
      let date = year + "/" + month + "/" + day;
      if (this.book == 2) {
        nowTime = new Date(this.second).getTime();
        date = this.second.split(" ")[0];
      }
      let businessTimes = null;
      if (this.shopNow) {
        businessTimes = this.shopNow.businessTimes;
        if (this.businessType == 2) {
          if (this.shopNow.storeTakeEatBusinessTimeSetting) {
            businessTimes = this.shopNow.storeTakeEatBusinessTimeOfDay;
          }
        } else if (this.businessType == 3) {
          if (this.shopNow.storeHereEatBusinessTimeSetting) {
            businessTimes = this.shopNow.storeHereEatBusinessTimeOfDay;
          }
        }
        this.shopNow.businessTimes = businessTimes;
      }
      if (businessTimes) {
        // 查看当前门店是否在营业时间范围内
        this.showDefortImg = false;
        for (var STY = 0; STY < businessTimes.length; STY++) {
          var businessTimesStartOne = new Date(
            date + " " + businessTimes[STY].beginTime
          ).getTime();
          var businessTimesEndOne = new Date(
            date + " " + businessTimes[STY].endTime
          ).getTime();
          var canShow = false;
          if (
            nowTime >= businessTimesStartOne &&
            nowTime <= businessTimesEndOne
          ) {
            canShow = true;
            break;
          }
        }
        let endBusinessTime = businessTimes[businessTimes.length - 1];
        let hashTagText = "";
        if (
          nowTime > new Date(date + " " + endBusinessTime.endTime).getTime()
        ) {
          hashTagText = this.$store.state.language == 1 ? "明天" : "tomorrow";
        }
        if (!canShow) {
          // 如果不在营业时间范围内 弹出提示
          this.DC_time_err_two =
            this.DC_time_err_morning +
            " " +
            businessTimes[0].beginTime +
            " " +
            this.DC_time_err_dinner +
            " " +
            businessTimes[businessTimes.length - 1].endTime;
          this.shopNow.announArr = [];
          this.shopNow.announArr[0] = {
            announcement:
              "現在預訂，最快於" +
              hashTagText +
              "上午" +
              businessTimes[0].beginTime +
              "取餐",
            announcementEn:
              "Order now, your order will be ready " +
              hashTagText +
              " at " +
              businessTimes[0].beginTime +
              " earliest",
          };
          if (this.businessType == 1) {
            this.bookCanCancel = false;
          }
          this.showErrorTime = true;
          this.showDefortImg = true;
          // return;
        }
      }
    },
    // 获取餐单
    async getProductMenu() {
      if (
        this.pageParam.type != 1 &&
        this.pageParam.type.app &&
        this.pageParam.type.reference
      ) {
        // 再来一单
        var again = await this.getAgainStore();
        if (!again) {
          this.$toast(this.HQXXSB);
          return;
        }
      }
      if (this.businessType == 1) {
        // 当是外卖的时候 查看是否在配送范围内
        this.getDeliveryRange();
      }
      // 获取门店详情
      var storePush = await this.getStoreDetail();
      if (!storePush) return;
      var nowTime = new Date().getTime();
      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      var day = new Date().getDate();
      var date = year + "/" + month + "/" + day;
      if (this.book == 2) {
        nowTime = new Date(this.second).getTime();
        date = this.second.split(" ")[0];
      }
      this.checkStoreBusinessTime();
      // if (
      //   this.shopNow &&
      //   this.shopNow.businessTimes &&
      //   this.shopNow.businessTimes.length
      // ) {
      //   // 查看当前门店是否在营业时间范围内
      //   this.showDefortImg = false;
      //   for (var STY = 0; STY < this.shopNow.businessTimes.length; STY++) {
      //     var businessTimesStartOne = new Date(
      //       date + " " + this.shopNow.businessTimes[STY].beginTime
      //     ).getTime();
      //     var businessTimesEndOne = new Date(
      //       date + " " + this.shopNow.businessTimes[STY].endTime
      //     ).getTime();
      //     var canShow = false;
      //     if (
      //       nowTime >= businessTimesStartOne &&
      //       nowTime <= businessTimesEndOne
      //     ) {
      //       canShow = true;
      //       break;
      //     }
      //   }
      //   if (!canShow) {
      //     // 如果不在营业时间范围内 弹出提示
      //     this.DC_time_err_two =
      //       this.DC_time_err_morning +
      //       " " +
      //       this.shopNow.businessTimes[0].beginTime +
      //       " " +
      //       this.DC_time_err_dinner +
      //       " " +
      //       this.shopNow.businessTimes[this.shopNow.businessTimes.length - 1]
      //         .endTime;
      //     this.shopNow.announArr = [];
      //     this.shopNow.announArr[0] = {
      //       announcement:
      //         "現在預訂，最快於明天上午" +
      //         this.shopNow.businessTimes[0].beginTime +
      //         "取餐",
      //       announcementEn:
      //         "Order now, your order will be ready tomorrow at " +
      //         this.shopNow.businessTimes[0].beginTime +
      //         " earliest",
      //     };
      //     if (this.businessType == 1) {
      //       this.bookCanCancel = false;
      //     }
      //     this.showErrorTime = true;
      //     this.showDefortImg = true;
      //     // return;
      //   }
      // }
      var params = {
        actionName: "candao.product.getProductMenu",
        content: {
          storeId: this.storeId,
          sendType: this.businessType,
        },
      };
      if (this.book == 2) {
        // 如果是预约的话 添加预约时间参数
        let reserve = this.second.split(" ");
        let reserveDate = reserve[0].split("/").join("-");
        params.content.reserveDate = reserveDate + " " + reserve[1];
      }
      let productMenuRes = await postDC("Action", params);
      if (productMenuRes.status == 1) {
        var week = new Date().getDay();
        if (this.book == 2) {
          week = new Date(this.second).getDay();
        }
        // 当是周日的时候 修改week为 7 后端返回的周日为7
        if (week == 0) {
          week = 7;
        }
        // 餐单
        // 过滤餐单
        var bigsOne = productMenuRes.data.bigs;
        for (var j = 0; j < bigsOne.length; j++) {
          if (bigsOne[j].name == "COPY" || bigsOne[j].nameEn == "COPY")
            continue;
          var smallsOne = bigsOne[j].smalls;
          for (var f = 0; f < smallsOne.length; f++) {
            var productArr = [];
            var productsOne = smallsOne[f].products;
            for (var d = 0; d < productsOne.length; d++) {
              if (
                !productsOne[d].isDiscontinue &&
                productsOne[d].status == 1 &&
                productsOne[d].isInServiceTime
              ) {
                if (
                  productsOne[d].serviceWeeks &&
                  productsOne[d].serviceWeeks.length
                ) {
                  if (productsOne[d].serviceWeeks.indexOf(week) > -1) {
                    if (
                      productsOne[d].serviceTimes &&
                      productsOne[d].serviceTimes.length
                    ) {
                      for (
                        var ST = 0;
                        ST < productsOne[d].serviceTimes.length;
                        ST++
                      ) {
                        var startTime = new Date(
                          date + " " + productsOne[d].serviceTimes[ST].start
                        ).getTime();
                        var endTime = new Date(
                          date + " " + productsOne[d].serviceTimes[ST].end
                        ).getTime();
                        if (nowTime >= startTime && nowTime <= endTime) {
                          productArr.push(productsOne[d]);
                          break;
                        }
                      }
                    } else {
                      if (
                        this.shopNow &&
                        this.shopNow.businessTimes &&
                        this.shopNow.businessTimes.length
                      ) {
                        for (
                          let be = 0;
                          be < this.shopNow.businessTimes.length;
                          be++
                        ) {
                          var businessTimesStart = new Date(
                            date +
                              " " +
                              this.shopNow.businessTimes[be].beginTime
                          ).getTime();
                          var businessTimesEnd = new Date(
                            date + " " + this.shopNow.businessTimes[be].endTime
                          ).getTime();
                          if (
                            nowTime >= businessTimesStart &&
                            nowTime <= businessTimesEnd
                          ) {
                            productArr.push(productsOne[d]);
                            break;
                          }
                        }
                      } else {
                        productArr.push(productsOne[d]);
                      }
                    }
                  }
                } else {
                  // 没有星期几
                  if (
                    productsOne[d].serviceTimes &&
                    productsOne[d].serviceTimes.length
                  ) {
                    for (
                      var ST1 = 0;
                      ST1 < productsOne[d].serviceTimes.length;
                      ST1++
                    ) {
                      var startTime1 = new Date(
                        date + " " + productsOne[d].serviceTimes[ST1].start
                      ).getTime();
                      var endTime1 = new Date(
                        date + " " + productsOne[d].serviceTimes[ST1].end
                      ).getTime();
                      if (nowTime >= startTime1 && nowTime <= endTime1) {
                        productArr.push(productsOne[d]);
                        break;
                      }
                    }
                  } else {
                    if (
                      this.shopNow &&
                      this.shopNow.businessTimes &&
                      this.shopNow.businessTimes.length
                    ) {
                      for (
                        let be = 0;
                        be < this.shopNow.businessTimes.length;
                        be++
                      ) {
                        var businessTimesStart1 = new Date(
                          date + " " + this.shopNow.businessTimes[be].beginTime
                        ).getTime();
                        var businessTimesEnd1 = new Date(
                          date + " " + this.shopNow.businessTimes[be].endTime
                        ).getTime();
                        if (
                          nowTime >= businessTimesStart1 &&
                          nowTime <= businessTimesEnd1
                        ) {
                          productArr.push(productsOne[d]);
                          break;
                        }
                      }
                    } else {
                      productArr.push(productsOne[d]);
                    }
                  }
                }
              }
              smallsOne[f].products = productArr;
            }
          }
        }
        // 去掉二级分类
        for (var pm = 0; pm < productMenuRes.data.bigs.length; pm++) {
          var psSmall = productMenuRes.data.bigs[pm].smalls;
          var smallArr = [];
          for (var ps = 0; ps < psSmall.length; ps++) {
            var ppPro = psSmall[ps].products;
            if (ppPro && ppPro.length) {
              smallArr.push(psSmall[ps]);
            }
          }
          productMenuRes.data.bigs[pm].smalls = smallArr;
        }
        // 去掉一级分类
        var bigsArr = [];
        for (var bm = 0; bm < productMenuRes.data.bigs.length; bm++) {
          if (productMenuRes.data.bigs[bm].smalls.length) {
            bigsArr.push(productMenuRes.data.bigs[bm]);
          }
        }
        productMenuRes.data.bigs = bigsArr;
        this.productMenu = productMenuRes.data;
        // 存储的购物车中餐品 属于哪个 业务类型 和 门店
        this.clearCartPro();
        // 检查购物车餐品
        this.checkCart();
        if (productMenuRes.data.bigs.length == 0) {
          // this.$toast("暫無餐品供應");
          return;
        }
        // 触发埋点
        this.dataLayerPush("view_item_list");
        // 获取页面的顶部banner图
        this.getMenuBanner();
        // 获取门店的可用优惠信息
        await this.getStorePres();
        if (this.pageParam.type && this.pageParam.type != 1) {
          // 当传过来的参数重有优惠券信息是
          if (this.pageParam.type.coupon) {
            // 当 firstJoinCoupon != 1 是才需要检查优惠券
            if (sessionStorage.getItem("firstJoinCoupon") != 1) {
              sessionStorage.setItem("firstJoinCoupon", 1);
              this.$store.commit("saveShowCoupon", false);
              // 优惠券为赠品券 一口价券 时直接进入餐品详情页面选择优惠餐品详情
              if (
                this.pageParam.type.coupon.voucherType == 3 ||
                this.pageParam.type.coupon.voucherType == 5
              ) {
                // 获取赠送餐品的详情 加入购物车
                let R = await this.getVoucherProAndJoin(
                  this.pageParam.type.coupon
                );
                if (R) {
                  this.$store.commit(
                    "saveCouponInfo",
                    this.pageParam.type.coupon
                  );
                  this.lookDetail(R.copyPro);
                }
              } else {
                // 其他券种直接保存优惠券信息
                this.$store.commit(
                  "saveCouponInfo",
                  this.pageParam.type.coupon
                );
              }
            }
          }
          //
          if (this.pageParam.type.products) {
            this.checkCart(this.pageParam.type.products);
          }
          // 从banner跳转过来
          if (this.pageParam.type.page == 1) {
            // jumpType == 6 时 带过来的时餐品 检查餐品是否可用
            if (this.pageParam.type.jumpType == 6) {
              this.checkBannerFood();
            }
            // jumpType == 2 时带过来的是分类 滑动到餐单指定位置
            if (this.pageParam.type.jumpType == 2) {
              this.checkBannerMenu();
            }
          }
          // 再来一单
          if (this.pageParam.type.reference) {
            this.getAgainOrder(again.orderId, productMenuRes.data.menuId);
          }
        }

        this.$nextTick(() => {
          if (this.$refs.foodList) {
            // 设置单品在页面中的数量
            this.$refs.foodList.setNum();
          }
        });

        this.canToPay = true;
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    // 餐品券获取 赠送的餐品 进去餐品详情选取餐品
    async getVoucherProAndJoin(info) {
      if (!info.voucherProductCode || !info.voucherProductCode.length) {
        this.couponErrorTitle = this.WFSYYHQ;
        this.couponErrorSubTitle = "";
        this.couponErrorSubTitleE = "";
        this.couponErrorSubTitleF = "";
        this.voucherErrorLeftBtn = this.JXXG;
        this.couponInfo = null;
        this.showCouponError = true;
        this.isClearVoucher = true;
        return false;
      }
      let isCandao = true;
      if (info.voucherCalType == null) {
        isCandao = false;
      }
      let brandId = 41;
      let H5Seting = this.$store.state.H5Seting;
      if (H5Seting) {
        let brandInfos = H5Seting.brandInfos;
        if (brandInfos && brandInfos.length) {
          brandId = brandInfos[0].brandId;
        }
      }
      var params = {
        actionName: "candao.product.getProductMapping",
        content: {
          posId: 396,
          mappingCode: info.voucherProductCode[0],
          brandId,
        },
      };
      let result = await postDC("Action", params);
      if (result.status == 1) {
        if (result.data) {
          var mappingId;
          var bigs = this.productMenu.bigs;
          // 检查赠送的餐品 是否在餐单中
          for (var a = 0; a < bigs.length; a++) {
            for (var b = 0; b < bigs[a].smalls.length; b++) {
              // 赠送的餐品只能在餐单的COPY分类中 如果COPY分类中没有当前赠送的餐品 则优惠券不可以用
              if (
                bigs[a].smalls[b].name == "COPY" ||
                bigs[a].smalls[b].nameEn == "COPY"
              ) {
                for (var c = 0; c < bigs[a].smalls[b].products.length; c++) {
                  if (
                    bigs[a].smalls[b].products[c].uid == result.data.mappingId
                  ) {
                    mappingId = bigs[a].smalls[b].products[c].uid;
                    info.mappingPro = result.data;
                    info.copyPro = copyData(bigs[a].smalls[b].products[c]);
                    info.copyPro.isCouponPro = true;
                    info.copyPro.isCouponProType = info.voucherType;
                    // 当优惠券类型为 3 5 时 为获取到的餐品重新设置价格
                    if (info.voucherType == 5 || info.voucherType == 3) {
                      if (isCandao) {
                        //如果是餐道发行的优惠券 则使用 canDaoFixPrice 为餐品重新设置价格
                        result.data.mappingPrice = info.canDaoFixPrice / 100;
                        info.copyPro.price = info.canDaoFixPrice / 100;
                      } else {
                        //如果是stillect发行的优惠券 则使用餐单中COPY分类中的价格为餐品重新设置价格
                        result.data.mappingPrice =
                          bigs[a].smalls[b].products[c].price;
                        info.copyPro.price =
                          bigs[a].smalls[b].products[c].price;
                      }
                    } else {
                      result.data.mappingPrice =
                        bigs[a].smalls[b].products[c].price;
                    }
                    break;
                  }
                }
              }
            }
          }
        } else {
          this.couponErrorTitle = this.WFSYYHQ;
          this.couponErrorSubTitle = "";
          this.couponErrorSubTitleE = "";
          this.couponErrorSubTitleF = "";
          this.voucherErrorLeftBtn = this.JXXG;
          this.couponInfo = null;
          this.showCouponError = true;
          this.isClearVoucher = true;
          return false;
        }
        if (!mappingId) {
          let pro = { ...result.data };
          pro.name = result.data.mappingName;
          pro.nameEn = result.data.mappingName;
          pro.price = 0;
          pro.uid = result.data.mappingId;
          pro.bigTypeName = "COPY";
          pro.extId = result.data.mappingCode;
          pro.isSoldOut = true;
          pro.type = 1;
          info.mappingPro = pro;
          info.copyPro = pro;
          info.copyPro.isCouponPro = true;
          info.copyPro.isCouponProType = info.voucherType;
          return info;
        }
      } else {
        this.couponErrorTitle = this.WFSYYHQ;
        this.couponErrorSubTitle = "";
        this.couponErrorSubTitleE = "";
        this.couponErrorSubTitleF = "";
        this.voucherErrorLeftBtn = this.JXXG;
        this.couponInfo = null;
        this.showCouponError = true;
        this.isClearVoucher = true;
        return false;
      }
      return info;
    },
    async getDeliveryRange() {
      var coordinate = [];
      if (this.pageParam.type.app) {
        // 从app进来使用app带过来的信息
        if (
          this.pageParam.type.positionInfo &&
          this.pageParam.type.positionInfo.latitude
        ) {
          coordinate.push(this.pageParam.type.positionInfo.longitude);
          coordinate.push(this.pageParam.type.positionInfo.latitude);
        }
      } else {
        // 跳转过来携带参数中 如果有地址信息 使用带过来的
        if (this.pageParam.type.position && this.pageParam.type.position.lat) {
          coordinate.push(this.pageParam.type.position.lng);
          coordinate.push(this.pageParam.type.position.lat);
        } else if (this.$store.state.placeInfo) {
          // vuex中存的
          coordinate = [
            this.$store.state.placeInfo.lng,
            this.$store.state.placeInfo.lat,
          ];
        }
      }
      // 查看是否在配送范围内
      let deliveryRangeParam = {
        actionName: "candao.storeStandard.getStoreDeliveryRange",
        content: {
          storeId: this.storeId,
          coordinate: coordinate,
        },
      };
      // 转换经纬度
      var coordinateRes = wgs_gcj_encrypts(coordinate[1], coordinate[0]);
      deliveryRangeParam.content.coordinate = [
        coordinateRes.lng,
        coordinateRes.lat,
      ];
      var deliveryRange = await postDC("Action", deliveryRangeParam);
      if (deliveryRange.status == 1) {
        if (deliveryRange.data && !deliveryRange.data.length) {
          // this.closeErrorNear();
          this.showErrorNear = !this.showErrorNear;
          this.$toast("不在配送範圍內");
        }
      }
    },
    getStorePresTime(price) {
      if (this.getPresTimer) clearTimeout(this.getPresTimer);
      this.getPresTimer = setTimeout(() => {
        this.getStorePres(price);
      }, 1000);
    },
    // 获取门店优惠
    async getStorePres(price) {
      // let params1 = {
      //   actionName: "candao.preferential.getStorePres",
      //   content: {
      //     storeId: this.storeId,
      //     orderType: this.businessType,
      //   },
      // };
      // let storePres1 = await postDC("Action", params1);
      // if (storePres1.status == 1) {
      //   this.storePres = storePres1.data;
      //   return;
      // }
      if (
        !this.foodSku &&
        !this.foodSku.products &&
        !this.foodSku.products.length
      )
        return;
      let params = {
        actionName: "candao.preferential.canPreList",
        content: {
          storeId: this.storeId,
          orderType: this.businessType,
          menuId: this.productMenu.menuId,
          price: price,
          payType: 1,
          products: this.foodSku.products,
          isFirst: true,
        },
      };
      let storePres = await postDC("Action", params);
      if (storePres.status == 1) {
        this.storePres = storePres.data;
        if (this.storePres.pres) {
          this.storePres.pres.forEach((val) => {
            if (val.childType == 7 && val.type == 4) {
              let prePrice = {};
              let prePriceArr = [];
              val.items.forEach((item) => {
                prePriceArr.push(item.pid);
                prePrice[item.pid] = {
                  price: item.discountedPrice,
                  productDisplayPrice: item.productDisplayPrice,
                };
              });
              this.changeMenuDiscountProduct(prePrice, prePriceArr);
              let editPayload = {
                uids: prePriceArr,
                proPrice: prePrice,
              };
              this.$store.commit("editCartProductDisPrice", editPayload);
              return;
            }
          });
          let C = this.$store.state.couponInfo;
          if (C) {
            this.checkSelectVoucher(C, false);
          }
        }
      }
    },
    // 修改折扣餐品价格
    changeMenuDiscountProduct(prePrice, prePriceArr) {
      if (!this.productMenu.bigs) return;
      let bigs = this.productMenu.bigs;
      for (var j = 0; j < bigs.length; j++) {
        var smalls = bigs[j].smalls;
        for (var f = 0; f < smalls.length; f++) {
          var products = smalls[f].products;
          for (var d = 0; d < products.length; d++) {
            if (prePriceArr.indexOf(products[d].uid) > -1) {
              products[d].price =
                prePrice[products[d].uid].productDisplayPrice +
                prePrice[products[d].uid].price;
            }
          }
        }
      }
    },
    // 获取门店banner
    async getMenuBanner() {
      var params = {
        actionName: "candao.product.getMenuBanner",
        content: {
          menuId: this.productMenu.menuId,
          storeId: this.storeId,
        },
      };
      let banner = await postDC("Action", params);
      if (banner.status == 1 && banner.data) {
        this.menuBanner = banner.data;
      }
    },
    closeMyPlace() {
      this.placeShow = false;
    },
    // 现在所选地址
    async selectPlace(info) {
      this.pagePlace = info;
      this.showPlace(false);
      this.getNearStoreList(info);
    },
    // 关闭没有可预约时间弹窗
    closeIsNotTime() {
      this.isNotHastime = false;
    },
    // 切换配送方式
    changeSendTime(type) {
      if (type == 2) {
        if (this.isNotHasAdvanceTimes) {
          this.isNotHastime = true;
          this.BZCYY = this.businessType == 1 ? this.BZCWMYY : this.BZCZQYY;
          return;
        }
      } else {
        if (!this.canStoreList || !this.canStoreList.length) {
          return;
        }
        this.shopNow = this.canStoreList[0];
        this.storeId = this.shopNow.storeId;
        setHashTag(this.book, this.second);
      }
      this.book = type;
      if (type == 2) {
        this.showPicker = true;
        var cancel = "取消";
        var finish = "確認";
        if (this.$store.state.language != 1) {
          cancel = "Cancel";
          finish = "Confirm";
        }
        document.querySelector(".van-picker__cancel").innerHTML = cancel;
        document.querySelector(".van-picker__confirm").innerHTML = finish;
      } else {
        this.getProductMenu();
        this.second = null;
        this.$store.commit("saveReserveDate", null);
        this.showPicker = false;
      }
    },
    // 选择 时间确认按钮
    finishTime(value) {
      var timeMap = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sept: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
      };
      var splitType = "月";
      if (this.lanType != 1) {
        splitType = " ";
      }
      var arr = value[0].split(splitType);
      var year = new Date().getFullYear();
      var month = arr[0];
      var day = parseInt(arr[1]);
      if (this.lanType != 1) {
        month = timeMap[arr[1]];
        day = parseInt(arr[0]);
      }
      var hour = parseInt(value[1]);
      var minutes = parseFloat(value[2]) || 0;
      // if (month < 10) {
      //   month = "0" + month;
      // }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (this.businessType == 1) {
        let canSelectShop = this.canAppoinmentStore;
        let canShop = false;
        canSelectShop.forEach((val) => {
          let pointTime = val.appointmentTime;
          pointTime.forEach((val2) => {
            let pDate = val2.date.split("-");
            if (month == pDate[1] && day == pDate[2]) {
              year = pDate[0];
              val2.times.forEach((val3) => {
                let T = val3.split("~");
                let startTime = new Date(val2.date + " " + T[0]).getTime();
                let endTime = new Date(val2.date + " " + T[1]).getTime();
                let selectTime = new Date(
                  year +
                    "/" +
                    month +
                    "/" +
                    day +
                    " " +
                    hour +
                    ":" +
                    minutes +
                    ":00"
                );
                if (
                  selectTime >= startTime &&
                  selectTime <= endTime &&
                  !canShop
                ) {
                  this.shopNow = val;
                  this.storeId = val.storeId;
                  canShop = true;
                  return;
                }
              });
              return;
            }
          });
          if (canShop) return;
        });
      }
      var second = year + "/" + month + "/" + day + " " + hour + ":" + minutes;
      this.$store.commit("saveReserveDate", second);
      this.showPicker = false;
      this.second = second;
      setHashTag(this.book, this.second);
      this.getProductMenu();
      this.dataLayerPushTime(second);
      this.shopNow.announArr = null;
    },
    // 关闭时间选择器
    showDatePicker(type) {
      if (!this.bookCanCancel) {
        if (this.$store.state.app) {
          this.$store.commit("clearRouter");
          window.location = "kfchk://exith5";
        } else {
          this.$router.back(-1);
        }
        return;
      }
      this.showPicker = !this.showPicker;
      if (type == 1 && !this.second) {
        this.book = 1;
      }
      if (type == 9) {
        this.book = 1;
      }
    },
    touchstartRect(e) {
      this.start = e.touches[0].screenY;
    },
    //
    getHeight(height) {
      this.height = height;
    },
    touchmoveRect() {},

    // 返回按钮
    backPage(type) {
      this.showConfirm = !this.showConfirm;
      if (type == 1) {
        window.dataLayer.push({
          event: "view_send_order",
        });
      }
    },
    // 选择地址  刚开始写的选择地址
    // 选择地址
    showPlace(type) {
      if (this.businessType == 1) {
        this.$refs.myPlace.getMyPlace();
        this.placeShow = type;
      } else {
        this.$router.push({
          path: "/selectShop",
          query: { type: this.businessType },
        });
      }
      // this.showShopList = true;
    },
    // 关闭选择门店弹窗
    closeShopList(e) {
      if (e.target.id == "shopListBox") {
        this.showShopList = false;
        this.businessType = 1;
      }
    },
    closeErrorNear() {
      window.dataLayer.push({
        event: "view_reselect_store_message",
      });
      // this.$router.back(-1);
      this.showErrorNear = !this.showErrorNear;
      if (this.$store.state.app) {
        this.$store.commit("clearRouter");
        window.location = "kfchk://exith5";
      } else {
        this.$router.back(-1);
      }
    },
    // 获取餐到附近的门店
    async getNearStoreList(info) {
      var coordinate = [];
      if (info) {
        coordinate = [info.lng, info.lat];
      } else {
        if (this.pageParam.type.app) {
          // 如果从app跳过来 使用app中带过来的经纬度
          if (
            this.pageParam.type.positionInfo &&
            this.pageParam.type.positionInfo.latitude
          ) {
            coordinate.push(this.pageParam.type.positionInfo.longitude);
            coordinate.push(this.pageParam.type.positionInfo.latitude);
          }
        } else {
          if (
            this.pageParam.type.position &&
            this.pageParam.type.position.lat
          ) {
            // 如果携带参数中有位置信息 使用携带参数中的经纬度
            coordinate.push(this.pageParam.type.position.lng);
            coordinate.push(this.pageParam.type.position.lat);
          } else if (this.$store.state.placeInfo) {
            // vuex中存的位置信息
            coordinate = [
              this.$store.state.placeInfo.lng,
              this.$store.state.placeInfo.lat,
            ];
          }
        }
      }
      // 经纬度转换
      var coordinateRes = wgs_gcj_encrypts(coordinate[1], coordinate[0]);
      coordinate = [coordinateRes.lng, coordinateRes.lat];
      var params = {
        actionName: "candao.storeStandard.getNearStoreList",
        content: {
          coordinate: coordinate,
          businessType: [this.businessType],
          pageNow: 1,
          pageSize: 20,
        },
      };
      let result = await postDC("Action", params);

      if (result.status == 1) {
        if (result.data.rows.length) {
          this.shopList = result.data.rows;
          if (this.businessType == 1) {
            this.canAppoinmentStore = [];
            // 循环获取可使用的门店
            for (let i = 0; i < this.shopList.length; i++) {
              // 门店业务类型需满足预约
              if (this.shopList[i].businessType.indexOf(4) > -1) {
                // 除了堂食 其他任意类型为繁忙 则本店繁忙
                // 默认不繁忙
                let isbusy = false;
                for (
                  var b = 0;
                  b < this.shopList[i].businessStatus.length;
                  b++
                ) {
                  if (this.shopList[i].businessStatus[b].businessId != 3) {
                    if (this.shopList[i].businessStatus[b].busy) {
                      isbusy = true;
                      continue;
                    }
                  }
                }
                if (!isbusy) {
                  this.canAppoinmentStore.push(this.shopList[i]);
                }
              }
            }
            this.setAdvanceTimes(this.canAppoinmentStore, this.businessType);
            // 组装门店公告滚动信息
            var obj = {
              announcement: result.data.rows[0].announcement,
              announcementEn: result.data.rows[0].announcementEn,
            };
            if (
              result.data.rows[0].announcement ||
              result.data.rows[0].announcementEn
            ) {
              result.data.rows[0].announArr = [obj];
            } else {
              result.data.rows[0].announArr = null;
            }
            if (this.businessType == 1) {
              var year = new Date().getFullYear();
              var month = new Date().getMonth() + 1;
              var day = new Date().getDate();
              // 检查门店是否繁忙 繁忙门店不可用
              let noBusyStorelist = this.shopList.filter((val) => {
                let CStore = true;
                for (var b = 0; b < val.businessStatus.length; b++) {
                  if (val.businessStatus[b].businessId != 3) {
                    if (val.businessStatus[b].busy) {
                      CStore = false;
                      return;
                    }
                  }
                }
                return CStore;
              });
              this.noBusyStorelist = noBusyStorelist;
              if (!this.noBusyStorelist || !this.noBusyStorelist.length) {
                this.isbusyBack = true;
                this.showIsBusy = true;
                return;
              }
              // 检查门店是否在配送时间范围内
              let canStoreList = []; //在营业时间内的门店
              for (var i = 0; i < noBusyStorelist.length; i++) {
                var deliveryTimes = noBusyStorelist[i].deliveryTimes; //外卖可配送时间范围
                for (var t = 0; t < deliveryTimes.length; t++) {
                  var B =
                    year +
                    "/" +
                    month +
                    "/" +
                    day +
                    " " +
                    deliveryTimes[t].beginTime;
                  var E =
                    year +
                    "/" +
                    month +
                    "/" +
                    day +
                    " " +
                    deliveryTimes[t].endTime;
                  B = new Date(B).getTime();
                  E = new Date(E).getTime();
                  let T = new Date().getTime();
                  if (T >= B && T <= E) {
                    canStoreList.push(noBusyStorelist[i]);
                  }
                }
              }
              this.canStoreList = canStoreList;
              if (this.book != 2) {
                if (this.canStoreList && this.canStoreList.length > 0) {
                  // 当有不繁忙门店 且门店在配送范围内 是使用第一个的门店
                  this.shopNow = this.canStoreList[0];
                } else {
                  // 如果没有在配送时间范围内的门店 则可以预约
                  this.showIsBusy = true;
                  this.isbusyBack = false;
                  return;
                }
              } else {
                this.setShopNow();
              }
              if (this.shopNow.storeId) {
                this.storeId = this.shopNow.storeId;
              }
            }
          }
          // 获取餐单
          this.getProductMenu();
          // this.getStorePres();
          if (this.pagePlace) {
            this.$store.commit("savePlace", this.pagePlace);
            this.pagePlace = null;
          }
        } else {
          this.showErrorNear = !this.showErrorNear;
          window.dataLayer.push({
            event: "confirm_reselect_store_message",
          });
          this.rightBtnCan = false;
          this.$store.commit("clearCart");
        }
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    setShopNow() {
      let selectDate = this.second.split(" ");
      let dateInfo = selectDate[0].split("/");

      let canShop = false;
      this.canAppoinmentStore.forEach((val) => {
        let pointTime = val.appointmentTime;
        pointTime.forEach((val2) => {
          let pDate = val2.date.split("-");
          if (dateInfo[1] == pDate[1] && dateInfo[2] == pDate[2]) {
            val2.times.forEach((val3) => {
              let T = val3.split("~");
              let startTime = new Date(val2.date + " " + T[0]).getTime();
              let endTime = new Date(val2.date + " " + T[1]).getTime();
              let selectTime = new Date(this.second).getTime();
              if (
                selectTime >= startTime &&
                selectTime <= endTime &&
                !canShop
              ) {
                this.shopNow = val;
                this.storeId = val.storeId;
                canShop = true;
                return;
              }
            });
            return;
          }
        });
        if (canShop) return;
      });
    },
    clearCartPro() {
      var prevStore = localStorage.getItem("KFCStoreCart");
      if (prevStore != this.businessType + "_" + this.storeId) {
        // 当当前门店的业务类型 和 门店 与购物车中所属业务类型和门店不是同一个时 删除购物车餐品
        if (this.$store.state.cart && this.$store.state.cart.length != 0) {
          this.$refs.foodList.clearNum();
          this.$store.commit("clearCart");
          this.errorFoodTit = this.GWC_remove;
          this.showErrorFood = true;
        }
      }
      // 存储的购物车中餐品 属于哪个 业务类型 和 门店
      localStorage.setItem(
        "KFCStoreCart",
        this.businessType + "_" + this.storeId
      );
    },
    //切换自取的时候选择门店
    selectShop(info) {
      this.shopNow = info;
      this.storeId = info.storeId;
      this.showShopList = false;
    },
    // 重新获取门店信息判断当前预约时间是否还在预约时间内 外卖
    async getNewTakeselfTime() {
      var coordinate = [];
      if (this.pageParam.type.app) {
        // 如果从app跳过来 使用app中带过来的经纬度
        if (
          this.pageParam.type.positionInfo &&
          this.pageParam.type.positionInfo.latitude
        ) {
          coordinate.push(this.pageParam.type.positionInfo.longitude);
          coordinate.push(this.pageParam.type.positionInfo.latitude);
        }
      } else {
        if (this.pageParam.type.position && this.pageParam.type.position.lat) {
          // 如果携带参数中有位置信息 使用携带参数中的经纬度
          coordinate.push(this.pageParam.type.position.lng);
          coordinate.push(this.pageParam.type.position.lat);
        } else if (this.$store.state.placeInfo) {
          // vuex中存的位置信息
          coordinate = [
            this.$store.state.placeInfo.lng,
            this.$store.state.placeInfo.lat,
          ];
        }
      }
      // 经纬度转换
      var coordinateRes = wgs_gcj_encrypts(coordinate[1], coordinate[0]);
      coordinate = [coordinateRes.lng, coordinateRes.lat];
      var params = {
        actionName: "candao.storeStandard.getNearStoreList",
        content: {
          coordinate: coordinate,
          businessType: [this.businessType],
          pageNow: 1,
          pageSize: 20,
        },
      };
      let result = await postDC("Action", params);
      if (result.status != 1) return false;
      let can = false;
      result.data.rows.forEach((val) => {
        if (val.storeId == this.storeId) {
          let appointment = val.appointmentTime;
          let bookTime = new Date(this.second).getTime();
          for (let i = 0; i < appointment.length; i++) {
            let date = appointment[i].date;
            let times = appointment[i].times;
            for (let t = 0; t < times.length; t++) {
              let time = times[t].split("~");
              let start = new Date(date + " " + time[0]).getTime();
              let end = new Date(date + " " + time[1]).getTime();
              if (bookTime >= start && bookTime <= end) {
                can = true;
                break;
              }
            }
            if (can) break;
          }
        }
      });
      return can;
    },
    // 重新获取门店信息判断当前预约时间是否还在预约时间内
    async getNewAppointime() {
      let params = {
        actionName: "candao.storeStandard.getStore",
        langType: this.$store.state.language,
        content: {
          storeId: this.storeId,
          businessType: this.businessType,
        },
      };
      let storeDetail = await postDC("Action", params);
      if (storeDetail.status != 1) return false;
      let appointment = storeDetail.data.appointmentTime;
      if (this.businessType != 1) {
        appointment = storeDetail.data.takeSelfTime;
      }
      let bookTime = new Date(this.second).getTime();
      let can = false;
      for (let i = 0; i < appointment.length; i++) {
        let date = appointment[i].date;
        let times = appointment[i].times;
        for (let t = 0; t < times.length; t++) {
          let time = times[t].split("~");
          let start = new Date(date + " " + time[0]).getTime();
          let end = new Date(date + " " + time[1]).getTime();
          if (bookTime >= start && bookTime <= end) {
            can = true;
            break;
          }
        }
        if (can) break;
      }
      return can;
    },
    // 获取门店详情
    async getStoreDetail() {
      var params = {
        actionName: "candao.storeStandard.getStore",
        langType: this.$store.state.language,
        content: {
          storeId: this.storeId,
          businessType: this.businessType,
        },
      };
      let result = await postDC("Action", params);
      if (result.status == 1) {
        let isbusy = false;
        if (this.businessType == 3) {
          // 判断门店是否繁忙 当时堂食时 只看堂食类型是否繁忙
          result.data.businessStatus.forEach((val) => {
            if (val.businessId == this.businessType) {
              isbusy = val.busy;
              return;
            }
          });
        } else {
          // 判断门店是否繁忙 当不是堂食时 其他类型 只要有一个繁忙就算繁忙状态
          result.data.businessStatus.forEach((V) => {
            if (V.businessId != 3) {
              if (V.busy) {
                isbusy = V.busy;
                return;
              }
            }
          });
        }
        // 如果繁忙 显示弹窗
        if (isbusy) {
          this.isbusyBack = true;
          this.showIsBusy = true;
          return;
        }
        // 组装门店公告信息
        var obj = {
          announcement: result.data.announcement,
          announcementEn: result.data.announcementEn,
        };
        if (result.data.announcement || result.data.announcementEn) {
          result.data.announArr = [obj];
        } else {
          result.data.announArr = null;
        }
        if (this.businessType != 1) {
          // 当当前门店的业务类型 和 门店 与购物车中所属业务类型和门店不是同一个时 删除购物车餐品
          this.shopNow = result.data;
        } else {
          if (this.book == 2) {
            var canNext = false;
            var year = new Date(this.second).getFullYear();
            var month = new Date(this.second).getMonth() + 1;
            var day = new Date(this.second).getDate();
            var deliveryTimes = this.shopNow.deliveryTimes;
            for (var t = 0; t < deliveryTimes.length; t++) {
              var B =
                year +
                "/" +
                month +
                "/" +
                day +
                " " +
                deliveryTimes[t].beginTime;
              var E =
                year + "/" + month + "/" + day + " " + deliveryTimes[t].endTime;
              B = new Date(B).getTime();
              E = new Date(E).getTime();
              var T = new Date(this.second).getTime();
              if (T >= B && T <= E) {
                canNext = true;
                break;
              }
            }
            if (!canNext) {
              // this.$toast(this.BZPSFWN);
              this.$toast(this.MDFMALERTTITLE + this.MDFMALERT);
              this.second = 0;
              this.book = 1;
              return canNext;
            }
          }
        }

        if (
          this.pageParam.type &&
          this.pageParam.type.page == 1 &&
          this.pageParam.type.storeId
        ) {
          this.shopNow = result.data;
        }
        // 获取优惠券
        this.getCoupon();
        this.getStoreDelivery();
        if (this.businessType == 2) {
          if (result.data.businessType.indexOf(4) > -1) {
            this.canAppoinmentStore = [result.data];
            this.canStoreList = [result.data];
            this.setAdvanceTimes([result.data], this.businessType);
          }
        }
        return true;
      } else {
        this.$toast(this.HQXXSB);
        return false;
      }
    },
    setAdvanceTimes(data) {
      if (!data) return;
      let columnsData = setAppointment(data, this.businessType);
      if (!columnsData.hoursArr || !columnsData.hoursArr.length) {
        this.isNotHasAdvanceTimes = true;
      }
      let dateIndex = this.$refs.timePicker.getIndexes();
      let dateValue = this.$refs.timePicker.getValues();
      if (dateIndex && dateIndex.length) {
        columnsData.columns[1].values = columnsData.hoursArr[dateIndex[0]];
        let minutesKey = dateValue[0] + dateValue[1];
        if (columnsData.minutesData[minutesKey]) {
          columnsData.columns[2].values = columnsData.minutesData[minutesKey];
        }
      }
      this.columns = columnsData.columns;
      this.minutesData = columnsData.minutesData;
      this.hours = columnsData.hoursArr;
      this.minutes = columnsData.minutes;
    },
    // 关闭繁忙配送弹窗
    closeIsBusy() {
      this.showIsBusy = false;
      if (this.isbusyBack) {
        if (this.$store.state.app) {
          this.$store.commit("clearRouter");
          window.location = "kfchk://exith5";
        } else {
          this.$router.back(-1);
        }
        this.isbusyBack = false;
        return;
      }
      this.isbusyBack = false;
      this.changeSendTime(2);
    },

    // 获取当天门店配送时间范围
    setIsNoStoreMsgTime() {
      var dateListC = [];
      for (var i = 0; i < this.shopList.length; i++) {
        dateListC.push(...this.shopList[i].appointmentTime);
      }
      var dateList = [];
      var dateObj = [];
      var Y = new Date().getFullYear();
      var M = new Date().getMonth() + 1;
      var day = new Date().getDate();
      if (M < 10) {
        M = "0" + M;
      }
      if (day < 10) {
        day = "0" + day;
      }
      var DNow = Y + "-" + M + "-" + day;
      var nowTime = new Date(DNow).getTime();
      for (var d = 0; d < dateListC.length; d++) {
        var D = dateListC[d].date;
        var Dtime = new Date(D).getTime();
        if (nowTime == Dtime) {
          for (var t = 0; t < dateListC[d].times.length; t++) {
            var T = dateListC[d].times[t].split("~");
            T.forEach((val) => {
              var DT = D + " " + val;
              dateList.push(new Date(DT).getTime());
              dateObj[new Date(DT).getTime()] = DT;
            });
          }
        }
      }
      dateList.sort((a, b) => {
        return a - b;
      });
      var startTime = dateObj[dateList[0]].split(" ")[1];
      var endTime = dateObj[dateList[dateList.length - 1]].split(" ")[1];
      return { S: startTime, E: endTime };
    },
    // 時間改變
    changeTime() {
      var index = this.$refs.timePicker.getIndexes();
      this.columns[1].values = this.hours[index[0]];
      this.$nextTick(() => {
        var values = this.$refs.timePicker.getValues();
        var timeKey = values[0] + values[1];
        if (this.minutesData[timeKey]) {
          this.columns[2].values = this.minutesData[timeKey];
        } else {
          this.columns[2].values = [];
        }
      });
    },
    // 获取门店配送时间
    async getStoreDelivery() {
      let param = {
        actionName: "candao.member.storeAddressSearch",
        content: {
          shopCode: this.shopNow.extraStoreId,
          // lat: this.shopNow.coordinate[1],
          // lng: this.shopNow.coordinate[0],
        },
      };
      let result = await postPaas("UserUnify", param);
      if (
        result.status == 1 &&
        result.data &&
        result.data.data &&
        result.data.data.length
      ) {
        if (this.businessType == 1) {
          this.quoteTime = result.data.data[0].deliveryQuoteTime || null;
        } else {
          this.quoteTime = result.data.data[0].pickupQuoteTime || null;
        }
        if (this.lanType == 1) {
          this.quoteTime ? (this.quoteTime += "分鐘") : null;
        } else {
          this.quoteTime ? (this.quoteTime += " minutes") : null;
        }
      }
    },
    // 获取用户详情
    async getPersionInfo() {
      // this.pageParam.type.userId = "101597239";
      // this.pageParam.type.phone = "93187876";
      // this.pageParam.type.accecode =
      //   "/m+ek2ZhbLq1WMn7fe+o8r7yffwj7zrtfR4l/l95NCLbsyr7RHFrFxf46vDQDzb5ri64NxOwMw2S2ygSY4vtPzzsF+iFsqWmj7yVC2zgqKdvrfchMZtjuAZKcqIs3AKIv95GbRA+vW8sUoxqTH00fQ==";
      let paramsOne = {
        actionName: "candao.user.getUserByExtUserId",
        content: {
          extUserId: this.pageParam.type.userId,
          phone: this.pageParam.type.phone,
        },
      };
      // 获取secretKey
      let canDaoUser = await postDC("Action", paramsOne);
      if (canDaoUser.status != 1) {
        this.$toast(this.HQXXSB);
        return;
      }
      // 绑定AccessCode
      var paramsTwo = {
        actionName: "candao.member.bindAccessCode",
        content: {
          accessCode: this.pageParam.type.accecode,
        },
      };
      let bindCode = await postPaas("UserUnify", paramsTwo);
      if (bindCode.status != 1) {
        this.$toast(this.HQXXSB);
        return;
      }
      // 获取用户信息
      var params = {
        actionName: "candao.member.userProfileGet",
        content: {},
      };
      // if (this.$store.state.userInfo) {
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        result.data.accessCode = this.pageParam.type.accecode;
        this.$store.commit("setUserInfo", result.data);
        if (this.pageParam.type.banner) {
          this.appBannerJoin();
        } else if (this.pageParam.type.reference) {
          this.getProductMenu();
        } else {
          if (this.businessType == 1) {
            this.getNearStoreList();
          } else {
            this.getProductMenu();
          }
        }
      } else {
        this.$toast(this.HQXXSB);
      }
      // }
    },
    // 获取优惠券
    // 获取优惠券
    async getCoupon() {
      var params = {
        actionName: "candao.member.voucherGuestGet",
        content: {
          // shopCode: this.shopNow.extraStoreId,
          voucherStatus: 1,
        },
      };
      if (this.$store.state.userInfo) {
        // 如果登录使用此接口
        params.actionName = "candao.member.voucherGet";
        params.content.userId = this.$store.state.userInfo.userId;
      }
      let couponRes = await postPaas("UserUnify", params);
      if (couponRes.status == 1) {
        var couponListInfo = couponRes.data.data;
        var orderTypeCode;
        // 不用业务类型 对应标签code
        if (this.businessType == 1) {
          //外卖
          orderTypeCode = "A02";
        } else if (this.businessType == 2) {
          //自取
          orderTypeCode = "A01";
        } else if (this.businessType == 3) {
          //堂食
          orderTypeCode = "A05";
        }
        var couArr = [];
        for (var i = 0; i < couponListInfo.length; i++) {
          var nowTime = new Date().getTime();
          if (this.book == 2) {
            nowTime = new Date(this.second).getTime();
          }
          var startTime = 0;
          if (couponListInfo[i].validBeginDate) {
            var beginTimeArr = couponListInfo[i].validBeginDate.split("T"); //优惠券 有效期开始时间
            var beginDate = beginTimeArr[0].split("-");
            var beginTime = beginTimeArr[1].split(".")[0];
            var begin =
              beginDate[0] +
              "/" +
              beginDate[1] +
              "/" +
              beginDate[2] +
              " " +
              beginTime;
            startTime = new Date(begin).getTime();
          }
          var endTime = 0;
          if (couponListInfo[i].validEndDate) {
            //优惠券有效期结束时间
            var endTimeArr = couponListInfo[i].validEndDate.split("T");
            var endDate = endTimeArr[0].split("-");
            var endTimeTe = endTimeArr[1].split(".")[0];
            var end =
              endDate[0] +
              "/" +
              endDate[1] +
              "/" +
              endDate[2] +
              " " +
              endTimeTe;
            endTime = new Date(end).getTime();
          } else {
            //如果此值为空 表示无限期有效
            endTime = nowTime + 100000;
          }
          if (nowTime > startTime && nowTime < endTime) {
            // 过滤优惠券
            // 优惠券可用的业务类型
            if (couponListInfo[i].voucherCategoryCode) {
              if (
                couponListInfo[i].voucherCategoryCode.indexOf(orderTypeCode) >
                  -1 ||
                couponListInfo[i].voucherCategoryCode.length == 0
              ) {
                if (
                  couponListInfo[i].voucherType == 1 ||
                  couponListInfo[i].voucherType == 4 ||
                  couponListInfo[i].voucherType == 2 ||
                  couponListInfo[i].voucherType == 3 ||
                  couponListInfo[i].voucherType == 5
                ) {
                  couArr.push(couponListInfo[i]);
                }
              }
            } else {
              //如果此值为空 表示通用
              couArr.push(couponListInfo[i]);
            }
          }
        }
        var couponArr = [];
        var timeTag = this.$store.state.time; //当前时间段 早餐 午餐 下午茶 晚餐
        for (var j = 0; j < couArr.length; j++) {
          // 判断时餐道发行的优惠券还是 stollect发行的优惠券
          let isCandao = true;
          if (couArr[j].voucherCalType == null) {
            isCandao = false;
          }
          // 不同券商发行的优惠券使用不同的字段判断当前门店是否可用
          let availableShops = isCandao
            ? couArr[j].canDaoAvailableShops
            : couArr[j].availableShops;
          let storeIdOrCode = isCandao
            ? this.shopNow.storeId
            : this.shopNow.extraStoreId;
          if (
            availableShops &&
            availableShops.length > 0 &&
            availableShops.indexOf(storeIdOrCode) < 0
          ) {
            //如果当前门店不可用则跳过
            continue;
          }
          if (
            couArr[j].voucherHashtagCode &&
            couArr[j].voucherHashtagCode.length
          ) {
            // 当前优惠券是否在可用时间范围内
            if (couArr[j].voucherHashtagCode.indexOf(timeTag) > -1) {
              if (couArr[j].voucherType == 3 || couArr[j].voucherType == 5) {
                // 不同优惠券对应不同的字段 这个字段必须有值当前优惠券才可用
                if (
                  couArr[j].voucherProductCode &&
                  couArr[j].voucherProductCode.length
                ) {
                  couponArr.push(couArr[j]);
                }
              } else if (
                couArr[j].voucherType == 1 ||
                couArr[j].voucherType == 4
              ) {
                if (couArr[j].voucherAmount) {
                  couponArr.push(couArr[j]);
                }
              } else if (
                couArr[j].voucherType == 2 &&
                couArr[j].voucherDiscount
              ) {
                couponArr.push(couArr[j]);
              }
            }
          } else {
            // 此值为空表示不分时间段通用
            // 不同优惠券对应不同的字段 这个字段必须有值当前优惠券才可用
            if (couArr[j].voucherType == 3 || couArr[j].voucherType == 5) {
              if (
                couArr[j].voucherProductCode &&
                couArr[j].voucherProductCode.length
              ) {
                couponArr.push(couArr[j]);
              }
            } else if (
              couArr[j].voucherType == 1 ||
              couArr[j].voucherType == 4
            ) {
              if (couArr[j].voucherAmount) {
                couponArr.push(couArr[j]);
              }
            } else if (
              couArr[j].voucherType == 2 &&
              couArr[j].voucherDiscount
            ) {
              couponArr.push(couArr[j]);
            }
          }
        }
        // let hasVoucher = false;
        // 拆分拼接优惠可用日期
        for (var z = 0; z < couponArr.length; z++) {
          // if (this.$store.state.couponInfo) {
          //   // 如果有优惠券判断优惠券是否可用
          //   if (
          //     this.$store.state.couponInfo.voucherCode ==
          //     couponArr[z].voucherCode
          //   ) {
          //     hasVoucher = true;
          //   }
          // }
          var beginDateC = couponArr[z].validBeginDate //开始时间
            ? couponArr[z].validBeginDate.split("T")[0].split("-").reverse()
            : null;
          var endDateC = couponArr[z].validEndDate //过期时间
            ? couponArr[z].validEndDate.split("T")[0].split("-").reverse()
            : null;
          if (beginDateC) {
            couponArr[z].date =
              beginDateC.join("-") + "至" + endDateC.join("-");
          } else if (endDateC) {
            couponArr[z].date = endDateC.join("-");
          } else {
            couponArr[z].date = "-";
          }
          couponArr[z].couTag = [];
          // 不同的优惠券对应的业务类型 对应自己的标签
          if (
            couponArr[z].voucherCategoryCode &&
            (couponArr[z].voucherCategoryCode.indexOf("A01") > -1 ||
              couponArr[z].voucherCategoryCode.indexOf("A02") > -1)
          ) {
            couponArr[z].sendFunc = 1; //外送
            if (couponArr[z].voucherCategoryCode.indexOf("A01") > -1) {
              couponArr[z].couTag.push(1);
            }
            if (couponArr[z].voucherCategoryCode.indexOf("A02") > -1) {
              couponArr[z].couTag.push(2);
            }
          } else if (
            couponArr[z].voucherCategoryCode &&
            (couponArr[z].voucherCategoryCode.indexOf("A03") > -1 ||
              couponArr[z].voucherCategoryCode.indexOf("A05") > -1)
          ) {
            couponArr[z].sendFunc = 2; //堂食
            if (couponArr[z].voucherCategoryCode.indexOf("A03") > -1) {
              couponArr[z].couTag.push(3);
            }
            if (couponArr[z].voucherCategoryCode.indexOf("A05") > -1) {
              couponArr[z].couTag.push(5);
            }
          } else {
            couponArr[z].sendFunc = 0; //通用
          }
        }
        // if (this.$store.state.userInfo) {
        //   if (this.$store.state.couponInfo) {
        //     if (!hasVoucher) {
        //       this.couponErrorTitle = this.CDYBSYMD;
        //       this.couponErrorSubTitle = this.BZCDHYSQSUB;
        //       this.couponErrorSubTitleE = "";
        //       this.couponErrorSubTitleF = "";
        //       this.voucherErrorLeftBtn = this.JXXG;
        //       this.couponInfo = null;
        //       this.showCouponError = true;
        //       this.isClearVoucher = false;
        //       this.deleteCouponInfo();
        //     }
        //   }
        // }
        this.couponList = couponArr;
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    checkCoupon(canShow) {
      // 如果没有门店信息 返回
      if (!this.shopNow || !this.shopNow.extraStoreId) return;
      if (
        this.couponInfo &&
        (this.couponInfo.voucherType == 2 ||
          this.couponInfo.voucherType == 1 ||
          this.couponInfo.voucherType == 4)
      ) {
        //优惠券为 voucherType 为 1 2 4 检查优惠券
        this.checkSelectCoupon(this.couponInfo, canShow);
        return;
      }
      if (this.clear) return;
      if (
        this.$route.params.type &&
        this.$route.params.type != 1 &&
        this.$route.params.type.coupon
      ) {
        // 是否携带优惠券参数 携带需要检查
        if (
          this.$route.params.type.coupon.voucherType == 1 ||
          this.$route.params.type.coupon.voucherType == 4 ||
          this.$route.params.type.coupon.voucherType == 2
        ) {
          this.checkSelectCoupon(this.$route.params.type.coupon, canShow);
        }
      }
    },
    // 传过来的优惠券检查是否可用
    // 选择优惠券
    async checkSelectCoupon(info, canShow = true) {
      this.checkSelectCouponTimer
        ? clearTimeout(this.checkSelectCouponTimer)
        : false;
      this.checkSelectCouponTimer = setTimeout(() => {
        this.checkSelectCouponTime(info, canShow);
      }, 1000);
    },
    async checkSelectVoucher(info, canShow = true) {
      // 检查优惠券 如果没有餐单返回
      if (!this.productMenu.menuId) return;
      info.isCanUserVoucher = false;
      // 判断是 餐道发行的优惠券  stollect发行的优惠券
      let isCandao = true;
      if (info.voucherCalType == null) {
        isCandao = false;
      }
      info.isCandao = isCandao;
      if (this.couponList) {
        let hasVoucher = false;
        // 如果有优惠券判断优惠券是否可用
        for (let i = 0; i < this.couponList.length; i++) {
          if (info.voucherCode == this.couponList[i].voucherCode) {
            hasVoucher = true;
            break;
          }
        }
        if (!hasVoucher) {
          this.couponErrorTitle = this.CDYBSYMD;
          this.couponErrorSubTitle = this.BZCDHYSQSUB;
          this.couponErrorSubTitleE = "";
          this.couponErrorSubTitleF = "";
          this.voucherErrorLeftBtn = this.JXXG;
          this.couponInfo = null;
          canShow ? (this.showCouponError = true) : false;
          this.isClearVoucher = true;
          return;
        }
      }
      // 当优惠券的类型为 3 5 voucherCalType为空 或者voucherCalType==1 没有金额门槛  时判断购物车是否为空
      if (info.voucherType != 3 && info.voucherType != 5) {
        if (
          !info.voucherCalType ||
          (info.voucherCalType == 1 && !info.minUsage)
        ) {
          let cart = this.$store.state.cart;
          // 购物车为空
          if (!cart.length) {
            this.couponErrorTitle = this.GWCWKYHCW;
            this.couponErrorSubTitle = "";
            this.couponErrorSubTitleE = "";
            this.couponErrorSubTitleF = "";
            this.voucherErrorLeftBtn = this.JXXG;
            this.couponInfo = null;
            canShow ? (this.showCouponError = true) : false;
            this.isClearVoucher = false;
            this.$store.commit("saveCouponInfo", info);
            return false;
          }
        }
      }
      var type = this.$store.state.time; //当前时间段
      // 判断优惠券在当前时间段是否可用
      if (
        info.voucherHashtagCode &&
        info.voucherHashtagCode.indexOf(type) < 0
      ) {
        this.couponErrorTitle = this.WDSYMK;
        this.couponErrorSubTitle = this.BZDQSJDSUB;
        this.couponErrorSubTitleE = this.BZDQSJDSUBF;
        this.couponErrorSubTitleF = "";
        this.voucherErrorLeftBtn = this.JXXG;
        this.couponInfo = null;
        canShow ? (this.showCouponError = true) : false;
        this.isClearVoucher = false;
        this.$store.commit("saveCouponInfo", info);
        return false;
      }
      let brandId = 41;
      let H5Seting = this.$store.state.H5Seting;
      if (H5Seting) {
        let brandInfos = H5Seting.brandInfos;
        if (brandInfos && brandInfos.length) {
          brandId = brandInfos[0].brandId;
        }
      }
      if (!isCandao) {
        //检查stollect发行的优惠券
        // 检查优惠券金额门槛
        var total = this.totalPricePre || this.$store.state.totalPrice;
        if (info.minUsage) {
          if (Number(info.minUsage) / 100 > total) {
            this.couponErrorTitle = this.WDSYMK;
            this.couponErrorSubTitle = this.WDSYMKSUBJE;
            this.couponErrorSubTitleE = this.WDSYMKSUB;
            this.couponErrorSubTitleF = "";
            this.voucherErrorLeftBtn = this.JXXG;
            this.couponInfo = null;
            canShow ? (this.showCouponError = true) : false;
            this.isClearVoucher = false;
            return false;
          }
        }
        // 检查优惠券餐品餐品门槛
        // minUsageProductCode 有值则有门槛需要判断是否满足餐品门槛
        if (info.minUsageProductCode && info.minUsageProductCode.length) {
          var paramsOne = {
            actionName: "candao.product.getProductMapping",
            content: {
              posId: 396,
              brandId,
            },
          };
          let arr = [];

          for (let j = 0; j < info.minUsageProductCode.length; j++) {
            paramsOne.content.mappingCode = info.minUsageProductCode[j];
            var minUsageRes = await postDC("Action", paramsOne);
            if (minUsageRes.status == 1 && minUsageRes.data) {
              arr.push(minUsageRes.data.mappingId);
            }
          }
          // 接口失败
          // 餐品门槛会有多个餐品 如果其中一个获取失败则未达到门槛
          if (arr.length != info.minUsageProductCode.length) {
            this.couponErrorTitle = this.WDSYMK;
            this.couponErrorSubTitle = this.WDSYMKSUBCP;
            this.couponErrorSubTitleE = this.WDSYMKSUB;
            this.couponErrorSubTitleF = "";
            this.voucherErrorLeftBtn = this.JXXG;
            this.couponInfo = null;
            canShow ? (this.showCouponError = true) : false;
            this.isClearVoucher = false;
            this.$store.commit("saveCouponInfo", info);
            return false;
          }
          var canS = false;
          let cart = this.$store.state.cart;
          // 检查购物车中是否包含门槛餐品 没有则 未达到餐品门槛
          for (var i = 0; i < cart.length; i++) {
            if (cart[i].isCouponPro) continue;
            if (arr.indexOf(cart[i].uid) > -1) {
              canS = true;
              break;
            }
          }
          // 购物车中没有餐品门槛中的的餐品
          if (!canS) {
            this.couponErrorTitle = this.WDSYMK;
            this.couponErrorSubTitle = this.WDSYMKSUBCP;
            this.couponErrorSubTitleE = this.WDSYMKSUB;
            this.couponErrorSubTitleF = "";
            this.voucherErrorLeftBtn = this.JXXG;
            this.couponInfo = null;
            canShow ? (this.showCouponError = true) : false;
            this.isClearVoucher = false;
            this.$store.commit("saveCouponInfo", info);
            return false;
          }
        }
      } else {
        // 检查餐道优惠券门槛
        let checkRes = await checkCouonType(
          info,
          this.businessType,
          this.storePres
        );
        // 检查结果为false则不通过 未达到门槛
        if (!checkRes.usage) {
          if (checkRes.type == 1) {
            this.couponErrorTitle = this.WDSYMK;
            this.couponErrorSubTitle = this.WDSYMKSUBJE;
            this.couponErrorSubTitleE = this.WDSYMKSUB;
            this.couponErrorSubTitleF = "";
          } else {
            this.couponErrorTitle = this.WDSYMK;
            this.couponErrorSubTitle = this.WDSYMKSUBCP;
            this.couponErrorSubTitleE = this.WDSYMKSUB;
            this.couponErrorSubTitleF = "";
          }
          this.voucherErrorLeftBtn = this.JXXG;

          this.couponInfo = null;
          canShow ? (this.showCouponError = true) : false;
          this.isClearVoucher = false;
          this.$store.commit("saveCouponInfo", info);
          return false;
        }
      }
      // 一口价或者赠品券 没有配置对应餐品报错
      if (info.voucherType == 3 || info.voucherType == 5) {
        if (!info.voucherProductCode) {
          this.couponErrorTitle = this.WFSYYHQ;
          this.couponErrorSubTitle = "";
          this.couponErrorSubTitleE = "";
          this.couponErrorSubTitleF = "";
          this.voucherErrorLeftBtn = this.JXXG;
          this.couponInfo = null;
          canShow ? (this.showCouponError = true) : false;
          this.isClearVoucher = true;
          this.$store.commit("saveCouponInfo", info);
          return false;
        }
      }
      // 当指定门店的时候判断当前门店是否在指定门店中 不同券商发行的券使用不同的字段来进行判断
      let availableShops = isCandao
        ? info.canDaoAvailableShops
        : info.availableShops;
      let storeIdOrCode = isCandao
        ? this.shopNow.storeId
        : this.shopNow.extraStoreId;
      if (
        availableShops &&
        availableShops.length > 0 &&
        availableShops.indexOf(storeIdOrCode) < 0
      ) {
        this.couponErrorTitle = this.BZCDHYSQ;
        this.couponErrorSubTitle = this.BZCDHYSQSUB;
        this.couponErrorSubTitleE = "";
        this.couponErrorSubTitleF = "";
        this.voucherErrorLeftBtn = this.JXXG;
        this.couponInfo = null;
        canShow ? (this.showCouponError = true) : false;
        this.isClearVoucher = true;
        this.$store.commit("saveCouponInfo", info);
        return false;
      }
      if (info.voucherType == 3 || info.voucherType == 5) {
        let isHasPro = false;
        for (let i = 0; i < this.productMenu.bigs.length; i++) {
          let smalls = this.productMenu.bigs[i].smalls;
          if (
            this.productMenu.bigs[i].name != "COPY" &&
            this.productMenu.bigs[i].nameEn != "COPY"
          ) {
            continue;
          }
          for (let s = 0; s < smalls.length; s++) {
            let products = smalls[s].products;
            for (let p = 0; p < products.length; p++) {
              if (products[p].uid == info.copyPro.uid) {
                isHasPro = true;
                break;
              }
            }
          }
        }
        if (
          !isHasPro ||
          info.copyPro.isSoldOut ||
          !info.copyPro.isInServiceTime
        ) {
          this.couponErrorTitle = this.BZCDHYSQ;
          this.couponErrorSubTitle = this.BZCDHYSQSUB;
          this.couponErrorSubTitleE = "";
          this.couponErrorSubTitleF = "";
          this.voucherErrorLeftBtn = this.JXXG;
          this.couponInfo = null;
          canShow ? (this.showCouponError = true) : false;
          this.isClearVoucher = true;
          return;
        }
      }
      info.isCanUserVoucher = true;
      this.$store.commit("saveCouponInfo", info);
      if (canShow) {
        this.$refs.foodList.$refs.cartBar.changePage();
      }
      return true;
    },
    // 删除优惠券信息
    deleteCouponInfo() {
      this.$store.commit("saveCouponInfo", null);
      this.$store.commit("saveShowCoupon", true);
      this.deleteCouPro();
    },
    // 进入餐单检查购物车餐品 是否合格
    checkCart(info, msg) {
      var cart = this.$store.state.cart;
      if (info) {
        cart = info;
      }
      if (!cart || !cart.length) return;
      var productArr = [];
      var bigsOne = this.productMenu.bigs;
      for (var j = 0; j < bigsOne.length; j++) {
        var smallsOne = bigsOne[j].smalls;
        for (var f = 0; f < smallsOne.length; f++) {
          var productsOne = smallsOne[f].products;
          for (var d = 0; d < productsOne.length; d++) {
            var hasP = false;
            for (var m = 0; m < productArr.length; m++) {
              if (productsOne[d].uid == productArr[m].uid) {
                if (
                  !productsOne[d].isDiscontinue &&
                  productsOne[d].status == 1 &&
                  productsOne[d].isInServiceTime &&
                  !productsOne[d].isSoldOut
                ) {
                  hasP = true;
                  break;
                }
              }
            }
            if (!hasP) {
              productArr.push(productsOne[d]);
            }
          }
        }
      }
      var cartArr = [];
      var cartIndex = [];
      for (var i = 0; i < cart.length; i++) {
        for (var b = 0; b < productArr.length; b++) {
          if (
            cart[i].uid == productArr[b].uid ||
            cart[i].pid == productArr[b].uid
          ) {
            if (
              !productArr[b].isDiscontinue &&
              productArr[b].status == 1 &&
              productArr[b].isInServiceTime &&
              !productArr[b].isSoldOut
            ) {
              if (this.$store.state.language == 1) {
                if (productArr[b].logo) {
                  cart[i].logo = productArr[b].logo;
                  if (cart[i].selectedFood) {
                    cart[i].selectedFood.logo = productArr[b].logo;
                  }
                }
              } else {
                if (productArr[b].logoEn) {
                  cart[i].logo = productArr[b].logoEn;
                  if (cart[i].selectedFood) {
                    cart[i].selectedFood.logo = productArr[b].logoEn;
                  }
                }
              }

              cartArr.push(cart[i]);
              cartIndex.push(i);
              break;
            }
          }
        }
      }

      var unCart = [];
      var unCartName = "";
      for (var c = 0; c < cart.length; c++) {
        if (cartIndex.indexOf(c) == -1) {
          unCart.push(cart[c]);
          if (this.$store.state.language == 1) {
            unCartName += cart[c].name + " ";
          } else {
            unCartName += cart[c].nameEn + " ";
          }
        }
      }

      this.$store.commit("replaceCart", cartArr);
      localStorage.setItem("store", JSON.stringify(this.$store.state));
      if (msg) {
        this.errorFoodTit = this.GWC_remove;
        this.showErrorFood = true;
        return;
      }
      if (this.pageParam.type.errorFood) {
        this.errorFoodTit = this.GWC_remove;
        this.showErrorFood = true;
        return;
      }
      if (unCart.length == 0) {
        this.rightBtnCan = false;
        return;
      }
      this.unFood = unCartName;
      this.isNotProvideTime = true;
      window.dataLayer.push({
        event: "view_continue_order",
        ecommerce: {
          Message_variant: this.confirmTitle,
        },
      });
    },
    // 关闭餐品不在供应时间
    closeNotProvideTime() {
      this.isNotProvideTime = false;
    },
    // 切换语言
    setlanguage() {
      this.setBoxPaddign();
      var T;
      if (this.$store.state.language == 1) {
        T = "chinese";
      } else {
        T = "english";
      }
      var hour = new Date().getHours();
      var timeType;
      if (hour < 10) {
        timeType = "morning";
      } else if (hour < 14) {
        timeType = "lunch";
      } else if (hour < 17) {
        timeType = "tea";
      } else {
        timeType = "dinner";
      }
      this.morning = language[timeType][T];
      this.suDa = language.suDa[T];
      this.new_CD_ziQi = language.new_CD_ziQi[T];
      this.new_TS_ziQi = language.new_TS_ziQi[T];
      this.jiShi = language.jiShi[T];
      this.yuYue = language.yuYue[T];
      this.coupon = language.coupon[T];
      this.confirmTitle = language.jiXuDianDan[T];
      this.selectTime = language.selectTime[T];
      this.tangShi = language.tangShi[T];
      this.meiWeiSuDa = language.meiWeiSuDa[T];
      this.new_CD_dianCan = language.new_CD_dianCan[T];
      this.DC_time_err_one = language.DC_time_err_one[T];
      this.DC_time_err_JS = language.DC_time_err_JS[T];
      this.DC_time_err_morning = language.DC_time_err_morning[T];
      this.DC_time_err_dinner = language.DC_time_err_dinner[T];
      this.finishBtn = language.finishBtn[T];
      this.YJ_time_err = language.YJ_time_err[T];
      this.GWC_del = language.GWC_del[T];
      this.GWC_remove = language.GWC_remove[T];
      this.backTitle =
        this.$store.state.language == 1
          ? "是否要繼續落單?"
          : "Continue Ordering? ";
      this.backLeftBtn = this.$store.state.language == 1 ? "退出" : "Cancel";
      this.backRightBtn =
        this.$store.state.language == 1 ? "繼續落單" : "Continue Ordering";
      this.confirmTitle =
        this.$store.state.language == 1
          ? "是否要繼續落單?"
          : "Continue Ordering? "; //提示框的标题
      this.leftBtn = this.$store.state.language == 1 ? "返回" : "Cancel";
      this.rightBtn = this.$store.state.language == 1 ? "繼續落單" : "Confirm";
      this.dateConfirm = this.$store.state.language == 1 ? "確認" : "Confirm";
      this.dateCancel = this.$store.state.language == 1 ? "取消" : "Cancel";
      this.dateTextOne =
        this.$store.state.language == 1 ? "預計" : "Est. Delivery Time:";
      this.dateTextPick =
        this.$store.state.language == 1 ? "預計" : "Est. Pickup Time:";
      this.dateTextTwo = this.$store.state.language == 1 ? "後送到" : "";
      this.dateTextThree = this.$store.state.language == 1 ? "準備好" : "";
      this.dateTextFour =
        this.$store.state.language == 1 ? "送達" : " delivery";
      this.nowLocationP =
        this.$store.state.language == 1 ? "用餐位置" : "Dining Location";
      this.SYMK = language.SYMK[T];
      this.WDSYMK = language.WDSYMK[T];
      this.WDSYMKSUBJE = language.WDSYMKSUBJE[T];
      this.WDSYMKSUBCP = language.WDSYMKSUBCP[T];
      this.WDSYMKSUB = language.WDSYMKSUB[T];
      this.JXXG = language.JXXG[T];
      this.QWFK = language.QWFK[T];
      this.TDCP = language.TDCP[T];
      this.QSJG = language.QSJG[T];
      this.BZSYSJFW = language.BZSYSJFW[T];
      this.HQXXSB = language.HQXXSB[T];
      this.BZPSFWN = language.BZPSFWN[T];
      this.WFSYYHQ = language.WFSYYHQ[T];
      this.canUseCoupon = language.WFSYYHQ[T];
      this.BZCD = language.BZCD[T];
      this.BZCDHYSQ = language.BZCDHYSQ[T];
      this.BZDQSJDSUB = language.BZDQSJDSUB[T];
      this.BZDQSJDSUBF = language.BZDQSJDSUBF[T];
      this.BZCDHYSQSUB = language.BZCDHYSQSUB[T];
      this.CDYBSYMD = language.CDYBSYMD[T];
      this.SXDZBZFWFWN = language.SXDZBZFWFWN[T];
      this.MZDDZKSYYZQSUB = language.MZDDZKSYYZQSUB[T];
      this.MZDDZKSYYZQSUBE = language.MZDDZKSYYZQSUBE[T];
      this.errorFoodMsgCancel =
        this.$store.state.language == 1 ? "取消" : "Cancel";
      this.errorFoodMsgConfirm =
        this.$store.state.language == 1 ? "繼續落單" : "Confirm";
      this.XZSJ = this.$store.state.language == 1 ? "選擇時間" : "Select Time";
      if (this.businessType == 3 || this.businessType == 2) {
        this.MDFMALERT = language.MDFMTS[T];
        this.MDFMALERTTITLE = "";
      } else {
        this.MDFMALERTTITLE = language.MDFMALERTTITLE[T];
        this.MDFMALERT = language.MDFMWM[T];
      }
      this.finishIsBusyBtn = language.finishIsBusyBtn[T];
      this.GWCWKYHCW = language.GWCWKYHCW[T];
      this.BZCZQYY = language.BZCZQYY[T];
      this.BZCWMYY = language.BZCWMYY[T];
      this.CDYFH = language.CDYFH[T];
      this.SCSJYG=language.SCSJYG[T];
    },
    // 检查banner跳过来的餐单分类
    checkBannerMenu() {
      var first = this.pageParam.type.firstLevel;
      var second = this.pageParam.type.secondLevel;
      var firstId;
      var firstIndex;
      var secondId;
      var bigsOne = this.productMenu.bigs;
      for (var j = 0; j < bigsOne.length; j++) {
        if (first == bigsOne[j].name) {
          firstId = bigsOne[j].uid;
          firstIndex = j;
        }
        // if (!second) break;
        var smallsOne = bigsOne[j].smalls;
        for (var f = 0; f < smallsOne.length; f++) {
          if (second == smallsOne[f].name) {
            secondId = smallsOne[f].uid;
          }
        }
      }
      if (!firstId) {
        firstId = bigsOne[0].uid;
        firstIndex = 0;
      }
      this.$refs.foodList.selectCategory(firstId, firstIndex);
      if (secondId) {
        setTimeout(() => {
          this.$refs.foodList.changeTab(secondId);
        }, 300);
      }
    },
    // 检查banner跳转过来的餐品
    checkBannerFood() {
      var canJump = this.$store.state.jump;
      if (!canJump) return;
      this.$store.commit("saveJumpDetail", false);
      var pid = this.pageParam.type.productId;
      var product = null;
      var bigsOne = this.productMenu.bigs;
      var hasP = false;
      for (var j = 0; j < bigsOne.length; j++) {
        var smallsOne = bigsOne[j].smalls;
        for (var f = 0; f < smallsOne.length; f++) {
          var productsOne = smallsOne[f].products;
          for (var d = 0; d < productsOne.length; d++) {
            if (productsOne[d].uid == pid) {
              if (
                !productsOne[d].isDiscontinue &&
                productsOne[d].status == 1 &&
                productsOne[d].isInServiceTime
              ) {
                hasP = true;
                product = productsOne[d];
                break;
              }
            }
          }
        }
      }

      if (hasP) {
        if (product.type == 1) {
          if (product.property) {
            this.lookDetail(product);
          } else {
            product.num = 1;
            var cartPro = {
              shopId: this.storeId,
              product: product,
            };
            this.$store.commit("createCart", cartPro);
          }
        } else {
          this.lookDetail(product);
        }
      } else {
        if (this.showConfirm) {
          this.confirmTitle =
            this.pageParam.type.productName + " " + this.confirmTitle;
          return;
        }

        this.errorFoodTit = this.GWC_remove;
        this.showErrorFood = true;
      }
    },
    // 查看食物详情
    lookDetail(food) {
      // if (food.isSoldOut) {
      //   this.couponErrorTitle = this.BZCDHYSQ;
      //   this.couponErrorSubTitle = this.BZCDHYSQSUB;
      //   this.couponErrorSubTitleE = "";
      //   this.couponErrorSubTitleF = "";
      //   this.voucherErrorLeftBtn = this.JXXG;
      //   this.couponInfo = null;
      //   this.showCouponError = true;
      //   this.isClearVoucher = true;
      //   this.cancelCoupon();
      //   return;
      // }
      if (this.pageParam.type && !this.pageParam.type.jumpType) {
        if (this.$store.state.showCoupon) {
          // this.couponInfo = null;
          return;
        }
      }
      food.storeId = this.storeId;
      food.menuId = this.productMenu.menuId;
      food.KFCbusinessType = this.businessType;
      var params = JSON.stringify(food);
      params = encodeURIComponent(params);
      if (food.type == 1 && !food.property) return;
      this.$router.push("/foodDetail/" + params);
    },
    // app再来一单中的信息
    async getAgainStore() {
      var params = {
        actionName: "candao.orderOwn.getOrderDetail",
        content: {
          orderId: this.pageParam.type.reference,
        },
      };
      let result = await postDC("Action", params);
      if (result.status != 1) return this.$toast(this.HQXXSB);
      this.storeId = result.data.storeId;
      if (result.data.sendType == 4) {
        result.data.sendType = 1;
      }
      this.businessType = result.data.sendType;
      return result.data;
    },
    // app添加再来一单
    async getAgainOrder(oid, mid) {
      var againParams = {
        actionName: "candao.orderOwn.reApplyOrder",
        content: {
          orderId: oid,
          menuId: mid,
        },
      };
      if (
        this.pageParam.type != 1 &&
        this.pageParam.type.app &&
        this.pageParam.type.deviceId
      ) {
        againParams.content.deviceId = this.pageParam.type.deviceId;
      }
      let againResult = await postDC("Action", againParams);
      if (againResult.status == 1) {
        if (againResult.data.productsOwn) {
          var productsOwn = againResult.data.productsOwn;
          var product = [];
          for (var i = 0; i < productsOwn.length; i++) {
            var obj = {};
            for (var key in productsOwn[i]) {
              if (typeof productsOwn[i][key] != "object") {
                obj[key] = productsOwn[i][key];
              }
            }
            obj.selectedFood = productsOwn[i];
            product.push(obj);
          }
        }
        if (againResult.data.errorProducts) {
          this.checkCart(product, againResult.data.msg);
          return;
        }
        this.checkCart(product);
      }
    },
    // app点击banner跳进来
    appBannerJoin() {
      this.pageParam.type.page = 1;
      this.pageParam.type.jumpType = this.pageParam.type.banner.jumpType;
      this.pageParam.type.businessType =
        this.pageParam.type.banner.businessType;
      if (this.pageParam.type.banner.storeId) {
        this.pageParam.type.storeId = this.pageParam.type.banner.storeId;
      }
      if (this.pageParam.type.banner.jumpType == 6) {
        this.pageParam.type.productName =
          this.pageParam.type.banner.specifiedProductName;
        this.pageParam.type.productId =
          this.pageParam.type.banner.specifiedProductId;
      }
      if (this.pageParam.type.banner.jumpType == 2) {
        if (this.pageParam.type.banner.productPrimaryTypeName) {
          this.pageParam.type.firstLevel =
            this.pageParam.type.banner.productPrimaryTypeName;
        }
        if (this.pageParam.type.banner.productSecondaryTypeName) {
          this.pageParam.type.secondLevel =
            this.pageParam.type.banner.productSecondaryTypeName;
        }
      }
      this.businessType = this.pageParam.type.businessType;
      if (this.pageParam.type.storeId) {
        this.storeId = this.pageParam.type.storeId;
        this.getProductMenu();
      } else {
        this.getNearStoreList();
      }
    },
    getParam() {
      this.$nextTick(() => {
        localStorage.removeItem("KFCYK");
        setTimeout(() => {
          // 设置语言
          this.setlanguage();
          this.foodListTop = document
            .getElementById("func")
            .getBoundingClientRect().top;
          this.pageParam = this.$route.params;
          localStorage.setItem("KFCOrderParam", this.pageParam.type);
          this.pageParam.type = JSON.parse(this.pageParam.type);
          var KFCClear = localStorage.getItem("KFCClear");
          if (KFCClear) {
            localStorage.removeItem("KFCClear");
            this.$store.commit("clearCart");
          }
          // 从app进来
          if (this.pageParam.type.app) {
            // localStorage.removeItem("firstJoinCoupon");
            // this.$store.commit("saveShowCoupon", false);
            this.$store.commit("saveApp", this.pageParam.type.app);
            if (this.pageParam.type.storeid) {
              this.storeId = this.pageParam.type.storeid;
            }
            if (this.pageParam.type.sendType) {
              this.businessType = this.pageParam.type.sendType;
            }
            this.appInfo = this.pageParam.type;
            this.$store.commit("saveAppInfo", this.appInfo);
            if (this.pageParam.type.deviceId) {
              this.$store.commit("saveDeviceId", this.pageParam.type.deviceId);
            } else {
              this.$store.commit("saveDeviceId", null);
            }
            if (this.pageParam.type.positionInfo) {
              var copyInfo = {
                building: this.pageParam.type.positionInfo.address,
                district: this.pageParam.type.positionInfo.address,
                lat: this.pageParam.type.positionInfo.latitude,
                lng: this.pageParam.type.positionInfo.longitude,
              };
              if (this.pageParam.type.positionInfo.name) {
                copyInfo.contactPerson = this.pageParam.type.positionInfo.name;
              }
              if (this.pageParam.type.positionInfo.phone) {
                copyInfo.phoneNo = this.pageParam.type.positionInfo.phone;
              }
              if (this.pageParam.type.positionInfo.flat) {
                copyInfo.flat = this.pageParam.type.positionInfo.flat;
              }
              if (this.pageParam.type.positionInfo.floor) {
                copyInfo.floor = this.pageParam.type.positionInfo.floor;
              }
              if (this.pageParam.type.positionInfo.block) {
                copyInfo.block = this.pageParam.type.positionInfo.block;
              }
              if (this.pageParam.type.positionInfo.street) {
                copyInfo.street = this.pageParam.type.positionInfo.street;
              }
              this.$store.commit("savePlace", copyInfo);
            }
            this.$store.commit(
              "savePosition",
              this.pageParam.type.positionInfo
            );
            if (this.pageParam.type.language) {
              if (this.pageParam.type.language == 0) {
                this.pageParam.type.language = 1;
              }
              this.$store.commit("setLanguage", this.pageParam.type.language);
            }
            if (this.pageParam.type.userId) {
              if (this.$store.state.userInfo) {
                if (
                  this.$store.state.userInfo.userId !=
                  this.pageParam.type.userId
                ) {
                  this.$store.commit("clearUserInfo");
                  this.$store.commit("clearCart");
                }
              } else {
                this.$store.commit("clearUserInfo");
                this.$store.commit("clearCart");
              }
              this.getPersionInfo();
              localStorage.setItem("KFCID", 1);
            } else {
              var ykTag = localStorage.getItem("ykTag");
              if (this.$store.state.userInfo) {
                this.$store.commit("clearUserInfo");
                this.$store.commit("clearCart");
              }
              if (ykTag && ykTag != 0) {
                this.$store.commit("clearUserInfo");
                this.$store.commit("clearCart");
              }
              localStorage.setItem("KFCID", 0);
              if (this.pageParam.type.banner) {
                this.appBannerJoin();
              } else if (this.pageParam.type.reference) {
                this.getProductMenu();
              } else {
                if (this.businessType == 1) {
                  this.getNearStoreList();
                } else {
                  this.getProductMenu();
                }
              }
            }
          } else {
            this.$store.commit("saveApp", false);
            if (this.pageParam.type != 1) {
              if (this.pageParam.type.appSelfPick) {
                this.$store.commit("saveApp", true);
              }
              if (this.pageParam.type.lang == "tc") {
                this.$store.commit("setLanguage", 1);
              } else if (this.pageParam.type.lang == "en") {
                this.$store.commit("setLanguage", 2);
              }
            }
            if (this.pageParam.type != 1) {
              if (this.pageParam.type.page == 1) {
                this.businessType = this.pageParam.type.businessType;
                if (this.pageParam.type.storeId) {
                  this.storeId = this.pageParam.type.storeId;
                  this.getProductMenu();
                } else {
                  this.getNearStoreList();
                }
                this.setlanguage();
                return;
              }
              if (
                this.pageParam.type.coupon &&
                this.pageParam.type.businessType == 1
              ) {
                this.businessType = this.pageParam.type.businessType;
                this.getNearStoreList();
              } else if (
                this.pageParam.type.coupon &&
                this.pageParam.type.businessType != 1
              ) {
                this.businessType = this.pageParam.type.businessType;
                this.shopNow = this.pageParam.type;
                this.storeId =
                  this.pageParam.type.storeId ||
                  this.pageParam.type.canDaoShopId;
                this.getProductMenu();
              } else if (this.pageParam.type.products) {
                //再来一单
                this.businessType = this.pageParam.type.sendType;
                this.storeId = this.pageParam.type.storeId;
                this.getProductMenu();
              } else {
                this.businessType = this.pageParam.type.businessType;
                this.shopNow = this.pageParam.type;
                this.storeId =
                  this.pageParam.type.storeId ||
                  this.pageParam.type.canDaoShopId;
                this.getProductMenu();
              }
            } else {
              this.businessType = this.pageParam.type;
              this.getNearStoreList();
            }
            this.$store.commit("saveMenuType", this.businessType);
          }
          this.setlanguage();
        }, 500);
      });
    },
    setS() {
      let scrollTop = document.querySelector(".box").scrollTop;
      let topbarHeight = document.querySelector(".topbar").offsetHeight;
      let funcHeight = document.querySelector(".func#func");
      if (funcHeight) {
        funcHeight = document.querySelector(".func#func").offsetHeight;
      }
      this.scrollTopHeight = scrollTop;
      document.body.style.cssText = "overflow:hidden;";
      document.querySelector("#func").classList.add("active");
      document.querySelector("#func").style.paddingTop = topbarHeight + "px";

      document.querySelector(".funcbox").style.display = "flex";
      if (document.querySelector(".category")) {
        document.querySelector(".category").classList.add("active");
        document.querySelector(".category").style.top = funcHeight + "px";
      }
      if (document.querySelector(".tab")) {
        document.querySelector(".tab").classList.add("active");
      }
      if (document.querySelector(".footLists")) {
        document.querySelector(".footLists").classList.add("active");
      }
    },
    bodyScroll() {
      if (JSON.stringify(this.productMenu) == "{}") {
        return;
      }
      let scrollTop = document.querySelector(".box").scrollTop;
      let topbarHeight = document.querySelector(".topbar").offsetHeight;
      let funcHeight = document.querySelector(".func#func");
      if (funcHeight) {
        funcHeight = document.querySelector(".func#func").offsetHeight;
      }
      this.scrollTopHeight = scrollTop;
      var height1 = document.querySelector(".banner-box")
        ? document.querySelector(".banner-box").offsetHeight
        : 0;
      let scrollH = height1 + document.querySelector(".topbar").offsetHeight;
      // 固定左边一级菜单和优惠券
      if (
        scrollH - scrollTop >=
        document.querySelector(".topbar").offsetHeight
      ) {
        document.body.style.cssText = "overflow:auto;";
        document.querySelector("#func").classList.remove("active");
        document.querySelector("#func").style.paddingTop = 0 + "px";
        document.querySelector(".funcbox").style.display = "none";
        if (document.querySelector(".category")) {
          document.querySelector(".category").classList.remove("active");
          document.querySelector(".category").style.top = 0 + "px";
        }
        if (document.querySelector(".category-inner")) {
          document.querySelector(".category-inner").scrollTop = 0;
          document.querySelector(".category-inner").classList.remove("active");
        }
        if (document.querySelector(".tab")) {
          document.querySelector(".tab").classList.remove("active");
        }
        if (document.querySelector(".footLists")) {
          document.querySelector(".footLists").classList.remove("active");
        }
      } else {
        document.body.style.cssText = "overflow:hidden;";
        document.querySelector("#func").classList.add("active");
        document.querySelector("#func").style.paddingTop = topbarHeight + "px";

        document.querySelector(".funcbox").style.display = "flex";
        if (document.querySelector(".category")) {
          document.querySelector(".category").classList.add("active");
          document.querySelector(".category").style.top = funcHeight + "px";
        }
        if (document.querySelector(".category-inner")) {
          document.querySelector(".category-inner").classList.add("active");
        }
        if (document.querySelector(".tab")) {
          document.querySelector(".tab").classList.add("active");
        }
        if (document.querySelector(".footLists")) {
          document.querySelector(".footLists").classList.add("active");
        }
        this.$refs.foodList.setSecondTitleView();
      }
      // 根据滚动位置判断哪个被选中
      let distanceObj = this.$store.distanceObj;
      if (distanceObj && distanceObj.length) {
        for (let i = 0; i < distanceObj.length; i++) {
          if (distanceObj[i + 1]) {
            if (
              scrollTop >= distanceObj[i].value &&
              scrollTop < distanceObj[i + 1].value
            ) {
              this.$refs.foodList.showTag = distanceObj[i].uid;
              this.$refs.foodList.secondTitle = this.productMenu.bigs[i];
              for (let j = 0; j < distanceObj[i].smalls.length; j++) {
                if (distanceObj[i].smalls[j + 1]) {
                  if (
                    scrollTop >= distanceObj[i].smalls[j].value &&
                    scrollTop < distanceObj[i].smalls[j + 1].value
                  ) {
                    this.$refs.foodList.secondUid =
                      distanceObj[i].smalls[j].uid;
                    break;
                  }
                } else {
                  if (scrollTop >= distanceObj[i].smalls[j].value) {
                    this.$refs.foodList.secondUid =
                      distanceObj[i].smalls[j].uid;
                  }
                }
              }
              break;
            }
          } else {
            if (scrollTop >= distanceObj[i].value) {
              this.$refs.foodList.showTag = distanceObj[i].uid;
              this.$refs.foodList.secondTitle = this.productMenu.bigs[i];
              for (let j = 0; j < distanceObj[i].smalls.length; j++) {
                if (distanceObj[i].smalls[j + 1]) {
                  if (
                    scrollTop >= distanceObj[i].smalls[j].value &&
                    scrollTop < distanceObj[i].smalls[j + 1].value
                  ) {
                    this.$refs.foodList.secondUid =
                      distanceObj[i].smalls[j].uid;
                    break;
                  }
                } else {
                  if (scrollTop >= distanceObj[i].smalls[j].value) {
                    this.$refs.foodList.secondUid =
                      distanceObj[i].smalls[j].uid;
                  }
                }
              }
            }
          }
        }
      }
    },
    // 埋点
    dataLayerPush(event, uid) {
      var items = [];
      var single = [];
      var bigs = this.productMenu.bigs;
      for (var b = 0; b < bigs.length; b++) {
        var smalls = bigs[b].smalls;
        for (var s = 0; s < smalls.length; s++) {
          var products = smalls[s].products;
          for (var p = 0; p < products.length; p++) {
            var itemObj = {};
            itemObj.item_name =
              this.lanType == 1 ? products[p].name : products[p].nameEn;
            itemObj.item_id = products[p].pid;
            itemObj.price = products[p].price;
            itemObj.item_list_name = products[p].smallTypeName;
            itemObj.item_list_id = products[p].smallTypeId;
            itemObj.index = p;
            itemObj.quantity = 1;
            itemObj.rec_usage = "";
            itemObj.item_category = "";
            itemObj.item_variant =
              this.lanType == 1
                ? products[p].desc || products[p].name
                : products[p].descEn || products[p].nameEn;
            if (uid && uid == products[p].uid) {
              single.push(itemObj);
              break;
            } else {
              items.push(itemObj);
            }
          }
        }
      }

      window.dataLayer.push({
        event,
        ecommerce: {
          items: uid ? single : items,
        },
        page_category: "product",
      });
    },
    // 埋点预约时间
    dataLayerPushTime(time) {
      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      var day = new Date().getDate();
      var hour = new Date().getHours();
      var minutes = new Date().getMinutes();
      var second = new Date().getSeconds();
      var nowTime =
        year +
        "-" +
        month +
        "-" +
        day +
        "," +
        hour +
        ":" +
        minutes +
        ":" +
        second;
      var timeArr = time.split(" ");
      var date = timeArr[0].split("/").join("-");
      time = date + "," + timeArr[1];
      window.dataLayer.push({
        event: "pre_order",
        purchase_timedate: nowTime,
        fufillment_timedate: time,
      });
      window.dataLayer.push({
        event: "adv_order",
        purchase_timedate: nowTime,
        fufillment_timedate: time,
      });
    },
    setBoxPaddign() {
      this.$nextTick(() => {
        let scrollH = document.querySelector(".topbar").offsetHeight;
        document.querySelector("#box").style.paddingTop = scrollH + "px";
      });
    },

    // 当已有优惠券选择优惠券
    shouldRemoveVoucher(type) {
      this.isChangePage = false;
      if (type == 1) {
        // 再次选取优惠券
        if (this.cartLength != 0) {
          this.isChangePage = true;
        }
        this.showCoupon = false;
        this.couponErrorTitle = this.WDSYMK;
        this.couponErrorSubTitle = this.MZDDZKSYYZQSUB;
        this.couponErrorSubTitleE = this.MZDDZKSYYZQSUBE;
        this.couponErrorSubTitleF = "";
        this.voucherErrorLeftBtn = this.CDYFH;
        this.isClearVoucher = false;
      } else if (type == 2) {
        // 不在当前使用时间范围内
        this.couponErrorTitle = this.SCSJYG;
        this.couponErrorSubTitle = "";
        this.couponErrorSubTitleE = "";
        this.couponErrorSubTitleF = "";
        this.voucherErrorLeftBtn = this.JXXG;
        this.isClearVoucher = false;
      } else if (type == 3) {
        // 为达到最低送餐金额
        this.couponErrorTitle = this.QSJG + this.shopNow.reachFee;
        this.couponErrorSubTitle = "";
        this.couponErrorSubTitleE = "";
        this.couponErrorSubTitleF = "";
        this.voucherErrorLeftBtn = this.JXXG;
        this.isClearVoucher = false;
      }
      this.couponInfo = null;
      this.showCouponError = true;
    },
  },
  computed: {
    ...mapState(["isYuu"]),
    cartLength() {
      let cart = this.$store.state.cart;
      let length = 0;
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].isCouponPro) continue;
        length += Number(cart[i].num);
      }
      let C = this.$store.state.couponInfo;
      if (
        C &&
        C.isCanUserVoucher &&
        (C.voucherType == 3 || C.voucherType == 5)
      ) {
        length += 1;
      }
      return length;
    },
    // 餐品sku
    foodSku() {
      var products = [];
      var preProducts = [];
      var proAll = [];
      var cartList = copyData(this.$store.state.cart);
      for (var i = 0; i < cartList.length; i++) {
        var obj = {
          pid: cartList[i].uid || cartList[i].pid,
          typeId: cartList[i].type || cartList[i].typeId,
          num: cartList[i].num || cartList[i].selectedFood.num || 1,
          weight: 0,
        };
        if (cartList[i].type == 1 || cartList[i].typeId == 1) {
          if (cartList[i].listRequirements) {
            obj.listRequirements = [];
            for (let m = 0; m < obj.num; m++) {
              var listPro = {
                index: m,
                num: cartList[i].listRequirements[0].num,
                propertys: cartList[i].listRequirements[0].propertys,
              };
              obj.listRequirements.push(listPro);
            }
          }
          proAll.push(obj);
          if (cartList[i].isCouponPro) {
            preProducts.push(obj);
          } else {
            products.push(obj);
          }
        } else if (cartList[i].type != 1 && cartList[i].typeId != 1) {
          cartList[i].selectedFood.num =
            cartList[i].num || cartList[i].selectedFood.num || 1;
          if (
            cartList[i].selectedFood.groups &&
            cartList[i].selectedFood.groups.length
          ) {
            var grp = {
              index: 0,
              num: cartList[i].selectedFood.num,
              mains: cartList[i].selectedFood.groups[0].mains,
            };
            cartList[i].selectedFood.groups[0] = grp;
          }
          proAll.push(cartList[i].selectedFood);
          if (cartList[i].isCouponPro) {
            preProducts.push(cartList[i].selectedFood);
          } else {
            products.push(cartList[i].selectedFood);
          }
        }
      }
      return { products, preProducts, proAll };
    },
    place() {
      var place;
      if (this.businessType == 1) {
        place = this.$store.state.placeInfo
          ? this.$store.state.placeInfo.building
          : "";
      } else if (this.shopNow) {
        place = this.shopNow.storeName;
      } else {
        place = "";
      }
      return place;
    },
    getDay() {
      var month = new Date().getMonth() + 1;
      var day = new Date().getDate();
      var second = new Date().getTime();
      var number = 86400000;
      var arr = [];
      arr.push(month + "月" + day + "日");
      for (var i = 0; i < 6; i++) {
        var date = new Date(number * (i + 1) + second).getDate();
        var monthDay = month + "月" + date + "日";
        arr.push(monthDay);
      }
      return arr;
    },
  },
  watch: {},
  beforeRouteLeave(to) {
    // 允许页面滑动
    document.body.style.cssText = "overflow:auto;";
    // 缓存
    if (to.name != "FoodDetail") {
      this.scrollTopHeight = 0;
      this.$store.commit("clearRouter");
    } else {
      this.$store.commit("saveRouter", "OrderFood");
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "ConfirmOrder" || from.name == "FoodDetail") {
        if (vm.$store.state.showCart) {
          vm.$refs.foodList.cart = false;
        }
        if (vm.$store.state.reserveDate) {
          vm.book = 2;
          vm.second = vm.$store.state.reserveDate;
        }
      } else if (!from.name) {
        if (!vm.$store.state.app) {
          if (vm.$store.state.showCart) {
            vm.$refs.foodList.cart = false;
          }
          if (vm.$store.state.reserveDate) {
            vm.book = 2;
            vm.second = vm.$store.state.reserveDate;
            setHashTag(vm.book, vm.second);
          }
        } else {
          vm.$store.commit("saveReserveDate", null);
        }
      } else {
        vm.$store.commit("saveReserveDate", null);
      }
      setTimeout(() => {
        vm.$store.commit("changeShowCart", false);
      }, 50);
    });
  },
  activated() {
    this.$store.commit("saveUpdateProIndex", null);
    if (this.$store.state.clearCou) {
      this.couponInfo = null;
      this.$store.commit("saveCouponInfo", null);
      this.$store.commit("changeClearCou", false);
    }
    // if (!this.$store.state.app) {
    document.body.style.cssText = "overflow:auto;";
    document.querySelector("#func").classList.remove("active");
    document.querySelector(".funcbox").style.display = "none";
    document.querySelector(".category").classList.remove("active");
    document.querySelector(".tab").classList.remove("active");
    document.querySelector(".footLists").classList.remove("active");
    // 缓存 回到对应位置
    if (this.scrollTopHeight) {
      document.querySelector(".box").scrollTop = this.scrollTopHeight;
    }
  },
  updated() {
    if (this.$store.state.clearCou) {
      this.couponInfo = null;
      this.$store.commit("saveCouponInfo", null);
      this.$store.commit("changeClearCou", false);
    }
  },
  created() {
    provide("getStorePresTime", this.getStorePresTime);
    provide("checkCouponType", this.checkSelectCoupon);
    window.dataLayer.push({
      event: "menu",
    });
    this.canToPay = false;
    this.bodyHeight = document.body.offsetHeight;
    // 参数
    this.getParam();
    // this.$store.commit("changeClearCou", false);
  },
  components: {
    Swiper,
    NoticeSwiper,
    FoodList,
    FoodConfirm,
    Myplace,
    ShopItem,
    UseCoupon,
    ConfirmBox,
  },
};
</script>
<style scoped>
.box {
  position: relative;
  padding-top: 1.1rem;
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  background-color: #fff;
}
.box::-webkit-scrollbar {
  display: none;
}
.topbar {
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 0.16rem 0.05rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 99;
  background-color: #fff;
}
.topIcon {
  /* width: 3rem; */
  height: 0.1rem;
  margin: 0 auto 0.06rem !important;
}

.topTitle {
  height: 0.25rem;
  font-size: 0.18rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.25rem;
  color: #030f14;
  text-align: left;
  margin-bottom: 0.1rem;
}
.topTitle.active {
  width: 0.08rem;
  height: 0.16rem;
  background: url("../../assets/icon/left.png") no-repeat center;
  background-size: 0.32rem 0.32rem;
}
.topInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.backIcon {
  width: 0.24rem;
  height: 0.24rem;
  background: url("../../assets/icon/dd.png") no-repeat center;
  background-size: cover;
  margin-right: 0.08rem;
}
.topPlace {
  /* width: 1.5rem; */
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #131314;
  text-align: left;
}
.topPlace p {
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.topPlace p span {
  max-width: 1.5rem;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.topPlace i {
  display: inline-block;
  width: 0.16rem;
  height: 0.12rem;
  background: url("../../assets/icon/show.png") no-repeat center;
  background-size: cover;
}
.timeFunc {
  width: 1.5rem;
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.21rem;
  color: #b1b1b3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.topRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.12rem;
  font-weight: 600;
  color: #131314;
}
.topRight span {
  min-width: 0.56rem;
  padding: 0 0.05rem;
  height: 0.28rem;
  line-height: 0.28rem;
  text-align: center;
  border: 0.01rem solid #b1b1b3;
  color: #b1b1b3;
  border-radius: 0.04rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.topRight span + span {
  margin-left: 0.08rem;
}
.topRight span.active {
  color: #e4022b;
  border-color: #e4022b;
}
.banner-box {
  position: relative;
  z-index: 9;
  background: #fff;
  padding-bottom: 0.08rem;
}
.func {
  width: 100%;
  height: 0.68rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-sizing: border-box; */
  background-color: #fff;
  z-index: 8;
  position: relative;
}
.funcbox {
  display: none;
}
.func.active {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  /* padding: 1.1rem 0 0; */
}
.sendTime {
  margin-left: 0.16rem;
  /* width: 0.8rem; */
  height: 0.34rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.17rem;
  background: #f2f3f5;
}
.sendTime span {
  padding: 0 0.1rem;
  /* width: 50%; */
  height: 100%;
  text-align: center;
  line-height: 0.34rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #b1b1b3;
  border-radius: 0.17rem;
}
.sendTime span.active {
  background-color: #e4022b;
  color: #fff;
}
.coupon {
  margin-right: 0.16rem;
  width: 0.94rem;
  height: 0.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(23, 23, 23, 0.16);
  border-radius: 0.08rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.36rem;
  color: #03060d;
}
.coupon i {
  width: 0.2rem;
  height: 0.2rem;
  background: url("../../assets/icon/couponIcon.png") no-repeat center;
  background-size: cover;
}
/* .foodConBox {
  height: 70vh;
} */
.datePicker {
  width: 100%;
  position: fixed;
  bottom: -100vh;
  left: 50%;
  margin-left: -1.875rem;
  z-index: 9999999999;
  transition: all 0.2s linear;
}
.datePicker.active {
  bottom: 0;
}

.datePicker .selectTimeTitle {
  width: 100%;
  text-align: left;
  background-color: #fff;
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.12rem;
  font-weight: 700;
  position: absolute;
  top: 0.4rem;
  left: 0.16rem;
  z-index: 99;
  font-family: "SF Pro Display";
  font-style: normal;
  letter-spacing: 0.7px;

  color: #333333;
}
.pickerBg {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -1.875rem;

  z-index: 999999999;
}
/* 选择门店弹窗 */
.shopListBox {
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: -100vh;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999999;
  transition: all 0.2s linear;
}
.shopListBox.active {
  bottom: 0;
}
.shopList {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0.12rem 0.12rem 0 0;
  max-height: 5rem;
  min-height: 1rem;
}
.shopListTitle {
  font-size: 0.16rem;
  height: 0.2rem;
  line-height: 0.2rem;
}
.itemBox {
  max-height: 4.8rem;
  overflow-y: scroll;
}
.stopScorll {
  height: 100vh;
  overflow-y: hidden;
  /* position: fixed; */
}
.funcTitle {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 600;
  font-size: 0.2rem;
  line-height: 0.28rem;
  margin-left: 0.16rem;
}
.deforeFoodImg {
  width: 100%;
}
.deforeFoodImg img {
  width: 100%;
}
.yuuActive {
  opacity: 0.5 !important;
}
</style>