<template>
  <div class="cartItem">
    <img v-if="$store.state.language == 1" :src="product.logo" alt="" />
    <img v-else :src="product.logoEn" alt="" />
    <div>
      <div class="nameBox">
        <p class="couponIcon" v-if="false">券</p>
        <p class="name" v-if="$store.state.language == 1">{{ product.name }}</p>
        <p class="name" v-else>{{ product.nameEn }}</p>
        <p class="changeIcon" @click="updateFood" v-if="product.type != 1">
          <i></i>
          <span>{{ $store.state.language == 1 ? "更改" : "Edit" }}</span>
        </p>
      </div>

      <p class="content" @click="lookDesc" v-if="product.type != 1">
        <span :class="{ active: false }">{{ product.descStr }}</span>
        <!-- <van-icon name="arrow-down" v-if="active" size="10" color="#808080" />
        <van-icon name="arrow-up" v-else size="10" color="#808080" /> -->
      </p>
      <div class="price">
        <p class="money">
          {{ $store.state.money[$store.state.area] }} <span>{{ price }}</span>
        </p>
        <div class="foodNum">
          <span @click="changeNum(1)">-</span>
          <input
            :readonly="product.isCouponPro"
            type="text"
            @input="changeNum(3)"
            ref="numInput"
            @focus="changeClass(1)"
            @blur="changeClass(2)"
            v-model="foodNum"
          />
          <span
            @click="changeNum(2)"
            :class="{
              active: disabled || product.isCouponPro,
            }"
            >+</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CartItem",
  data() {
    return {
      foodNum: 1,
      active: true,
    };
  },
  props: {
    proIndex: {
      type: Number,
    },
    product: {
      type: Object,
      default: () => {
        return {};
      },
    },
    storeId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    changeClass(type) {
      type == 1
        ? this.$refs.numInput.classList.add("active")
        : this.$refs.numInput.classList.remove("active");
    },
    changeNum(type) {
      if ((type == 3 || type==2) && this.product.isCouponPro) return;
      if (type == 1 && this.foodNum > 0) {
        this.foodNum--;
      } else if (type == 2 && !this.disabled && !this.product.isCouponPro) {
        this.foodNum++;
      }
      if (
        !this.product.listRequirements ||
        !this.product.listRequirements.length
      ) {
        var params = {
          uid: this.product.uid || this.product.pid,
          num: this.foodNum,
        };
        this.$emit("changeNum", params);
        this.$emit("dataLayerPush");
      }
      var productObj = {};
      var product = this.product;
      product.num = this.foodNum;
      productObj.shopId = this.storeId;
      productObj.product = product;
      productObj.cart = true;
      if (type == 1 && this.product.isCouponPro) {
        this.$emit("clearCoupon");
      }
      this.$store.commit("createCart", productObj);

      this.dataLayerPush(product, type);
    },
    lookDesc() {
      this.active = !this.active;
    },
    //
    setValue() {
      this.foodNum = this.product.num;
    },
    updateFood() {
      this.$store.commit("saveUpdateProIndex", this.proIndex);
      this.$emit("updateFood", this.product.uid);
    },
    // 埋点 加入购物车
    dataLayerPush(pro, type) {
      var items = [];
      if (pro.type == 1) {
        var itemObj = {};
        itemObj.item_name = this.lanType == 1 ? pro.name : pro.nameEn;
        itemObj.item_id = pro.uid;
        itemObj.price = pro.price;
        itemObj.addon_org_price = 0;
        itemObj.item_list_name = pro.bigTypeName;
        itemObj.item_list_id = pro.bigTypeId;
        itemObj.quantity = pro.num;
        itemObj.rec_usage = "";
        itemObj.item_category = "";
        itemObj.item_variant = pro.memo;
        items.push(itemObj);
      } else {
        var groups = pro.selectedFood.groups;
        groups.forEach((p) => {
          p.mains.forEach((m) => {
            m.groups.forEach((g) => {
              g.products.forEach((ps, index) => {
                var itemObj = {};
                itemObj.item_name =
                  this.$store.state.language == 1 ? ps.name : ps.nameEn;
                itemObj.item_id = ps.pid;
                itemObj.price = ps.price;
                itemObj.addon_org_price = 0;
                itemObj.item_list_name = pro.bigTypeName;
                itemObj.item_list_id = pro.bigTypeId;
                itemObj.index = index;
                itemObj.quantity = ps.num;
                itemObj.rec_usage = "";
                itemObj.item_category = "";
                itemObj.item_variant = ps.memo;
                items.push(itemObj);
              });
            });
          });
        });
      }
      var event = "remove_from_cart";
      if (type != 1) {
        event = "add_quantity";
      }
      window.dataLayer.push({
        event,
        ecommerce: {
          items,
        },
      });
    },
  },
  computed: {
    price() {
      var productPrice = Number(this.product.price);
      if (
        this.product.selectedFood &&
        this.product.type != 1 &&
        this.product.typeId != 1
        // &&
        // !this.product.isCouponPro
      ) {
        var product = this.product.selectedFood.groups;
        for (var i = 0; i < product.length; i++) {
          var mains = product[i].mains;
          for (var a = 0; a < mains.length; a++) {
            var groups = mains[a].groups;
            for (var b = 0; b < groups.length; b++) {
              var foods = groups[b].products;
              for (var k = 0; k < foods.length; k++) {
                productPrice += Number(foods[k].price) * Number(foods[k].num);
              }
            }
          }
        }
      }
      return productPrice;
    },
    disabledOne() {
      if (
        this.product.listRequirements &&
        this.product.listRequirements.length
      ) {
        return true;
      } else {
        return false;
      }
    },
    disabled() {
      if (this.foodNum >= this.product.maxNum) {
        return false;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.cartItem {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.16rem 0;
  box-sizing: border-box;
}
.cartItem img {
  width: 0.9rem;
  height: 0.75rem;
  object-fit: cover;
  margin-right: 0.08rem;
}
.nameBox {
  width: 2.45rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.1rem;
}
.couponIcon {
  padding: 0 0.02rem;
  color: #e4022b;
  border: 0.01rem solid #e4022b;
  border-radius: 0.02rem;
  font-size: 0.14rem;
}
.changeIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.11rem;
  color: #808080;
}
.changeIcon i {
  width: 0.16rem;
  height: 0.16rem;
  background: url("../../assets/icon/vector.png") no-repeat center;
  background-size: cover;
  margin-right: 0.04rem;
}
.name {
  width: 1.8rem;
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.2rem;
  color: #030f14;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.content {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #808080;
  text-align: left;
  /* background: #f2f3f5; */
  /* padding: 0.02rem 0.1rem; */
  box-sizing: border-box;
  margin: 0.1rem 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 0.32rem;
}

.content span {
  width: 100%;
  transition: all 0.2s linear;
}
.content span.active {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.price {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.18rem;
  color: #03060d;
  font-size: 0.1rem;
}
.money span {
  font-size: 0.16rem;
}
.foodNum {
  display: flex;
  justify-content: center;
  align-items: center;
}
.foodNum input {
  width: 0.24rem;
  height: 0.24rem;
  outline: none;
  border: none;
  text-align: center;
  margin: 0 0.15rem;
  border-radius: 0.02rem;
}
.foodNum input.active {
  background-color: rgba(242, 242, 242, 1);
}
.foodNum span {
  width: 0.2rem;
  height: 0.2rem;
  border: 0.02rem solid #e4022b;
  border-radius: 0.04rem;
  line-height: 0.18rem;
  text-align: center;
  color: #e4022b;
  font-size: 0.18rem;
}
.foodNum span {
  background-color: #e4022b;
  color: #fff;
}
.foodNum span:last-child.active {
  background-color: #bbb3b3;
  border-color: #bbb3b3;
}
</style>