<template>
  <div class="notice">
    <div class="noticeIcon"></div>
    <van-swipe
      class="noticeSwiper"
      :vertical="true"
      :autoplay="2000"
      :show-indicators="false"
    >
      <van-swipe-item v-for="item in brandInfos" :key="item">
        <span>
          {{
            $store.state.language == 1 ? item.announcement : item.announcementEn
          }}
        </span>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
export default {
  name: "NoticeSwiper",
  props: {
    brandInfos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods:{
   
  }
};
</script>
<style scoped>
.notice {
  margin: 0.08rem 0.16rem;
  background-color: #eee;
  height: 0.44rem;
  border-radius: 0.08rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.44remx;
  color: #e3002b;
  text-align: left;
}
.noticeSwiper {
  width: 3rem;
  height: 100%;
}
.notice span {
  display: inline-block;
  /* line-height: 0.44rem; */
  text-align: left;
}
.noticeIcon {
  width: 0.2rem;
  height: 0.2rem;
  background: url("../../assets/icon/notice.png") no-repeat center;
  background-size: cover;
  margin-left: 0.12rem;
}
.van-swipe-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>>
    