<template>
  <div>
    <van-swipe
      class="my-swipe"
      :autoplay="3000"
      lazy-render
      height="200"
      :show-indicators="true"
      indicator-color="#ffffff"
    >
      <van-swipe-item
        @click="clickTag(index)"
        v-for="(item, index) in imglist"
        :key="index"
        ><img :src="item.picUrl" alt=""
      /></van-swipe-item>
      <!-- <van-swipe-item><img src="@/assets/icon/banner.png" alt=""/></van-swipe-item> -->
    </van-swipe>
  </div>
</template>
<script>
export default {
  name: "Swiper",
  props: {
    imglist: {
      type: Array,
      default() {
        return [];
      },
    },
    page: {
      type: Number, // 1 首页 頂部輪播 10 首頁底部輪播
      default: 0,
    },
  },
  methods: {
    // @dragstart="removeDefault" @select="removeDefault"
    removeDefault(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      return false;
    },
    clickTag(index) {
      var param = {
        index,
        page: this.page,
      };
      this.$emit("clickTag", param);
    },
  },
};
</script>
<style scoped>
.my-swipe img {
  width: 100%;
}
</style>
