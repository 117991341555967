<template>
  <div>
    <div class="foodList box">
      <div class="category">
        <div class="category-inner">
          <div class="categoryItemBox">
            <div
              @click="selectCategory(item.uid, index)"
              v-for="(item, index) in productMenu.bigs"
              :key="item.uid"
            >
              <div
                class="categoryItem"
                :class="{ active: showTag == item.uid }"
                v-show="item.name && item.name.toLowerCase() != 'copy'"
              >
                <img
                  v-show="$store.state.language == 1"
                  :src="item.typeImage"
                  alt=""
                />
                <img
                  v-show="$store.state.language != 1"
                  :src="item.typeImageEn"
                  alt=""
                />
                <span v-if="$store.state.language == 1">{{ item.name }}</span>
                <span v-else>{{ item.nameEn }}</span>
                <i></i>
              </div>
            </div>
            <div style="height: 3rem"></div>
          </div>
        </div>

        <div class="tab" id="tab">
          <div class="secondTab" ref="tabSecond" v-if="secondTitle.length != 0">
            <div
              v-for="item in secondTitle.smalls"
              :key="item.uid"
              @click="changeTab(item.uid)"
            >
              <p
                class="secondtitle"
                :class="{ active: secondUid == item.uid }"
                v-show="item.name && item.name.toLowerCase() != 'copy'"
              >
                <span v-if="$store.state.language == 1">{{ item.name }}</span>
                <span v-else>{{ item.nameEn }}</span>
              </p>
            </div>
          </div>
          <div class="rnowMore" v-show="showRnow" @click="slideTab"></div>
        </div>
      </div>

      <div class="footLists" id="foodList">
        <div
          v-for="secondMenu in productMenu.bigs"
          :key="secondMenu.uid"
          :id="'food_' + secondMenu.uid"
        >
          <div
            v-show="secondMenu.name && secondMenu.name.toLowerCase() != 'copy'"
          >
            <div
              v-for="item in secondMenu.smalls"
              :key="item.uid"
              :id="'secondFood_' + item.uid"
            >
              <p class="favorite">
                <!-- <img :src="item.typeImage" alt="" /> -->
                {{ $store.state.language == 1 ? item.name : item.nameEn }}
              </p>
              <div
                class="foodItem"
                @click="lookDetail(food)"
                v-for="food in item.products"
                :key="food.uid"
              >
                <img
                  v-if="$store.state.language == 1"
                  :src="food.logo || iconDefault"
                  alt=""
                  @error="setErrorImg(food.uid)"
                  :ref="'img_' + food.uid"
                />
                <img v-else :src="food.logoEn" alt="" />

                <div class="foodItemRight">
                  <p class="name">
                    {{ $store.state.language == 1 ? food.name : food.nameEn }}
                  </p>
                  <p class="foodInfo" v-if="$store.state.language == 1">
                    {{ food.desc }}
                  </p>
                  <p class="foodInfo" v-else>{{ food.descEn }}</p>
                  <p class="foodType" v-if="food.isNew"></p>
                  <p class="foodType active" v-else></p>
                  <div class="price">
                    <p>
                      {{ $store.state.money[$store.state.area] }}
                      <span>{{ food.price }}</span>
                      <span v-if="food.type != 1"></span>
                    </p>
                    <div
                      class="btnBox"
                      :class="{ active: addActive }"
                      v-if="food.type == 1 && !food.isSoldOut"
                    >
                      <b
                        class="addBtn addBtnOne"
                        @click.prevent.stop="addCart(food, 1)"
                        >-</b
                      >
                      <input
                        :ref="'foodItem_' + food.uid"
                        type="number"
                        value="0"
                        @input.prevent.stop="addCart(food, 3)"
                        @focus="changeClass(food, 1)"
                        @blur="changeClass(food, 2)"
                      />
                      <b
                        class="addBtn addBtnOne"
                        @click.prevent.stop="addCart(food, 2)"
                        >+</b
                      >
                    </div>
                    <!-- <b
                    v-if="food.type == 1"
                    class="addBtn addBtnOne"
                    @click.prevent.stop="addCart(food)"
                    >+</b
                  > -->
                    <b
                      class="addBtn addBtnOne"
                      v-else-if="
                        (food.type == 2 || food.type == 3) && !food.isSoldOut
                      "
                      >+</b
                    >
                    <b class="addBtn addNone" v-else-if="food.isSoldOut">{{
                      $store.state.language == 1 ? "今日售罄" : "Sold out"
                    }}</b>
                  </div>
                </div>
                <i class="listLine"></i>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 1.5rem"></div>
      </div>
      <div class="cartBox">
        <cart-bar
          ref="cartBar"
          :menuId="productMenu.menuId"
          :businessType="businessType"
          :second="second"
          :book="book"
          @storePre="changeStorePre"
          :storePres="storePres"
          :storeId="productMenu.storeId"
          @showCart="showCart"
          :shopName="shopName"
          :fee="fee"
          :reachFee="reachFee"
          :couponInfo="couponInfo"
          :canToPay="canToPay"
          :takeaway="takeaway"
          @setPreMoney="setPreMoney"
          @checkCoupon="checkCoupon"
        ></cart-bar>
      </div>
      <div class="shopCart" :class="{ active: cart }">
        <shop-cart
          ref="shopCart"
          :storePreParam="storePreParam"
          :businessType="businessType"
          :storeId="productMenu.storeId"
          :preMoney="preMoney"
          @closeCart="closeCart"
          @changeNum="changeNum"
          @clearNum="clearNum"
          @clearCoupon="clearCoupon"
          @showClearCart="showClearCart"
          :couponInfo="couponInfo"
        ></shop-cart>
      </div>
      <div v-show="!cart" class="cartBg" @click="showCart"></div>
    </div>
    <confirm-box-new
      v-show="show"
      @closeBox="showClearCart"
      @finish="finishClear"
      :title="title"
      :cancelBtn="cancelBtn"
      :finishBtn="finishBtn"
    ></confirm-box-new>
  </div>
</template>
<script>
import CartBar from "../../components/food/CartBar.vue";
import ConfirmBoxNew from "../../components/common/CnfirmBoxNew.vue";

import ShopCart from "../../components/food/ShopCart.vue";
export default {
  name: "FoodList",
  data() {
    return {
      showTag: 0,
      index: 0,
      type: 0,
      cart: true,
      showRnow: true,
      iconDefault: require("../../assets/icon/linshi.png"),
      topHeight: 0, //餐品列表距离顶部的距离
      lanType: this.$store.state.language, //语言类型
      productList: [], //餐品列表
      storePreParam: {},
      secondTitle: [], //二级标题
      secondUid: 0,
      canChange: true,
      addActive: false,
      preMoney: 0,
      show: false,
      cancelBtn: this.$store.state.language == 1 ? "返回" : "Cancel",
      finishBtn: this.$store.state.language == 1 ? "清空" : "Clear",
      title:
        this.$store.state.language == 1 ? "確認清空購物車?" : "Clear Cart?",
    };
  },
  props: {
    canScroll: {
      type: Boolean,
      default: false,
    },
    // 餐单
    productMenu: {
      type: Object,
      default: () => {
        return;
      },
    },
    storePres: {
      //优惠方案
      type: Object,
      default: () => {
        return {};
      },
    },
    // 选择的优惠券信息
    couponInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    businessType: {
      //业务类型
      default: 0,
    },
    second: {
      //预约时间
      default: 0,
    },
    book: {
      //预约时间
      type: Number,
      default: 0,
    },
    shopName: {
      type: String,
      default: "",
    },
    // 配送费
    fee: {
      type: Number,
      default: 0,
    },
    reachFee: {
      type: Number,
      default: 0,
    },
    canToPay: {
      type: Boolean,
      default: false,
    },
    takeaway: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showClearCart() {
      this.show = !this.show;
    },
    finishClear() {
      this.$refs.shopCart.finish();
    },
    slideTab() {
      this.$refs.tabSecond.scrollLeft += 40;
    },
    setPreMoney(money) {
      if (money) {
        money = Number(money).toFixed(1);
      }
      this.preMoney = money;
    },
    setErrorImg(id) {
      this.$refs["img_" + id].src = this.iconDefault;
    },
    // 关闭购物车
    closeCart() {
      this.cart = true;
    },
    setSecondTitleView() {
      this.$nextTick(() => {
        if (document.querySelector(".secondtitle.active")) {
          document
            .querySelector(".secondtitle.active")
            .scrollIntoView({ block: "start" });
        }
      });
    },
    selectCategory(id, index) {
      this.$nextTick(() => {
        this.$emit("setS");
        this.showTag = id;
        this.secondTitle = this.productMenu.bigs[index];
        var bannerBoxH = document.querySelector(".banner-box")
          ? document.querySelector(".banner-box").offsetHeight
          : 0;
        document.querySelector(".box").scrollTop =
          document.getElementById("food_" + id).offsetTop +
          bannerBoxH -
          document.querySelector(".tab").offsetHeight;
      });
    },
    // 切换二级标题
    changeTab(uid) {
      this.secondUid = uid;
      var bannerBoxH = document.querySelector(".banner-box")
        ? document.querySelector(".banner-box").offsetHeight
        : 0;
      document.querySelector(".box").scrollTop =
        document.getElementById("secondFood_" + uid).offsetTop +
        bannerBoxH -
        document.querySelector(".tab").offsetHeight;
    },
    // 查看食物详情
    lookDetail(food) {
      if (food.isSoldOut) {
        return;
      }
      food.storeId = this.productMenu.storeId;
      food.menuId = this.productMenu.menuId;
      food.KFCbusinessType=this.businessType;
      var params = JSON.stringify(food);
      params = encodeURIComponent(params);
      if (food.type == 1 && !food.property) return;
      this.$emit("dataLayerPush", "select_items", food.uid);
      this.$router.push("/foodDetail/" + params);
    },
    // 是否显示购物车
    showCart() {
      // if (this.$store.state.cart && this.$store.state.cart.length) {
      this.cart = !this.cart;
      this.$refs.cartBar.showPre(this.cart);
      // }
    },
    clearNum() {
      var cart = this.$store.state.cart;
      for (var i = 0; i < cart.length; i++) {
        if (this.$refs["foodItem_" + cart[i].uid]) {
          this.$refs["foodItem_" + cart[i].uid].parentNode.classList.remove(
            "active"
          );
          this.$refs["foodItem_" + cart[i].uid].value = 0;
        }
      }
    },
    clearCoupon() {
      this.$emit("clearCoupon");
    },
    // 改变购物车数量时改变页面数量
    changeNum(param) {
      if (this.$refs["foodItem_" + param.uid]) {
        if (param.num == 0) {
          this.$refs["foodItem_" + param.uid].parentNode.classList.remove(
            "active"
          );
        }
        this.$refs["foodItem_" + param.uid].value = param.num;
      }
    },
    changeClass(food, type) {
      type == 1
        ? this.$refs["foodItem_" + food.uid].classList.add("active")
        : this.$refs["foodItem_" + food.uid].classList.remove("active");
    },
    // 添加到购物车
    addCart(food, type) {
      if (type != 1) {
        this.$emit("dataLayerPush", "select_items", food.uid);
      } else {
        this.$refs.shopCart;
        Object.keys(this.$refs.shopCart.$refs).forEach((v) => {
          if (v.indexOf("cartItem_" + food.uid) > -1) {
            this.$refs.shopCart.$refs[v].dataLayerPush(food, 1);
            return;
          }
        });
      }

      if (food.property) {
        this.lookDetail(food);
        return;
      }
      var num = Number(this.$refs["foodItem_" + food.uid].value);
      if (type == 1) {
        this.$refs["foodItem_" + food.uid].value = num - 1;
        num -= 1;
        if (num == 0) {
          this.$refs["foodItem_" + food.uid].parentNode.classList.remove(
            "active"
          );
        }
      } else if (type == 2) {
        this.$refs["foodItem_" + food.uid].parentNode.classList.add("active");
        this.$refs["foodItem_" + food.uid].value = num + 1;
        num += 1;
      } else if (type == 3) {
        if (num < 0) {
          this.$refs["foodItem_" + food.uid].value = 1;
          num = 1;
        }
        if (!num) {
          this.$refs["foodItem_" + food.uid].parentNode.classList.remove(
            "active"
          );
          this.$refs["foodItem_" + food.uid].blur();
        }
      } else {
        return false;
      }
      food.num = num;
      var product = {};
      product.shopId = this.productMenu.storeId;
      product.product = food;
      product.cart = true;
      this.$store.commit("createCart", product);
      if (type != 1) {
        this.$refs.shopCart.dataLayerPush();
      }
    },
    // 显示满减优惠
    changeStorePre(param) {
      this.storePreParam = param;
    },
    checkCoupon() {
      this.$emit("checkCoupon", false);
    },
    // 提示
    toast() {
      // this.$toast({ message: "附近暫無門店" });
    },
    setNum() {
      this.$nextTick(() => {
        var cart = this.$store.state.cart;
        if (cart) {
          for (var i = 0; i < cart.length; i++) {
            if (
              (cart[i].type == 1 || cart[i].typeId == 1) &&
              !cart[i].property
            ) {
              if (cart[i].uid) {
                this.$refs["foodItem_" + cart[i].uid].value = cart[i].num;
                this.$refs["foodItem_" + cart[i].uid].parentNode.classList.add(
                  "active"
                );
              } else if (cart[i].pid) {
                if (this.$refs["foodItem_" + cart[i].pid]) {
                  this.$refs["foodItem_" + cart[i].pid].value = cart[i].num;
                  this.$refs[
                    "foodItem_" + cart[i].pid
                  ].parentNode.classList.add("active");
                }
              }
            }
          }
        }
      });
    },
  },
  watch: {
    secondUid() {
      this.$nextTick(() => {
        if (document.querySelector(".favorite.active")) {
          document.querySelector(".favorite.active").classList.remove("active");
        }
        if (
          document.querySelector("#secondFood_" + this.secondUid + " .favorite")
        ) {
          document
            .querySelector("#secondFood_" + this.secondUid + " .favorite")
            .classList.add("active");
        }
      });
    },
    secondTitle() {
      this.$nextTick(() => {
        var width = document.querySelector(".secondTab").offsetWidth;
        var parentW = document.querySelector(".tab").offsetWidth - 36;
        this.showRnow = width > parentW ? true : false;
      });
    },
    productMenu() {
      this.$nextTick(() => {
        if (this.productMenu.bigs && this.productMenu.bigs.length) {
          this.secondTitle = this.productMenu.bigs[0];
          setTimeout(() => {
            let distance = [];
            this.productMenu.bigs.forEach((item) => {
              var height = document.querySelector(".banner-box")
                ? document.querySelector(".banner-box").offsetHeight
                : 0;
              let dKey =
                document.getElementById("food_" + item.uid).offsetTop +
                height -
                document.querySelector(".tab").offsetHeight;
              let small = [];
              item.smalls.forEach((i) => {
                let dKey1 =
                  document.getElementById("secondFood_" + i.uid).offsetTop +
                  height -
                  document.querySelector(".tab").offsetHeight;
                small.push({ uid: i.uid, value: dKey1 });
              });
              distance.push({ uid: item.uid, value: dKey, smalls: small });
            });
            this.$store.distanceObj = distance;
          }, 1000);
        }
      });
    },
  },
  created() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.setNum();
    //   }, 500);
    // });
  },

  components: {
    CartBar,
    ShopCart,
    ConfirmBoxNew,
  },
};
</script>
<style scoped>
.foodList {
  width: 100%;
  /* height: 50vh; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* padding-bottom: 1.8rem; */
  position: relative;
  box-sizing: border-box;
}
.category {
  /* width: 22%; */
  width: 0.825rem;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  background-color: #f2f3f5;
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #131314;
  z-index: 7;
  top: auto;
}
.category-inner {
  height: 100%;
  overflow: hidden;
  scrollbar-width: none !important;
  scrollbar-color: #fff !important;
}
.category-inner.active {
  overflow: scroll;
}
.category-inner::-webkit-scrollbar {
  display: none;
}
.category.active {
  position: fixed;
  top: 0;
  /* left: 0; */
}
.categoryItem {
  width: 100%;
  height: 0.76rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.categoryItem img {
  width: 0.2rem;
  height: 0.2rem;
  object-fit: cover;
}
.categoryItem i {
  width: 0.44rem;
  height: 2px;
  background-color: #e6e7e9;
  position: absolute;
  bottom: 0.02rem;
}
.categoryItem span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.categoryItem.active {
  background-color: #fff;
}
.categoryItemOne img {
  width: 0.36rem;
  height: 0.12rem;
}
.footLists {
  width: 78%;
  padding-top: 0.4rem;
  /* padding-bottom: 1rem; */
  /* float: right; */
  /* margin-left: 22%; */
}

.footLists.active {
  margin-left: 22%;
  /* padding-top: 2.18rem; */
}
.footLists::-webkit-scrollbar {
  display: none;
}
.foodItem {
  width: 100%;
  padding: 0.16rem 0.16rem 0.16rem 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.foodItem img {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  object-fit: cover;
  margin-right: 0.12rem;
}
.foodItemRight p {
  text-align: left;
}
.name {
  width: 1.5rem;
  /* height: 0.44rem; */
  max-height: 0.44rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #030f14;
  overflow: hidden;
  /* text-overflow: ellipsis;
  white-space: nowrap; */
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.foodInfo {
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #8b8c8c;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.price {
  width: 1.56rem;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.18rem;
  color: #03060d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price span {
  font-size: 0.16rem;
}
.addBtn {
  height: 0.24rem;
  font-size: 0.12rem;
  border-radius: 0.04rem;
  background-color: #e3002b;
  text-align: center;
  line-height: 0.24rem;
  color: #fff;
  padding: 0 0.05rem;
}
.addBtnOne {
  font-size: 0.2rem;
  line-height: 0.2rem;
}
.addNone {
  color: #b1b1b3;
  border: 0.01rem solid #b1b1b3;
  background-color: #fff;
}
.listLine {
  width: 90%;
  height: 0.01rem;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #cacbcc;
}
.foodType {
  width: 0.28rem;
  height: 0.18rem;
  background: url("../../assets/icon/new.png") no-repeat center;
  background-size: cover;
  margin: 0.04rem 0;
}
.foodType.active {
  background: none;
}
.favorite {
  height: 0.17rem;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 400;
  line-height: 0.17rem;
  color: #03060d;
  margin-top: 0.15rem;
  text-align: left;
  padding-left: 0.12rem;
  box-sizing: border-box;
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}
.favorite img {
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.04rem;
}
.cartBox {
  width: 100%;
  position: fixed;
  bottom: 0.3rem;
  z-index: 9999999999999;
}
.cartBg {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.shopCart {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999999;
  transition: all 0.2s linear;
  padding-bottom: 1rem;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
}
.shopCart.active {
  bottom: -100vh;
}
/* 二级分类 */
.secondTab {
  height: 0.4rem;
  overflow-x: scroll;
  scrollbar-width: none !important;
  scrollbar-color: #fff !important;
}
.secondTab::-webkit-scrollbar {
  display: none;
}

.tab {
  width: 2.92rem;
  height: 0.4rem;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff !important;
  opacity: 1 !important;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* overflow-x: scroll; */
}
.tab .rnowMore {
  width: 0.24rem;
  height: 0.24rem;
  padding: 0 0.05rem;
  background: url("../../assets/icon/rnow.png") no-repeat center;
  background-size: 0.24rem 0.24rem;
  background-color: #fff;
}
.tab::-webkit-scrollbar {
  display: none;
}
.secondTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.16rem;
  box-sizing: border-box;
  flex-wrap: nowrap;
  white-space: nowrap;
  box-sizing: border-box;
}

.secondtitle {
  height: 0.28rem;
  font-size: 0.12rem;
  line-height: 0.3rem;
  padding: 0 0.08rem;
  background-color: #fff;
  border-radius: 0.2rem;
  margin-right: 0.05rem;
  padding: 0 0.1rem;
  border: 0.01rem solid #aaa;
  color: #aaa;
  font-weight: 600;
}
.secondtitle.active {
  background-color: #f7dfe3;
  color: #e4022b;
  border-color: #e4022b;
}
.btnBox {
  width: 0.24rem;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 1s linear;
}
.btnBox.active {
  /* transition: all 1s linear; */
  width: auto;
}
.btnBox input {
  width: 0.2rem;
  height: 0.2rem;
  margin: 0 0.04rem;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 0.02rem;
}
.btnBox input.active {
  background-color: rgba(242, 242, 242, 1);
}
.favorite.active {
  color: #e3002b;
}
</style>